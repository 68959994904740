import React, { useState, useEffect } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import MultiView from 'devextreme-react/multi-view';
import Tabs from 'devextreme-react/tabs';
import SelectBox from 'devextreme-react/select-box';

import PortalNotesTab from './PortalNotesTab';
import ClientsInquiry from './PortalDocRequests';

import ClientDocuments from './PortalDocuments';
import HTTP from '../../../api/HTTP';
import ArrayStore from 'devextreme/data/array_store';
import MessagePortalsPage from '../../messages/MessagePortalsPage';

const PortalComms = (props) => {
  const { user, selClientId, refreshActivity } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [clientProviderDatalist, setClientProviderDatalist] = useState([]);
  const [selPortal, setSelPortal] = useState(-1);
  const [partnerProviderClientChatid, setPartnerProviderClientChatid] = useState();

  useEffect(() => {
    const fetchClientProviderData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/provider-of-client/${selClientId}`);
        setClientProviderDatalist(dataArray.data);
      } catch (err) {}
    };
    if (selClientId) {
      fetchClientProviderData();
    }
  }, [selClientId]);

  useEffect(() => {
    const fetchPartnerProviderClientChat = async () => {
      try {
        const dataArray = await HTTP.get(
          `/client/partner-provider-client-chat/${user.portalid}/${selPortal}/${selClientId}`
        );
        setPartnerProviderClientChatid(dataArray.data[0].chatid);
      } catch (err) {}
    };

    if (user.portalid && selPortal && selClientId) {
      fetchPartnerProviderClientChat();
    } else {
      setPartnerProviderClientChatid(-1);
    }
  }, [user.portalid, selPortal, selClientId]);

  const stClientProviderDatalist = new ArrayStore({
    key: 'portalid',
    data: clientProviderDatalist,
  });

  return (
    <div id='client-comms-box'>
      <div className='sd-header item'>
        <ResponsiveBox>
          <Row />
          <Col ratio={1} />
          <Col ratio={5} />
          <Item>
            <Location screen='md lg xs sm' row={0} col={0} />
            <div className='dx-fieldset-header'>
              <div>Provider</div>
            </div>
          </Item>
          <Item>
            <Location screen='md lg xs sm' row={0} col={1} />
            <div className='dx-fieldset-header'>
              <SelectBox
                width={300}
                dataSource={stClientProviderDatalist}
                valueExpr='portalid'
                displayExpr='providername'
                searchEnabled={false}
                onValueChanged={(e) => {
                  e && setSelPortal(e.value);
                  // console.log(e.value);
                }}
              />
            </div>
          </Item>
        </ResponsiveBox>
        <Tabs
          selectedIndex={tabIndex}
          width='100%'
          visible={true}
          onItemClick={(e) => {
            setTabIndex(e.itemIndex);
          }}
        >
          <Item text='Notes' />
          <Item text='Chat' />
          <Item text='Doc. Requests' />
          <Item text='Documents' />
        </Tabs>
        <MultiView height={760} animationEnabled={false} selectedIndex={tabIndex}>
          <Item title='Notes'>
            <PortalNotesTab
              tabVisibleIndex={tabIndex}
              selClientId={selClientId}
              selPortal={selPortal}
              user={user}
              // getReload={getReloadCondition}
            />
          </Item>
          <Item title='Chat'>
            {partnerProviderClientChatid && partnerProviderClientChatid > -1 && (
              <MessagePortalsPage
                messageid={parseInt(partnerProviderClientChatid)}
                openedBy={parseInt(user.portalid)}
                portalType={2}
                selClientId={selClientId}
                refreshActivity={refreshActivity}
                own_portalid={user.portalid}
                tgt_portalid={selPortal}
              />
            )}
          </Item>
          <Item title='Doc. Requests'>
            <ClientsInquiry
              selectedKey={selClientId}
              selClientid={selClientId}
              selAccountType={'Person'}
              selPartnerid={user.partnerid}
              user={user}
            />
          </Item>
          <Item title='Documents'>
            <ClientDocuments user={user} selClientId={selClientId} selPortalId={parseInt(user.portalid)} />
          </Item>
        </MultiView>
      </div>
    </div>
  );
};

export default PortalComms;

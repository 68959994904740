import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';

export default function (props) {
  const { user } = props;
  return (
    <SideNavBarLayout title={appInfo.title + ' - ' + user.portalname}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={'/operations'} />
      </Switch>
      <Footer>
        Copyright © 2004-{new Date().getFullYear()} Oculeus GmbH. All trademarks or registered trademarks are property
        of their respective owners.
      </Footer>
    </SideNavBarLayout>
  );
}

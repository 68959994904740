import React, { useEffect, useState, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";

import "./PartnerServicesEdit.css";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import { useAuth } from "../../contexts/auth";

import ServicesEditFeatures from "./ServicesEditFeatures";
import ServicesEditPartnerSellRates from "./ServicesEditPartnerSellRates";
import ServicesEditCustomerDocs from "./ServicesEditCustomerDocs";
import ServicesEditTermsofUse from "./ServicesEditTermsofUse";
import ServicesEditProviderName from "./ServicesEditProviderName";

const PartnerServicesEditPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const systemServiceId = user.service && user.service.serviceId;
  const partnerName = user.service && user.service.partnerName;
  const serviceName = user.service && user.service.serviceName;
  const selProviderId = user.service && user.service.providerId;
  const selCategoryId = user.service && user.service.categoryId;
  const selPartnerId = user.service && user.service.partnerId;
  const selRoutingId = user.service && user.service.routingId;
  const modeInsert = user.service && user.service.modeInsert;
  const history = useHistory();


  useEffect(() => {
    if (!user.service) {
      history.push({
        pathname: "/",
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen="md lg xs sm" row={0} col={0} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="partner-services-box-line1-left">
                <ServicesEditProviderName
                  selProviderId={selProviderId}
                  modeInsert={modeInsert}
                  user={user}
                  serviceName={serviceName}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={0} col={1} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="partner-services-box-line1-right">
                <ServicesEditFeatures
                  selServiceId={systemServiceId}
                  selCategoryId={selCategoryId}
                  modeInsert={modeInsert}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={1} col={0} colspan={2} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="partner-services-box-line2-left">
                <ServicesEditPartnerSellRates
                  selServiceId={systemServiceId}
                  selPartnerId={selPartnerId}
                  selRoutingId={selRoutingId}
                  modeInsert={modeInsert}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={2} col={0} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="partner-services-box-line3-left">
                <ServicesEditCustomerDocs systemServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={2} col={1} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="partner-services-box-line3-right">
                <ServicesEditTermsofUse systemServiceId={systemServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={3} col={1} />
          <div id={"client-close-button"}>
            <div style={{ flexDirection: "row" }}>
              <Button
                width={120}
                height={50}
                text="Close"
                type="normal"
                stylingMode="contained"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerServicesEditPage;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PartnerServicesEdit.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServicesEditFeatures = (props) => {
  const { selServiceId, user } = props;
  const history = useHistory();

  const [serviceFeaturesData, setServiceFeaturesData] = useState([]);

  useEffect(() => {
    const fetchServiceFeatures = async () => {
      try {
        const dataArray = await HTTP.get(`/service/systemservicefeatures/${selServiceId}`);
        setServiceFeaturesData(dataArray.data);
      } catch (err) {}
    };
    if (selServiceId) {
      fetchServiceFeatures();
    }
  }, [selServiceId]);

  const stServiceFeaturesData = new ArrayStore({
    key: ['serviceid', 'group_assetid', 'featureid'],
    data: serviceFeaturesData,
  });

  const dcServiceFeaturesData = new DataSource({
    store: stServiceFeaturesData,
  });

  const saveFeatureUse = async (anFeatureUseData) => {
    try {
      HTTP.post(
        '/service/modsystemservicefeature',
        JSON.stringify({
          operation: 2,
          serviceid: anFeatureUseData.serviceid,
          featureid: anFeatureUseData.featureid,
          usefeature: anFeatureUseData.usefeature,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  return (
    <div id='partner-services-feature-box'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Features</div>
      </div>
      <DataGrid
        height={370}
        dataSource={dcServiceFeaturesData}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        columnAutoWidth={true}
        focusedRowEnabled={true}
        onSaved={(e) => {
          if (e.changes[0]) {
            saveFeatureUse(e.changes[0].data);
          }
        }}
      >
        <Column dataField='servicefeature_id' visible={false} />
        <Column dataField='category_id' visible={false} />
        <Column dataField='buysellasset' visible={false} />
        <Column dataField='sendreceiveasset' visible={false} />
        <Column dataField='feature' caption='Feature' width='150' />
        <Column dataField='groupasset' caption='Group' width='70' visible={true} />
        <Column dataField='buysellasset_txt' caption='Asset' width='70' visible={true} />
        <Column dataField='main_feature' dataType='boolean' caption='Srvce' width='50' />
        <Column dataField='account_feature' dataType='boolean' caption='Accnt' width='50' />
        <Column dataField='buy' dataType='boolean' caption='Buy' width='50' visible={true} />
        <Column dataField='sell' dataType='boolean' caption='Sell' width='50' visible={true} />
        <Column dataField='list' dataType='boolean' caption='List' width='50' visible={true} />
        <Column dataField='send' dataType='boolean' caption='Send' width='50' visible={true} />
        <Column dataField='receive' dataType='boolean' caption='Get' width='60' visible={true} />
      </DataGrid>
    </div>
  );
};

export default ServicesEditFeatures;

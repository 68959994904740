import React from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import MessageUsersPage from '../messages/MessageUsersPage';

const UserChat = (props) => {
  const { chatid, user, selUser } = props;

  return (
    <React.Fragment>
      <div id='client-base-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header' id='header-box'>Chat</div>
        </div>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <MessageUsersPage chatid={chatid} user={user} selUser={selUser} />
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default UserChat;

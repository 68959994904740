import React, { useState, useEffect } from 'react';
import './partnerservices.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServiceFeatures = (props) => {
  const { selSystemServiceID, user } = props;
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchSystemService = async () => {
      try {
        const dataArray = await HTTP.get(`/service/systemservicefeatures/${selSystemServiceID}`);
        setServiceData(dataArray.data);
      } catch (err) {}
    };

    if (selSystemServiceID) {
      fetchSystemService();
    }
  }, [selSystemServiceID]);

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div>
          <DataGrid
            id='service-SystemServiceFeaturesGrid'
            // ref={userDataGrid}
            keyExpr={'serviceid'}
            dataSource={serviceData}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columnAutoWidth={true}
            focusedRowEnabled={true}
          >
            <Column dataField='servicefeature_id' visible={false}></Column>
            <Column dataField='category_id' visible={false}></Column>
            <Column dataField='buysellasset' visible={false}></Column>
            <Column dataField='sendreceiveasset' visible={false}></Column>
            <Column dataField='feature' caption='Feature' width='250'></Column>
            <Column dataField='groupasset' caption='Group' width='100'></Column>
            <Column dataField='buysellasset_txt' caption='Asset' width='100px' visible={true} />
            <Column dataField='main_feature' dataType='boolean' caption='Service' width='70'></Column>
            <Column dataField='account_feature' dataType='boolean' caption='Account' width='80'></Column>
            <Column dataField='buy' dataType='boolean' caption='Buy' width='60' visible={true} />
            <Column dataField='sell' dataType='boolean' caption='Sell' width='60' visible={true} />
            <Column dataField='list' dataType='boolean' caption='List' width='60' visible={true} />
            <Column dataField='send' dataType='boolean' caption='Send' width='60' visible={true} />
            <Column dataField='receive' dataType='boolean' caption='Receive' width='70' visible={true} />
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceFeatures;

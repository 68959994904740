import React, { useState, useEffect, useCallback } from 'react';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../../contexts/auth';
import './costrateservices.css';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServiceFeeGridDetails = (props) => {
  const { user } = useAuth();
  const risklevel = props.data.data;
  const [costRateDetailList, setCostRateDetailList] = useState([]);

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await HTTP.get(`/service/viewcostrateentries/${risklevel.costentryid}/${risklevel.riskid}`);
        setCostRateDetailList(dataArray.data);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [risklevel.costentryid, risklevel.riskid]);

  const stCostDetailsData = new ArrayStore({
    key: ['costentryid', 'featureid'],
    data: costRateDetailList,
  });

  const dcCostDetailsData = new DataSource({
    store: stCostDetailsData,
  });

  return (
    <div>
      <DataGrid
        id='service-cost-rates-entries-base-grid'
        dataSource={dcCostDetailsData}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        autoNavigateToFocusedRow={true}
      >
        <FilterRow visible={false} />
        <Column dataField='serviceid' visible={false} />
        <Column dataField='costentryid' visible={false} />
        <Column dataField='riskid' visible={false} />
        <Column dataField='featureid' visible={false} />
        <Column dataField='regional_limit_id' visible={false} />
        <Column dataField='feature' caption='Service Feature' width={150} />
        <Column dataField='costtype' caption='Cost Type' width={80} visible={false}></Column>
        <Column dataField='costvalue' caption='Fixed Fee' dataType='number' width={80} />
        <Column dataField='costperc' caption='Percent of Value' dataType='number' width={120} />
        <Column dataField='costperc_minvalue' caption='Min Value' dataType='number' width={80} />
        <Column dataField='flattype' caption='Flat Type' width={90}></Column>
        <Column dataField='flatvalue' dataType='number' caption='Flat Value' width={90} />
        <Column dataField='flatperiod' caption='Flat Period' width={90}></Column>
      </DataGrid>
    </div>
  );
};
export default ServiceFeeGridDetails;

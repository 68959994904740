import React, { useEffect, useRef, useState } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react';

const AcceptRejectDocPop = props => {
  const { showPopup, hidePopup } = props;
  const [btnClicked, setBtnClicked] = useState(false);

  const acceptRejectDoc = async anDecision => {
    setBtnClicked(true);
    hidePopup(anDecision);
  };

  return (
    <Popup
      title={'Document'}
      width={310}
      height={150}
      visible={showPopup}
      showCloseButton={true}
      onShowing={() => {
        setBtnClicked(false);
      }}
      onHiding={() => {
        if (!btnClicked) {
          hidePopup(false);
        }
      }}
    >
      {/*   <table>
        <tbody>
          <tr>
            <td>Document</td>
          </tr>
        </tbody>
      </table>
    */}
      <div id='accept-reject-pop'>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Col ratio={1} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <Button
                width={130}
                height={40}
                text='Accept'
                type='success'
                stylingMode='outlined'
                onClick={() => {
                  acceptRejectDoc(true);
                }}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <Button
                width={130}
                height={40}
                text='Reject'
                type='danger'
                stylingMode='outlined'
                onClick={() => {
                  acceptRejectDoc(false);
                }}
              />
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </Popup>
  );
};

export default AcceptRejectDocPop;

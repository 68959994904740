import React, { useState, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, CustomRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';

export default function (props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { passwordkey } = formData.current;

      history.push(`/change-password/${passwordkey}`);
    },
    [history]
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item dataField={'passwordkey'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message='Password Key is required' />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width={'100%'} height={'36px'} type={'default'} useSubmitBehavior={true}>
            <span className='dx-button-text'>
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Continue'}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Please enter your Password Key',
  mode: 'text',
};

import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ClientAccessPop = (props) => {
  const { showPopup, hidePopup, user, selClientid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [clientAccessData, setClientAccessData] = useState({});
  const [lpAccessType, setLpAccessType] = useState([]);

  useEffect(() => {
    const fetchClietnAccess = async () => {
      const dataArray = await HTTP.get(`/client/clientaccess/${selClientid}`);
      setClientAccessData(dataArray.data[0]);
    };

    const fetchAccessType = async () => {
      const dataArray = await HTTP.get('/base/getlookupvalues/48');
      setLpAccessType(dataArray.data);
    };

    if (showPopup && selClientid) {
      fetchAccessType();
      fetchClietnAccess();
    }
  }, [showPopup, selClientid]);

  const stAccessType = new ArrayStore({
    key: 'lp_id',
    data: lpAccessType,
  });

  const accessData = {
    signup: clientAccessData && clientAccessData.signup,
    wallet: clientAccessData && clientAccessData.wallet,
    accountdata: clientAccessData && clientAccessData.accountdata,
    statements: clientAccessData && clientAccessData.statements,
    assets: clientAccessData && clientAccessData.assets,
    emailnotifications: clientAccessData && clientAccessData.emailnotifications,
    smsnotifications: clientAccessData && clientAccessData.smsnotifications,
  };

  const saveClientAccess = async () => {
    try {
      HTTP.post(
        '/client/setclientaccess',
        JSON.stringify({
          clientid: selClientid,
          signup: accessData.signup,
          wallet: accessData.wallet,
          accountdata: accessData.accountdata,
          statements: accessData.statements,
          assets: accessData.assets,
          emailnotifications: accessData.emailnotifications,
          smsnotifications: accessData.smsnotifications,
        })
      );
      hidePopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Client Access'}
      width={600}
      height={440}
      showCloseButton={true}
      visible={showPopup}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id={'client-access-form'} labelLocation={'top'} formData={accessData}>
            <Item itemType='group' colCount={10}>
              <SimpleItem
                dataField='signup'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Signup' />
              </SimpleItem>

              <SimpleItem
                dataField='wallet'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Wallet' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={10}>
              <SimpleItem
                dataField='accountdata'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Account Data' />
              </SimpleItem>

              <SimpleItem
                dataField='statements'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Statements' />
              </SimpleItem>

              <SimpleItem
                dataField='assets'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Assets' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={10}>
              <SimpleItem
                dataField='emailnotifications'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='E-Mail Notifications' />
              </SimpleItem>

              <SimpleItem
                dataField='smsnotifications'
                editorType='dxSelectBox'
                colSpan={3}
                name='clienttype_listbox'
                editorOptions={{
                  dataSource: stAccessType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='SMS Notifications' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={36}
                text='Save'
                type='default'
                stylingMode='outlined'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={saveClientAccess}
              />
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={36}
                text='Close'
                type='normal'
                stylingMode='outlined'
                onClick={hidePopup}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientAccessPop;

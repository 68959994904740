import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import AddInquiry from '../../../modules/inquiries/AddInquiry';
import HTTP from '../../../api/HTTP';

const PortalDocRequests = (props) => {
  const { selectedKey, user, selClientid } = props;
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const [reloadInquiries, setReloadInquiries] = useState(false);
  const [showInquiryAddPopup, setShowInquiryAddPopup] = useState(false);
  const [showInquiryDetailsPopup, setShowInquiryDetailsPopup] = useState(false);
  const [inquiriesDataList, setInquiriesDatalist] = useState([]);
  const [selInquiryId, setSelInquiryId] = useState();

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const dataArray = await HTTP.get(`/inquiries/getClientRequests/${selectedKey}`);
        setInquiriesDatalist(dataArray.data);
        if (dataArray.data.length === 0) {
          setSelInquiryId();
        } else {
          setSelInquiryId(dataArray.data[0].inquiryid);
        }
      } catch (err) {}
    };
    if (selectedKey) {
      fetchInquiries();
    }
  }, [selectedKey, reloadInquiries]);

  const stInquiriesDataList = new ArrayStore({
    key: 'inquiryid',
    data: inquiriesDataList,
  });

  const dcInquiriesDataList = new DataSource({
    store: stInquiriesDataList,
  });

  const OpenAddInquiry = () => {
    setShowInquiryAddPopup(true);
  };

  const OpenDetailsInquiry = () => {
    // console.log(selPartnerid, selInquiryId, selClientid);

    user.inquiry = {
      selInquiryId: selInquiryId ? selInquiryId : -1,
      selClientid: selClientid,
    };
    history.push({
      pathname: '/inquiry',
    });
  };

  const hideInquiryAddPopup = async (anInquiryid) => {
    // console.log(2345, anInquiryid);
    setShowInquiryAddPopup(false);
    if (anInquiryid > -1) {
      await setSelInquiryId(anInquiryid);

      user.inquiry = {
        selInquiryId: anInquiryid,
        selClientid: selClientid,
      };
      history.push({
        pathname: '/inquiry',
      });
    }
    setReloadInquiries(!reloadInquiries);
  };

  return (
    <>
      <AddInquiry
        showPopup={showInquiryAddPopup}
        hidePopup={hideInquiryAddPopup}
        user={user}
        selSignupClientId={selectedKey}
      />
      <ResponsiveBox>
        <Col ratio={0.6} screen='md lg sm xs' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Doc. Request</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={OpenAddInquiry} />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              disabled={!selInquiryId ? true : false}
              onClick={OpenDetailsInquiry}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={1} />
          <div>
            <DataGrid
              id='client-comms-inquirydata-grid'
              height={700}
              dataSource={dcInquiriesDataList}
              defaultFocusedRowIndex={0}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                console.log(e);
                // setSelRowData(e.row.data);
                setSelInquiryId(e.row.key);
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='inquiryid' visible={false} caption='ID' />
              <Column dataField='portalid' visible={false} />
              <Column dataField='subject' alignment='left' caption='Subject' visible={true} width={200} />
              <Column dataField='inquirydate' caption='Date' dataType='datetime' visible={true} width={130} />
              <Column dataField='inquirytype' caption='Type' visible={false} />
              <Column dataField='statetext' caption='State' visible={true} width={60} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </>
  );
};

export default PortalDocRequests;

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ClientTransactionPop from './ClientTransactionPop';
import HTTP from '../../api/HTTP';

const ClientsApprovedTransactions = (props) => {
  const { user, selClientid, reloadNewsGridCondition } = props;
  const history = useHistory();
  const [clientTransactionsData, setClientTransactionsData] = useState([]);
  const [selectedTransactionid, setSelectedTransactionid] = useState(-1);
  const [selServiceid, setselServiceid] = useState(-1);

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showClientTransactionPop, setShowClientTransactionPop] = useState(false);

  const [selTransactionRecord, setSelTransactionRecord] = useState({});

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const dataArray = await HTTP.get(`/rep/clienttransactions/${selClientid}`);
        setClientTransactionsData(dataArray.data);
      } catch (err) {}
    };
    if (selClientid) {
      fetchTransactions();
    }
  }, [selClientid]);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selClientid}`);
        setSignupClientDatalist(dataArray.data[0]);
      } catch (err) {}
    };
    if (selClientid) {
      fetchSignupClientData();
    }
  }, [selClientid]);

  const stClientTransactionsData = new ArrayStore({
    key: 'transactionid',
    data: clientTransactionsData,
  });

  const dcClientTransactionsData = new DataSource({
    store: stClientTransactionsData,
  });

  const showClientTransactionDialog = () => {
    setShowClientTransactionPop(true);
  };

  const hideClientTransactionDialog = async () => {
    await setShowClientTransactionPop(false);
    await reloadNewsGridCondition(true);
  };

  return (
    <React.Fragment>
      <ClientTransactionPop
        showPopup={showClientTransactionPop}
        hidePopup={hideClientTransactionDialog}
        user={user}
        selTransactionRecord={selTransactionRecord}
        clientid={selClientid}
      />

      <ResponsiveBox>
        <Col ratio={1.5} />
        <Col ratio={9} />
        <Row ratio={1} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Transaction Request</p>
            <p>Contact</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Client'
              type='default'
              stylingMode='outlined'
              onClick={showClientTransactionDialog}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <DataGrid
              id='clients-active-transaction-Grid'
              dataSource={dcClientTransactionsData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selectedTransactionid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelectedTransactionid(e.row.key);
                  setselServiceid(e.row.data.serviceid);
                  setSelTransactionRecord(e.row.data);
                }
              }}
            >
              <FilterRow visible={true} />
              <Column dataField='transactionid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='clientid' visible={false}></Column>
              <Column dataField='transferdate' caption='Transfer Date' dataType='datetime' width={130}></Column>
              <Column dataField='servicename' caption='Service' visible={true} width={190} />
              <Column dataField='operation' caption='Operation' visible={true} width={80} />{' '}
              <Column dataField='assetname' caption='Asset' visible={true} width={80} />
              <Column dataField='assetamount' caption='Amount' visible={true} width={150} />
              <Column dataField='assetcalcunit' caption='Unit' visible={true} width={70} />
              <Column dataField='currency' caption='Currency' visible={true} width={60} />
              <Column dataField='currencyamount' caption='Amount' visible={true} width={80} />
              <Column dataField='partner_fee' caption='Cost' visible={true} width={70} />
              <Column dataField='client_fee' caption='Client Fee' visible={true} width={80} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedTransactions;

import React, { useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';

const MessagePop = props => {
  const { showPopup, hidePopup, popTitle, popMessage } = props;
  const [featureChanged, setFeatureChanged] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);

  return (
    <Popup
      title={popTitle}
      width={440}
      height={260}
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
        if (!btnClicked) {
          hidePopup(featureChanged);
        }
      }}
      onShowing={() => {
        setFeatureChanged(false);
        setBtnClicked(false);
      }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={1} width='90%'>
          <h5>{popMessage}</h5>
        </Item>
        <Item ratio={1}>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Yes'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  setBtnClicked(true);
                  hidePopup(true);
                }}
              />
              <Button
                width={120}
                height={50}
                text='No'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  setBtnClicked(true);
                  hidePopup(false);
                }}
              />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default MessagePop;

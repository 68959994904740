import React, { useEffect, useRef, useState } from 'react';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { SelectBox } from 'devextreme-react/select-box';

import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../../api/HTTP';

const ClientShareDocPop = (props) => {
  const { showPopup, hidePopup, selClientId, selclientdocid, user } = props;
  const [clientProviderDatalist, setClientProviderDatalist] = useState([]);
  const [selPortalId, setSelPortalId] = useState(-1);

  useEffect(() => {
    const fetchClientProviderData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/provider-of-client/${selClientId}`);
        setClientProviderDatalist(dataArray.data);
      } catch (err) {}
    };
    if (selClientId) {
      fetchClientProviderData();
    }
  }, [selClientId]);

  const stClientProviderDatalist = new ArrayStore({
    key: 'portalid',
    data: clientProviderDatalist,
  });

  const shareDocWithProviders = async () => {
    console.log(1, selPortalId);
    console.log(2, selclientdocid);

    try {
      await HTTP.post(
        '/client/share-client-document',
        JSON.stringify({
          operation: 1,
          portalid: selPortalId,
          clientdocid: selclientdocid,
        })
      );
    } catch (err) {
      console.log('shareDocWithProviders Error', err);
    }
    hidePopup(true);
  };

  return (
    <Popup
      title={'Share Documents with'}
      width={480}
      height={240}
      showCloseButton={true}
      visible={showPopup}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={5} />
        <Row ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='share-doc-pop-frm'>
            <div id='share-doc-pop-lbl'>Provider</div>
            <SelectBox
              // width={'200px'}
              dataSource={stClientProviderDatalist}
              valueExpr={'portalid'}
              displayExpr={'providername'}
              searchEnabled={true}
              onValueChanged={(e) => {
                e && setSelPortalId(e.value);
              }}
            />
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id='share-doc-pop-btn'>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                onClick={() => {
                  shareDocWithProviders();
                }}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientShareDocPop;

import React, { useState, useEffect } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ArrayStore from 'devextreme/data/array_store';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import { SelectBox } from 'devextreme-react';
import HTTP from '../../api/HTTP';

const CasesNotesToSelReceivePop = (props) => {
  const { showPopup, hidePopup, popTitle, user, entryid, toType, getReceiver, clientId } = props;
  const [btnClicked, setBtnClicked] = useState(false);
  const [portalsDatalist, setPortalsDatalist] = useState([]);
  const [selPortalid, setSelPortalid] = useState(-1);
  const [selPortalName, setSelPortalName] = useState('');

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await HTTP.get(`/management/clientproviderportals/${clientId}`);
        setPortalsDatalist(dataArray.data);
      } catch (err) {}
    };
    if (showPopup && clientId) {
      fetchPortals();
    }
  }, [showPopup, clientId]);

  const stPortalData = new ArrayStore({
    key: 'portalid',
    data: portalsDatalist,
  });

  const saveReceiver = async (anReceiverType) => {
    await getReceiver(anReceiverType, selPortalid, toType, selPortalName);
  };

  const onHideClearValues = () => {
    setPortalsDatalist([]);
    setSelPortalid(-1);
    setSelPortalName('');
  };

  return (
    <Popup
      title={popTitle}
      width={360}
      height={160}
      visible={showPopup}
      resizeEnabled={false}
      showCloseButton={true}
      onHiding={() => {
        onHideClearValues();
        if (!btnClicked) {
          hidePopup(false);
        }
      }}
      onShowing={() => {
        setBtnClicked(false);
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Col ratio={0} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <SelectBox
            // width={'200px'}
            dataSource={stPortalData}
            valueExpr={'portalid'}
            displayExpr={'portalname'}
            searchEnabled={true}
            defaultValue={selPortalid}
            value={selPortalid}
            onValueChanged={(e) => {
              e && setSelPortalid(e.value);
              // console.log(e.component.option('text'));
              e && setSelPortalName(e.component.option('text'));
            }}
          />
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <Button
            width={'100%'}
            height={41}
            text='Selected Provider'
            type='default'
            stylingMode='contained'
            onClick={async () => {
              await saveReceiver(4);
              hidePopup(true);
            }}
          />
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CasesNotesToSelReceivePop;

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Chat.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import HTTP from '../../api/HTTP';
import UserChat from './UserChat';


const ChatPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const navigateToHome = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='base-box' >
                <UserChat chatid={user.chat.chatid} user={user} selUser={user.chat.selUser} /></div>              
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={3} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ChatPage;

import React from 'react';
import './portaluser.css';

import { MultiView } from 'devextreme-react';
import { Item } from 'devextreme-react/responsive-box';

import PortalUserTab from './PortalUserTab';

const SettingsPage = () => {
  // const location = useLocation(); // locationValue={location}
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Settings</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'dx-card wide-card'}>
          <MultiView height={650} loop={false}>
            <Item title='Users'>
              <PortalUserTab />
            </Item>
          </MultiView>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsPage;

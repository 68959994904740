import React, { useEffect, useState } from 'react';

import './ComplianceMatrix.css';
import 'devextreme/dist/css/dx.light.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';

import MatrixModel from './nmatrix/MatrixModel';
import HTTP from '../../api/HTTP';

const ComplianceMatrixPage = (props) => {
  const { user } = props;
  const [modelid, setModelid] = useState(1);

  const [aiModelFocusedDataRow, setAiModelFocusedDataRow] = useState({});
  const [aiModelsData, setAiModelsData] = useState([]);
  const [showAiModelPopup, setShowAiModelPopup] = useState(false);
  const [reloadAiModels, setReloadAiModels] = useState(false);
  const [addAiModel, setAddAiModel] = useState(false);

  useEffect(() => {
    const fetchAiModels = async () => {
      const response = await HTTP.get('/ai/models');
      setAiModelsData(response.data);
    };
    if (user) {
      fetchAiModels();
    }
  }, [reloadAiModels, user]);

  const martrixData = {
    modelid: 1,
    modelName: '',
  };

  return (
    <React.Fragment>
      <div id='ai-form'>
        <ResponsiveBox>
          <Row ratio={1} />
          <Row ratio={1} />
          <Row ratio={1} />
          <Row ratio={1} />
          <Col />

          <Item>
            <Location screen='md lg xs sm' row={0} col={0} />
            <h4 className='card-heading'>Risk Matrix AI-Model</h4>

            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />

              <Item>
                <Location screen='md lg xs sm' row={0} col={0} />
                <DataGrid
                  id='Model-Grid'
                  dataSource={aiModelsData}
                  showBorders={true}
                  keyExpr={'modelid'}
                  showRowLines={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  focusStateEnabled={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  onFocusedRowChanged={(e) => {
                    if (e) {
                      setAiModelFocusedDataRow(e.row.data);
                      setModelid(e.row.key);
                    }
                  }}
                >
                  <Column dataField='modelid' visible={false} />
                  <Column dataField='modelname' visible={true} caption='Model' width='360px' />
                </DataGrid>
              </Item>
            </ResponsiveBox>
          </Item>
          <Item>
            <Location screen='md lg xs sm' row={1} col={0} />
            <MatrixModel modelid={modelid} user={user} />
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ComplianceMatrixPage;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Product.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import ProductCostRiskServiceDetailGrid from '../../detailgrids/ProductCostRiskServiceDetailGrid';

import MessagePop from '../../ui_elements/MessagePop';
import HTTP from '../../api/HTTP';

const ProductPricing = (props) => {
  const { selProductId, selServiceId, selPartnerId, user } = props;
  const history = useHistory();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);
  const [doReload, setDoReload] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [hasNoPrices, setHasNoPrices] = useState(false);

  useEffect(() => {
   
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(`/product/productcostrateheader/${selProductId}/${selPartnerId}`);

        if (dataArray.data.length === 0) {
          setHasNoPrices(true);
        } else {
          setHasNoPrices(false);
        }

        setCostRateHeaderList(dataArray.data[0]);

        if (dataArray.data[0]) {
          let anSellRateId = -1;

          if (dataArray.data[0].sellrateid) {
            anSellRateId = dataArray.data[0].sellrateid;
          }

          const costRiskDataArray = await HTTP.get(
            `/product/viewproductcostriskentries/${selProductId}/${selPartnerId}/${anSellRateId}`
          );

          setCostRiskEntriesList(costRiskDataArray.data);
        }
      } catch (err) {}
    };
    console.log(selPartnerId, selProductId);
    if (selPartnerId && selProductId) {
      fetchCostRatesHeader();
    }
  }, [selPartnerId, selProductId, doReload]);

  const costRateHeader = {
    productid: (costRateHeaderList && costRateHeaderList.productid) || -1,
    partnerid: (costRateHeaderList && costRateHeaderList.partnerid) || -1,
    sellrateid: (costRateHeaderList && costRateHeaderList.sellrateid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active_date: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.goBack();
  }

  const openServiceFeeEdit = async () => {
    try {
      const dataArray = await HTTP.get(
        `/product/productcostrateworkcopy/${selProductId}/${selPartnerId}/${costRateHeader.sellrateid}`
      );

      user.productfees = {
        selPartnerId: selPartnerId,
        selProductId: selProductId,
        selSellrateId: costRateHeader.sellrateid,
        workId: dataArray.data[0].work_copy_productcostrates,
      };

      history.push({
        pathname: '/productservicefees_edit',
      });
    } catch (err) {}
  };

  const createProductSellRates = async () => {
    try {
      const dataArray = await HTTP.get(`/product/createproductsellrates/${selPartnerId}/${selProductId}`);
      setDoReload();
    } catch (err) {}
  };

  const initializeServiceCostRates = () => {
    setShowMessagePop(true);
  };

  const hideMessagePop = async (doContinue) => {
    setShowMessagePop(false);
    if (doContinue) {
      await createProductSellRates();
      setDoReload(true);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Initialization is required when you first setup prices. At later stages it will reinitialize existing Prices. Do you want to continue?'
      />
      <div id='product-edit-pricing-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Product Sell Rates</div>
        </div>
        <ResponsiveBox>
          <Row />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />

            <ResponsiveBox>
              <Col ratio={0.33} screen='md lg sm xs' />
              <Col ratio={3} />
              <Row />
              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <div id='hm-btn-section-1'>
                  <Button
                    width={120}
                    height={36}
                    text='Edit'
                    disabled={hasNoPrices}
                    stylingMode='outlined'
                    type={hasNoPrices ? 'normal' : 'default'}
                    onClick={openServiceFeeEdit}
                  />
                </div>
                <div id='hm-btn-section-11'>
                  <Button
                    width={120}
                    height={36}
                    text='Init'
                    stylingMode='outlined'
                    type={hasNoPrices ? 'success' : 'danger'}
                    visible={true}
                    onClick={initializeServiceCostRates}
                  />
                </div>
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={0} col={1} />
                {hasNoPrices && (
                  <div id='center-info'>
                    <h3>No Prices defined yet. Please click on INIT to continue</h3>
                  </div>
                )}
                <Form
                  id='partner-prod-edit-cost-entries'
                  formData={costRateHeader}
                  labelLocation={'left'}
                  colCountByScreen={colCountByScreen}
                  scrollingEnabled={true}
                  readOnly={true}
                >
                  <Item itemType='group' colCount={12}>
                    <SimpleItem dataField='currency' colSpan={2}>
                      <Label text='Currency' />
                    </SimpleItem>
                    <SimpleItem dataField='valid_on' colSpan={3} dataType='date' editorType='dxDateBox'>
                      <Label text='Valid on' />
                    </SimpleItem>
                    <SimpleItem dataField='valid_until' colSpan={3} dataType='date' editorType='dxDateBox'>
                      <Label text='Valid on' />
                    </SimpleItem>
                  </Item>
                  <Item itemType='group' colCount={12}>
                    <SimpleItem dataField='active' colSpan={2} dataType='boolean' editorType='dxCheckBox'>
                      <Label text='Active' />
                    </SimpleItem>
                    <SimpleItem
                      dataField='active_date'
                      colSpan={3}
                      dataType='date'
                      editorType='dxDateBox'
                      editorOptions={{ readOnly: true }}
                    >
                      <Label text='Active on' />
                    </SimpleItem>
                  </Item>

                  <Item>
                    <DataGrid
                      width={1000}
                      height={400}
                      dataSource={dcCostRiskEntriesData}
                      defaultFocusedRowIndex={0}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      focusedRowEnabled={true}
                      showBorders={true}
                      showRowLines={true}
                      showColumnLines={true}
                      autoNavigateToFocusedRow={true}
                    >
                      <Column dataField='productid' visible={false} />
                      <Column dataField='partnerid' visible={false} />
                      <Column dataField='serviceid' visible={false} />
                      <Column dataField='costentryid' visible={false} />
                      <Column dataField='riskid' visible={false} />
                      <Column dataField='risklevel' caption='Risk Level' width='140px' allowEditing={false} />
                      <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                      <Column dataField='not_applicable' caption='Not Applicable' width='110px' />
                      <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='90' />
                      <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='90' />
                      <Column dataField='recperiod' caption='Recurring' width='100'></Column>
                      <MasterDetail enabled={true} component={ProductCostRiskServiceDetailGrid} />
                    </DataGrid>
                  </Item>
                </Form>
              </Item>
            </ResponsiveBox>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductPricing;

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import AddInquiry from '../../../modules/inquiries/AddInquiry';

import notify from 'devextreme/ui/notify';
import InternalMessagingHelper from '../../messages/internalMessaging/InternalMessagingHelper';
import { downloadFileFromBuffer } from '../../../utils/attachesHelper';
import ClientShareDocPop from './ClientShareDocPop';
import HTTP from '../../../api/HTTP';

import { v4 as uuidv4 } from 'uuid';

const ClientCalls = (props) => {
  const { user, selClientId, selPortalId } = props;
  const history = useHistory();
  const [clientCallsList, setClientCallsList] = useState([]);
  const [reloadCallsList, setReloadCallsList] = useState(false);
  const [callRow, setCallRow] = useState({});

  useEffect(() => {
    const fetchClientCalls = async () => {
      try {
        const dataArray = await HTTP.get(`/client/client-calls/${selClientId}/${user.portalid}`);
        setClientCallsList(dataArray.data);
      } catch (err) {}
    };
    if (selClientId && user.portalid) {
      fetchClientCalls();
    }
  }, [selClientId, user.portalid, reloadCallsList]);

  const stClientCallsList = new ArrayStore({
    key: 'call_no',
    data: clientCallsList,
  });

  const startCall = async () => {
    const roomId = uuidv4();
    try {
      const response = await HTTP.post(
        '/client/start-client-call',
        JSON.stringify({
          clientid: selClientId,
          portalid: user.portalid,
          userid: user.userId,
          call_roomid: roomId,
        })
      );
      console.log(response.data);
      setReloadCallsList(!reloadCallsList);

      user.clientcall = true;
      user.call_room = roomId;

      console.log('Start Client Call');
      console.log(1, user.clientcall);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.6} screen='md lg sm xs' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Call</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button width={130} height={36} text='Start' type='default' stylingMode='outlined' onClick={startCall} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={1} />
          <div>
            <DataGrid
              id='client-comms-documents-grid'
              height={700}
              dataSource={stClientCallsList}
              defaultFocusedRowIndex={0}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                // console.log(e);
                setCallRow(e.row.data);
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='call_no' visible={false} />
              <Column dataField='call_invite_time' aption='Date' dataType='datetime' visible={true} width={130} />
              <Column dataField='username' visible={true} caption='User' width={120} />
              <Column dataField='callstate' visible={true} caption='State' width={100} />
              <Column dataField='call_recorded' visible={true} caption='Recorded' width={100} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientCalls;

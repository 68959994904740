import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ClientAccessSignupPop = (props) => {
  const { showPopup, hidePopup, user, selClientid } = props;
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [selRisklevel, setSelRisklevel] = useState(-1);
  const [grantAccess, setGrantAccess] = useState(false);

  useEffect(() => {
    const fetchRiskLevel = async () => {
      const dataArray = await HTTP.get('/base/getlookupvalues/12');
      setLpRiskLevel(dataArray.data);
    };

    if (showPopup) {
      fetchRiskLevel();
    }
  }, [showPopup]);

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const saveGrantAccess = async () => {
    if (grantAccess === true) {
      try {
        await HTTP.post(
          '/client/signupclientaccess',
          JSON.stringify({
            clientid: selClientid,
            risklevelid: selRisklevel,
            userid: user.id,
          })
        );
        hidePopup(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Popup
      title={'Client Signup'}
      width={400}
      height={300}
      showCloseButton={true}
      visible={showPopup}
      onHiding={() => {
        setSelRisklevel(-1);
        setGrantAccess(false);
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='signup-pop-region'>
            <div id='signup-pop-label'>
              <table>
                <tbody>
                  <tr>
                    <td width='150px'>Set the Risklevel to</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id='signup-pop-select'>
              <SelectBox
                width={'300px'}
                dataSource={stRiskLevel}
                valueExpr={'lp_id'}
                displayExpr={'lp_value'}
                defaultValue={selRisklevel}
                value={selRisklevel}
                stylingMode='outlined'
                label='Risk Level'
                placeholder='Select...'
                labelMode='static'
                onValueChanged={(e) => {
                  e && setSelRisklevel(e.value);
                }}
              />
            </div>
            <CheckBox
              text='Grant Access to Product Selection'
              value={grantAccess}
              onValueChanged={(e) => {
                setGrantAccess(e.value);
              }}
            />
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={saveGrantAccess}
              />
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientAccessSignupPop;

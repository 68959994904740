import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';


import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './costrateservices.css';

import 'devextreme-react/switch';

import Tabs from 'devextreme-react/tabs';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow, Editing, Lookup } from 'devextreme-react/data-grid';

import Box from 'devextreme-react/box';
import PartnerRiskLevelCopyPop from './PartnerRiskLevelCopyPop';
import MessagePop from '../../ui_elements/MessagePop';
import HTTP from '../../api/HTTP';

const PartnerServiceFeesEdit = () => {
  const { user } = useAuth();
  const history = useHistory();

  const selServiceid = user.costrates.selServiceid;
  const selCostEntryid = user.costrates.selCostEntryid;
  const selWorkId = user.costrates.workId;
  const selOriginid = user.costrates.originid;
  const selPartnerId = user.costrates.selPartnerId;

  const [costRateEntryList, setCostRateEntryList] = useState([]);
  const [lpFlatRateType, setLpFlatRateType] = useState([]);
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [lpCostType, setLpCostType] = useState([]);
  const [lpFlatRatePeriod, setLpFlatRatePeriod] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [lpCustomerType, setLpCustomerType] = useState([]);
  const [lpRegionalLimitType, setLpRegionalLimitType] = useState([]);
  const [selRiskid, setSelRiskid] = useState(1);
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);
  const [riskGridData, setRiskGridData] = useState({});
  const [lpRegions, setLpRegions] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(
          `/work/singlepartnercostrateheader/${selPartnerId}/${selWorkId}/${selCostEntryid}`
        );
        setCostRateHeaderList(dataArray.data[0]);
      } catch (err) {}
    };
    if (selCostEntryid && selCostEntryid > -1) {
      fetchCostRatesHeader();
    }
  }, [selCostEntryid, reloadData]);

  const selCostRateHeaderData = {
    workId: selWorkId,
    partnerid: selPartnerId,
    serviceid: selServiceid,
    costentryid: selCostEntryid,
    servicename: costRateHeaderList.servicename,
    generated: costRateHeaderList.generated,
    valid_on: costRateHeaderList.valid_on,
    valid_until: costRateHeaderList.valid_until,
    currency: costRateHeaderList.currency,
    entrynumber: costRateHeaderList.entrynumber,
    userid: costRateHeaderList.userid,
    active: costRateHeaderList.active,
    active_date: costRateHeaderList.active_date,
  };

  useEffect(() => {
    const fetchCostRiskEntries = async () => {
      try {
        const dataArray = await HTTP.get(`/work/partnercostriskentries/${selPartnerId}/${selWorkId}/${selCostEntryid}`);
        setCostRiskEntriesList(dataArray.data);
      } catch (err) {}
    };
    if (selCostEntryid && selCostEntryid > -1) {
      fetchCostRiskEntries();
    }
  }, [selCostEntryid, reloadData]);

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  useEffect(() => {
    const fetchCustomerType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/3');
        setLpCustomerType(dataArray.data);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/4');
        setLpRiskLevel(dataArray.data);
      } catch (err) {}
    };

    const fetchCostType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/6');
        setLpCostType(dataArray.data);
      } catch (err) {}
    };

    const fetchFlatRateType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/5');
        setLpFlatRateType(dataArray.data);
      } catch (err) {}
    };

    const fetchFlatRatePeriod = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/2');
        setLpFlatRatePeriod(dataArray.data);
      } catch (err) {}
    };

    const fetchRegionalLimitType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/13');
        setLpRegionalLimitType(dataArray.data);
      } catch (err) {}
    };

    const fetchRegions = async () => {
      try {
        const dataArray = await HTTP.get('/service/regions');
        setLpRegions(dataArray.data);
      } catch (err) {}
    };

    fetchCustomerType();
    fetchRiskLevel();
    fetchCostType();
    fetchFlatRateType();
    fetchFlatRatePeriod();
    fetchRegionalLimitType();
    fetchRegions();
  }, []);

  const stCostTypeData = new ArrayStore({
    key: 'lp_id',
    data: lpCostType,
  });

  const stFlatRateTypeData = new ArrayStore({
    key: 'lp_id',
    data: lpFlatRateType,
  });

  const stFlatRatePeriod = new ArrayStore({
    key: 'lp_id',
    data: lpFlatRatePeriod,
  });

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const stCustomerType = new ArrayStore({
    key: 'lp_id',
    data: lpCustomerType,
  });

  const stRegionalLimitType = new ArrayStore({
    key: 'lp_id',
    data: lpRegionalLimitType,
  });

  const stRegions = new ArrayStore({
    key: 'regionid',
    data: lpRegions,
  });

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await HTTP.get(
          `/work/partnercostrateentries/${selPartnerId}/${selWorkId}/${selCostEntryid}/${selRiskid}`
        );
        setCostRateEntryList(dataArray.data);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [ selCostEntryid, selRiskid, reloadData]);

  const stCostRateEntriesData = new ArrayStore({
    key: ['costentryid', 'feature_group_assetid', 'featureid'],
    data: costRateEntryList,
  });

  const dcCostRateEntriesData = new DataSource({
    store: stCostRateEntriesData,
  });

  const saveRiskentry = async (ancostRateEntryData) => {
    try {
      await HTTP.post(
        '/work/partnerserviceriskcostentry',
        JSON.stringify({
          workid: selWorkId,
          partnerid: ancostRateEntryData.partnerid,
          serviceid: ancostRateEntryData.serviceid,
          costentryid: ancostRateEntryData.costentryid,
          riskid: ancostRateEntryData.riskid,
          featureid: ancostRateEntryData.featureid,
          setupfee: ancostRateEntryData.setupfee,
          recurringfee: ancostRateEntryData.recurringfee,
          recurringperiodid: ancostRateEntryData.recurringperiodid,
          not_applicable: ancostRateEntryData.not_applicable,
          regional_limittypeid: ancostRateEntryData.regional_limittypeid,
          regional_limit_id: ancostRateEntryData.regional_limit_id,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveServerCostEntry = async (ancostRateEntryData) => {
    try {
      await HTTP.post(
        '/work/partnerservicecostentry',
        JSON.stringify({
          workid: selWorkId,
          partnerid: ancostRateEntryData.partnerid,
          serviceid: ancostRateEntryData.serviceid,
          costentryid: ancostRateEntryData.costentryid,
          riskid: ancostRateEntryData.riskid,
          featureid: ancostRateEntryData.featureid,
          costvalue: ancostRateEntryData.costvalue,
          costperc: ancostRateEntryData.costperc,
          costperc_minvalue: ancostRateEntryData.costperc_minvalue,
          costperc_maxvalue: ancostRateEntryData.costperc_maxvalue,
          costtype: ancostRateEntryData.costtype,
          flattypeid: ancostRateEntryData.flattypeid,
          flatperiodid: ancostRateEntryData.flatperiodid,
          flatvalue: ancostRateEntryData.flatvalue,
          feature_group_assetid: ancostRateEntryData.feature_group_assetid,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveRateHeader = async (ancostRateEntryData) => {
    try {
      await HTTP.post(
        '/work/partnerheader',
        JSON.stringify({
          workid: selWorkId,
          partnerid: ancostRateEntryData.partnerid,
          serviceid: ancostRateEntryData.serviceid,
          costentryid: ancostRateEntryData.costentryid,
          valid_on: ancostRateEntryData.valid_on,
          valid_until: ancostRateEntryData.valid_until,
          currency: ancostRateEntryData.currency,
          entrynumber: ancostRateEntryData.entrynumber,
          comment: ancostRateEntryData.comment,
          userid: ancostRateEntryData.userid,
          active: ancostRateEntryData.active,
          active_date: ancostRateEntryData.active_date,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveWorktoMain = async (an_Operation) => {
    try {
      const dataArray = await HTTP.get(`/work/saveworkpartnercost/${an_Operation}/${selWorkId}`);
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const SaveWork = async () => {
    await saveRateHeader(selCostRateHeaderData);
    await saveWorktoMain(1);
    navigateToHome();
  };

  const CancelSaveWork = async () => {
    await saveWorktoMain(3);
    navigateToHome();
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadData(!reloadData);
  };

  const showRiskLevelCopyPopup = () => {
    setShowPopup(true);
  };

  return (
    <React.Fragment>
      <PartnerRiskLevelCopyPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        selWorkId={selWorkId}
        selPartnerId={selPartnerId}
        selServiceId={selServiceid}
        selCostEntryid={selCostEntryid}
        selRiskid={selRiskid}
        user={user}
      />

      <h2 className={'content-block'}>Partner Service - {selCostRateHeaderData.servicename} - Sales Fees</h2>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={10} />
            <Row ratio={0.2} />
            <Col />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />

              <Box id='service-fee-box1' direction='row' width='100%'>
                <Item ratio={1}>
                  <div className='dx-fieldset-header'>Partner Sell Rates</div>
                </Item>
                <Item ratio={7}>
                  <Form
                    id='service-fee-edit-form'
                    // ref={dataFormRef}
                    formData={selCostRateHeaderData}
                    labelLocation={'left'}
                    colCountByScreen={colCountByScreen}
                    // validationGroup='financial_Routing_Create_Data'
                  >
                    <Item itemType='group' colCount={5}>
                      <SimpleItem dataField='currency'>
                        <Label text='Currency' />
                      </SimpleItem>
                      <SimpleItem dataField='valid_on' dataType='date' editorType='dxDateBox'>
                        <Label text='Valid on' />
                      </SimpleItem>
                      <SimpleItem dataField='valid_until' dataType='date' editorType='dxDateBox'>
                        <Label text='Valid on' />
                      </SimpleItem>
                    </Item>
                    <Item itemType='group' colCount={6}>
                      <SimpleItem dataField='active' dataType='boolean' editorType='dxCheckBox'>
                        <Label text='Active' />
                      </SimpleItem>
                      <SimpleItem
                        dataField='active_date'
                        dataType='date'
                        editorType='dxDateBox'
                        editorOptions={{ readOnly: true }}
                      >
                        <Label text='Active on' />
                      </SimpleItem>
                    </Item>
                  </Form>
                </Item>
              </Box>

              <Box id='service-fee-box2' direction='row' width='100%'>
                <Item ratio={1}>
                  <div className='dx-fieldset-header'>Risk Level</div>
                  <div id='service-edit-btn-section-1'>
                    <Button
                      width={120}
                      height={50}
                      text='copy'
                      type='normal'
                      stylingMode='outlined'
                      onClick={showRiskLevelCopyPopup}
                    />
                  </div>
                </Item>
                <Item ratio={7}>
                  <DataGrid
                    id='service-cost-rates-risk-grid-base'
                    dataSource={dcCostRiskEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                    onFocusedRowChanged={(e) => {
                      setRiskGridData(e.row.data);
                      setSelRiskid(e.row.data.riskid);
                    }}
                    onSaved={(e) => {
                      if (e.changes[0]) {
                        saveRiskentry(e.changes[0].data);
                      }
                    }}
                  >
                    <Column dataField='workid' visible={false} />
                    <Column dataField='partnerid' visible={false} />
                    <Column dataField='serviceid' visible={false} />
                    <Column dataField='costentryid' visible={false} />
                    <Column dataField='riskid' visible={false} />
                    <Column dataField='risklevel' caption='Risk Level' width='150px' allowEditing={false} />
                    <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                    <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                    <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='110' />
                    <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='110' />
                    <Column dataField='recurringperiodid' caption='Recurring' width='100'>
                      <Lookup dataSource={stFlatRatePeriod} displayExpr='lp_value' valueExpr='lp_id' />
                    </Column>
                    <Column dataField='regional_limittypeid' caption='Limited to' width='140'>
                      <Lookup dataSource={stRegionalLimitType} displayExpr='lp_value' valueExpr='lp_id' />
                    </Column>
                    <Column dataField='regional_limit_id' caption='Countries' width='180'>
                      <Lookup dataSource={stRegions} displayExpr='regionname' valueExpr='regionid' />
                    </Column>
                    <Editing mode='row' allowUpdating={true} />
                  </DataGrid>
                </Item>
              </Box>

              <Box id='service-fee-box3' direction='row' width='100%'>
                <Item ratio={1}></Item>
                <Item ratio={7}>
                  <DataGrid
                    id='service-cost-rates-entries-base-edit-grid'
                    dataSource={dcCostRateEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                    onSaved={(e) => {
                      if (e.changes[0]) {
                        saveServerCostEntry(e.changes[0].data);
                      }
                    }}
                  >
                    <FilterRow visible={false} />
                    <Column dataField='workid' visible={false} />
                    <Column dataField='partnerid' visible={false} />
                    <Column dataField='serviceid' visible={false} />
                    <Column dataField='costentryid' visible={false} />
                    <Column dataField='riskid' visible={false} />
                    <Column dataField='featureid' visible={false} />
                    <Column dataField='regional_limit_id' visible={false} />
                    <Column dataField='feature' caption='Service Feature' width={250} allowEditing={false} />
                    <Column dataField='feature_group_asset' caption='Group' width={100} allowEditing={false} />
                    <Column dataField='costtype' caption='Cost Type' width={80} visible={false}>
                      <Lookup dataSource={stCostTypeData} displayExpr='lp_value' valueExpr='lp_id' />
                    </Column>
                    <Column dataField='costvalue' caption='Fixed Fee' dataType='number' width={80} />
                    <Column dataField='costperc' caption='Percent of Value' dataType='number' width={120} />
                    <Column dataField='costperc_minvalue' caption='Min Value' dataType='number' width={80} />
                    <Column dataField='costperc_maxvalue' caption='Max Value' dataType='number' width={80} />
                    <Column dataField='flattypeid' caption='Flat Type' width={90}>
                      <Lookup dataSource={stFlatRateTypeData} displayExpr='lp_value' valueExpr='lp_id' />
                    </Column>
                    <Column dataField='flatvalue' dataType='number' caption='Flat Value' width={90} />
                    <Column dataField='flatperiodid' caption='Flat Period' width={90}>
                      <Lookup dataSource={stFlatRatePeriod} displayExpr='lp_value' valueExpr='lp_id' />
                    </Column>

                    <Editing mode='row' allowUpdating={true} />
                  </DataGrid>
                </Item>
              </Box>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <div id={'services-edit-button'}>
                <div style={{ flexDirection: 'row' }}>
                  <Button
                    id='savebutton'
                    width={120}
                    height={50}
                    text='Save'
                    type='normal'
                    stylingMode='contained'
                    onClick={SaveWork}
                  />
                  <Button
                    id='services-edit_close-button'
                    width={120}
                    height={50}
                    text='Close'
                    type='normal'
                    stylingMode='contained'
                    onClick={CancelSaveWork}
                  />
                </div>
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default PartnerServiceFeesEdit;

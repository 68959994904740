import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import './ProductPage.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import ProductCostRiskServiceDetailGrid from '../../detailgrids/ProductCostRiskServiceDetailGrid';
import HTTP from '../../api/HTTP';

const ProductPricing = (props) => {
  const { selProductId, selPartnerId, user } = props;
  const history = useHistory();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(`/product/productcostrateheader/${selProductId}/${selPartnerId}`);
        setCostRateHeaderList(dataArray.data[0]);

        let anSellRateId = -1;

        if (dataArray.data[0].sellrateid) {
          anSellRateId = dataArray.data[0].sellrateid;
        }

        const costRiskDataArray = await HTTP.get(
          `/product/viewproductcostriskentries/${selProductId}/${selPartnerId}/${anSellRateId}`
        );

        setCostRiskEntriesList(costRiskDataArray.data);
      } catch (err) {}
    };
    if (selPartnerId && selProductId) {
      fetchCostRatesHeader();
    }
  }, [selPartnerId, selProductId]);

  const costRateHeader = {
    productid: (costRateHeaderList && costRateHeaderList.productid) || -1,
    partnerid: (costRateHeaderList && costRateHeaderList.partnerid) || -1,
    sellrateid: (costRateHeaderList && costRateHeaderList.sellrateid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active_date: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />

          <ResponsiveBox>
            <Col />
            <Row />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <Form
                id='partner-cost-entries-form-edit-base'
                formData={costRateHeader}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
                scrollingEnabled={true}
              >
                <Item itemType='group' colCount={5}>
                  <SimpleItem dataField='currency'>
                    <Label text='Currency' />
                  </SimpleItem>
                  <SimpleItem dataField='valid_on' dataType='date' editorType='dxDateBox'>
                    <Label text='Valid on' />
                  </SimpleItem>
                  <SimpleItem dataField='valid_until' dataType='date' editorType='dxDateBox'>
                    <Label text='Valid on' />
                  </SimpleItem>
                  <SimpleItem dataField='active' dataType='boolean' editorType='dxCheckBox'>
                    <Label text='Active' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='active_date'
                    dataType='date'
                    editorType='dxDateBox'
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text='Active on' />
                  </SimpleItem>
                </Item>

                <Item>
                  <DataGrid
                    width={1000}
                    dataSource={dcCostRiskEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                  >
                    <Column dataField='productid' visible={false} />
                    <Column dataField='partnerid' visible={false} />
                    <Column dataField='serviceid' visible={false} />
                    <Column dataField='costentryid' visible={false} />
                    <Column dataField='riskid' visible={false} />
                    <Column dataField='risklevel' caption='Risk Level' width='140px' allowEditing={false} />
                    <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                    <Column dataField='not_applicable' caption='Not Applicable' width='110px' />
                    <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='90' />
                    <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='90' />
                    <Column dataField='recperiod' caption='Recurring' width='100'></Column>
                    <MasterDetail enabled={true} component={ProductCostRiskServiceDetailGrid} />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'services-edit-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='services-edit_close-button'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductPricing;

import React, { useEffect, useRef, useState } from "react";
import { Button } from "devextreme-react/button";
import TextArea from "devextreme-react/text-area";
import Box from "devextreme-react/box";
import Helpers from "./Helpers";
import "./Call.css";
import { io } from "socket.io-client";
import { useAuth } from "../../contexts/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faDesktop,
  faVideo,
  faUserPlus,
  faVideoSlash,
  faRecordVinyl,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
} from "@fortawesome/free-solid-svg-icons";

const Call = (props) => {
  const { roomId } = props;
  const { user } = useAuth();

  const room = roomId;
  // console.log(user.firstName + " " + user.lastName);

  const screen = useRef("");
  const userName = useRef(user.firstName + " " + user.lastName);
  const myStream = useRef("");
  const localStream = useRef();
  const mediaRecorder = useRef("");
  const recordedStream = useRef([]);
  const peerConnection = useRef([]);

  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [videoActive, setVideoActive] = useState(false);
  const [muteActive, setMuteActive] = useState(false);
  const [shareScreenActive, setShareScreenActive] = useState(false);
  const [recordActive, setRecordActive] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [disableVideoButton, setDisableVideoButton] = useState(false);

  useEffect(() => {
    let commElem = document.getElementsByClassName("room-comm");

    for (let i = 0; i < commElem.length; i++) {
      commElem[i].attributes.removeNamedItem("hidden");
    }

    let socketId = "";
    let socket = io.connect(process.env.REACT_APP_SERVER);

    // Get user video by default
    getAndSetUserStream();

    socket.on("connect", () => {
      // Set socketId
      socketId = socket.io.engine.id;

      socket.emit("subscribe", {
        room: room,
        socketId: socketId,
      });

      socket.on("new user", (data) => {
        socket.emit("newUserStart", { to: data.socketId, sender: socketId });
        peerConnection.current.push(data.socketId);
        // console.log('socketId: ' + data.socketId)
        init(true, data.socketId);
      });

      socket.on("newUserStart", (data) => {
        peerConnection.current.push(data.sender);
        init(false, data.sender);
      });

      socket.on("ice candidates", async (data) =>
        data.candidate
          ? await peerConnection.current[data.sender].addIceCandidate(
              new RTCIceCandidate(data.candidate)
            )
          : ""
      );

      // Close video of socketId after user disconnected
      socket.on("disconnected", (data) => {
        // console.log(data);
        // console.log(peerConnection.current)
        Helpers.closeVideo(data.socketId);
      });

      // console.log(socket);

      socket.on("sdp", async (data) => {
        if (data.description.type === "offer") {
          getDataDescription(data);

          Helpers.getUserFullMedia()
            .then(async (stream) => {
              if (!localStream.current.srcObject) {
                Helpers.setLocalStream(stream);
              }

              // Save my stream
              myStream.current = stream;

              stream.getTracks().forEach((track) => {
                peerConnection.current[data.sender].addTrack(track, stream);
              });

              let answer = await peerConnection.current[
                data.sender
              ].createAnswer();

              await peerConnection.current[data.sender].setLocalDescription(
                answer
              );

              socket.emit("sdp", {
                description:
                  peerConnection.current[data.sender].localDescription,
                to: data.sender,
                sender: socketId,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        } else if (data.description.type === "answer") {
          await peerConnection.current[data.sender].setRemoteDescription(
            new RTCSessionDescription(data.description)
          );
        }
      });

      socket.on("chat", (data) => {
        Helpers.addChat(data, "remote");
      });
    });

    async function getDataDescription(data) {
      return data.description
        ? await peerConnection.current[data.sender].setRemoteDescription(
            new RTCSessionDescription(data.description)
          )
        : "";
    }

    function getAndSetUserStream() {
      Helpers.getUserFullMedia()
        .then((stream) => {
          // Save my stream
          myStream.current = stream;

          Helpers.setLocalStream(stream);
        })
        .catch((e) => {
          console.error(`Stream error: ${e}`);
        });
    }

    function sendMsg(msg) {
      let data = {
        room: room,
        msg: msg,
        sender: `${userName.current}`,
      };

      socket.emit("chat", data);

      Helpers.addChat(data, "local");
    }

    function init(createOffer, partnerName) {
      peerConnection.current[partnerName] = new RTCPeerConnection(
        Helpers.getIceServer()
      );

      if (screen.current && screen.current.getTracks().length) {
        screen.current.getTracks().forEach((track) => {
          peerConnection.current[partnerName].addTrack(track, screen.current);
        });
      } else if (myStream.current) {
        myStream.current.getTracks().forEach((track) => {
          peerConnection.current[partnerName].addTrack(track, myStream.current);
        });
      } else {
        Helpers.getUserFullMedia()
          .then((stream) => {
            // Save my stream
            myStream.current = stream;

            stream.getTracks().forEach((track) => {
              peerConnection.current[partnerName].addTrack(track, stream);
            });

            Helpers.setLocalStream(stream);
          })
          .catch((e) => {
            console.error(`stream error: ${e}`);
          });
      }

      // Create offer
      if (createOffer) {
        peerConnection.current[partnerName].onnegotiationneeded = async () => {
          let offer = await peerConnection.current[partnerName].createOffer();

          await peerConnection.current[partnerName].setLocalDescription(offer);

          socket.emit("sdp", {
            description: peerConnection.current[partnerName].localDescription,
            to: partnerName,
            sender: socketId,
          });
        };
      }

      // Send ice candidate to partnerNames
      peerConnection.current[partnerName].onicecandidate = ({ candidate }) => {
        socket.emit("ice candidates", {
          candidate: candidate,
          to: partnerName,
          sender: socketId,
        });
      };

      // Add
      peerConnection.current[partnerName].ontrack = (e) => {
        let str = e.streams[0];
        if (document.getElementById(`${partnerName}-video`)) {
          document.getElementById(`${partnerName}-video`).srcObject = str;
        } else {
          // Video elem
          let newVid = document.createElement("video");
          newVid.id = `${partnerName}-video`;
          newVid.srcObject = str;
          newVid.autoplay = true;
          newVid.className = "remote-video";

          // Create a new div for card
          let cardDiv = document.createElement("Item");
          cardDiv.className = "card card-sm";
          cardDiv.id = partnerName;
          cardDiv.appendChild(newVid);

          // Put div in main-section elem
          document.getElementById("videos").appendChild(cardDiv);

          Helpers.adjustVideoElemSize();
        }
      };

      peerConnection.current[partnerName].onconnectionstatechange = (d) => {
        // console.log(partnerName);
        switch (peerConnection.current[partnerName].iceConnectionState) {
          case "disconnected":
          case "failed":
            Helpers.closeVideo(partnerName);
            break;

          case "closed":
            Helpers.closeVideo(partnerName);
            break;
          default:
            return "";
        }
      };

      peerConnection.current[partnerName].onsignalingstatechange = (d) => {
        switch (peerConnection.current[partnerName].signalingState) {
          case "closed":
            console.log("Signalling state is 'closed'");
            Helpers.closeVideo(partnerName);
            break;
          default:
            return "";
        }
      };
    }

    // Chat textarea
    document.getElementById("chat-input").addEventListener("keypress", (e) => {
      if (e.key === "Enter" && e.target.value.trim()) {
        e.preventDefault();

        sendMsg(e.target.value);

        setTimeout(() => {
          e.target.value = "";
        }, 50);
      }
    });
  }, [room]);

  const broadcastNewTracks = (stream, type, mirrorMode = true) => {
    Helpers.setLocalStream(stream, mirrorMode);

    let track =
      type === "audio"
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];

    for (let p in peerConnection.current) {
      let pName = peerConnection.current[p];

      if (typeof peerConnection.current[pName] == "object") {
        Helpers.replaceTrack(track, peerConnection.current[pName]);
      }
    }
  };

  const shareScreen = () => {
    Helpers.shareScreen()
      .then((stream) => {
        screen.current = stream;
        setShareScreenActive(true);
        setDisableVideoButton(true);
        broadcastNewTracks(stream, "video", false);
        screen.current.getVideoTracks()[0].addEventListener("ended", () => {
          stopSharingScreen();
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  async function getScreenTracks() {
    return screen.current.getTracks().length
      ? screen.current.getTracks().forEach((track) => track.stop())
      : "";
  }

  const stopSharingScreen = async () => {
    return new Promise((res, rej) => {
      getScreenTracks();
      res();
    })
      .then(() => {
        setShareScreenActive(false);
        setDisableVideoButton(false);
        broadcastNewTracks(myStream.current, "video");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const startRecording = (stream) => {
    setRecordActive(true);

    mediaRecorder.current = new MediaRecorder(stream);

    mediaRecorder.current.start(1000);

    mediaRecorder.current.ondataavailable = function (e) {
      recordedStream.current.push(e.data);
    };

    mediaRecorder.current.onstop = function () {
      setRecordActive(false);

      Helpers.saveRecordedStream(recordedStream.current, userName.current);

      setTimeout(() => {
        mediaRecorder.current = "";
        recordedStream.current = [];
      }, 3000);
    };

    mediaRecorder.current.onerror = function (e) {
      console.error(e);
    };
  };

  const toggleMute = () => {
    setMuteActive(!muteActive);
    // console.log(myStream.current.getAudioTracks()[0])

    if (myStream.current.getAudioTracks()[0].enabled) {
      myStream.current.getAudioTracks()[0].enabled = false;
    } else {
      myStream.current.getAudioTracks()[0].enabled = true;
    }

    broadcastNewTracks(myStream.current, "audio");
  };

  const toggleVideo = () => {
    setVideoActive(!videoActive);

    if (myStream.current.getVideoTracks()[0].enabled) {
      myStream.current.getVideoTracks()[0].enabled = false;
    } else {
      myStream.current.getVideoTracks()[0].enabled = true;
    }

    broadcastNewTracks(myStream.current, "video");
  };

  const toggleShareScreen = () => {
    if (
      screen.current &&
      screen.current.getVideoTracks().length &&
      screen.current.getVideoTracks()[0].readyState !== "ended"
    ) {
      stopSharingScreen();
    } else {
      shareScreen();
    }
  };

  const toggleRecord = () => {
    if (!mediaRecorder.current || mediaRecorder.current.state === "inactive") {
      Helpers.shareScreen()
        .then((screenStream) => {
          startRecording(screenStream);
        })
        .catch(() => {});
    } else if (mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
    } else if (mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
  };

  const toggleChat = () => {
    setChatActive(!chatActive);

    let chatElem = document.querySelector("#chat-pane");

    if (chatElem.classList.contains("chat-opened")) {
      chatElem.setAttribute("hidden", true);
      chatElem.classList.remove("chat-opened");
    } else {
      chatElem.attributes.removeNamedItem("hidden");
      chatElem.classList.add("chat-opened");
    }

    // Remove the 'New' badge on chat icon once chat is opened.
    setTimeout(() => {
      if (
        document.querySelector("#chat-pane").classList.contains("chat-opened")
      ) {
        Helpers.toggleChatNotificationBadge();
      }
    }, 300);
  };

  const addUser = () => {
    console.log("Add user");
  };

  return (
    <>
      <div id="callOptionsMenu">
        <Button className="button-menu" onClick={toggleMute}>
          <FontAwesomeIcon
            icon={muteActive ? faMicrophoneAltSlash : faMicrophoneAlt}
          />
        </Button>
        <Button
          className="button-menu"
          disabled={disableVideoButton}
          onClick={toggleVideo}
        >
          <FontAwesomeIcon icon={videoActive ? faVideoSlash : faVideo} />
        </Button>
        <Button className="button-menu" onClick={toggleShareScreen}>
          <FontAwesomeIcon
            icon={faDesktop}
            color={shareScreenActive ? "#70db70" : "white"}
          />
        </Button>
        <Button className="button-menu" onClick={toggleRecord}>
          <FontAwesomeIcon
            icon={faRecordVinyl}
            color={recordActive ? "#ff4d4d" : "white"}
          />
        </Button>
        {/* <Button
          className="button-menu"
          onClick={toggleChat}
          id="toggle-chat-pane"
        >
          <FontAwesomeIcon
            icon={faComment}
            color={chatActive ? "#6699ff" : "white"}
          />
          <span
            className="badge badge-danger very-small font-weight-lighter"
            id="new-chat-notification"
            hidden
          >
            New
          </span>
        </Button> */}
        <Button className="button-menu" onClick={addUser}>
          <FontAwesomeIcon icon={faUserPlus} />
        </Button>
      </div>
      <div>
        <div className="container-fluid room-comm" hidden>
          <video
            className="local-video mirror-mode"
            id="local"
            volume="0"
            autoPlay
            muted
            ref={localStream}
          ></video>

          <div className="row">
            <Box
              noDataText=""
              direction="row"
              width="100%"
              className="mt-2 mb-2"
              id="videos"
            ></Box>

            <div
              className="col-md-3 chat-col d-print-none mb-2"
              id="chat-pane"
              hidden
            >
              <div className="col-12 text-center h2 mt-3 mb-4">CHAT</div>

              <div id="chat-messages"></div>

              <div>
                <TextArea
                  id="chat-input"
                  className="chat-box"
                  placeholder="Type here..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Call;

import React, { useState, useEffect } from 'react';
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';
import './GlobalDefinitions.css';
import HTTP from '../../api/HTTP';

function PartnerEditOnboardingGrid(props) {
  const { user } = props;
  const [lpLookupDataEntries, setLpLookupDataEntries] = useState([]);
  const [partnerOnboardingLookup, setPartnerOnboardingLookup] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchLookupRisk = async () => {
      try {
        const dataArray = await HTTP.get(`/base/getlookupvalues/12`);
        setLpLookupDataEntries(dataArray.data);
      } catch (err) {}
    };
    fetchLookupRisk();
  }, [reloadData]);

  useEffect(() => {
    const fetchGetPartnerOnboarding = async () => {
      try {
        const dataArray = await HTTP.get(`/partner/partneronboarding/${user.partnerid}`);
        setPartnerOnboardingLookup(dataArray.data);
      } catch (err) {}
    };
    fetchGetPartnerOnboarding();
  }, [reloadData]);

  const saveOnboardingAssetEntry = async (e) => {
    try {
      await HTTP.post(
        '/partner/modpartneronboarding',
        JSON.stringify({
          operation: 2,
          partnerid: user.partnerid,
          riskid: e.riskid,
          pay_before_kyc: e.pay_before_kyc,
          onboarding_fee: e.onboarding_fee,
        })
      ).then(() => setReloadData(!reloadData));
    } catch (err) {
      console.log(err);
    }
  };

  const onboardingArray = lpLookupDataEntries.map((item, i) => Object.assign({}, item, partnerOnboardingLookup[i]));

  return (
    <div id='setting-onboarding-box'>
      <React.Fragment>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Onboarding Fee</div>
        </div>
        <div id={'setting-onboard-content-block'}>
          <div>
            These Settings allow to define an On Boarding Fee and whether the On Boarding Fee is to be paid before or
            after the KYC Verification
          </div>
        </div>
        <DataGrid
          dataSource={onboardingArray}
          height={300}
          keyExpr='lp_id'
          showBorders={true}
          selectedRowKeys={selectedItemKeys}
          columnAutoWidth={true}
          autoNavigateToFocusedRow={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          allowSorting={false}
          onSaved={(e) => {
            if (e.changes[0]) {
              saveOnboardingAssetEntry(e.changes[0].data, e.changes[0].type);
            }
          }}
        >
          <Paging enabled={false} />
          <Column allowEditing={false} dataField='lp_id' caption='Key' width={50} visible={false} />
          <Column allowEditing={false} dataField='lp_value' dataType='string' caption='Risk' />
          <Editing mode='row' allowUpdating={true} />
          <Column dataField='pay_before_kyc' caption='Pay before KYC' />
          <Column />
          <Column dataField='onboarding_fee' caption='Onboarding Fee' dataType='number' />
        </DataGrid>
      </React.Fragment>
    </div>
  );
}

export default PartnerEditOnboardingGrid;

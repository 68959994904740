import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import AddInquiry from '../../../modules/inquiries/AddInquiry';

import notify from 'devextreme/ui/notify';
import InternalMessagingHelper from '../../messages/internalMessaging/InternalMessagingHelper';
import { downloadFileFromBuffer } from '../../../utils/attachesHelper';
import HTTP from '../../../api/HTTP';

const PortalDocuments = (props) => {
  const { user, selClientId, selPortalId } = props;
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const [clientDocList, setClientDocList] = useState([]);
  const [docRow, setDocRow] = useState({});

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const dataArray = await HTTP.get(
          `/client/clientdocuments/${selClientId}/${user.providerId}`,
        );
        setClientDocList(dataArray.data);
      } catch (err) {}
    };
    if (selClientId) {
      fetchDocuments();
    }
  }, [selClientId]);

  const downloadDocumentFile = async (anDocId, anFileName) => {
    try {
      await fetch(process.env.REACT_APP_BACKEND_URL + `/document/getDocumentDownload/${anDocId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then((response) => response.blob())
        .then(function (b) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(b);
          fileReader.onloadend = async () => {
            if (fileReader.result) {
              const link = document.createElement('a');
              link.href = fileReader.result.toString();
              link.setAttribute('download', anFileName);
              document.body.appendChild(link);
              link.click();
            }
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const downloadMessageFile = (documentId) => {
    InternalMessagingHelper.getFileContent(documentId, selPortalId)
      .then((data) => {
        downloadFileFromBuffer(data.fileContent.data, data.fileName);
      })
      .catch((e) => {
        notify('An error occurred while downloading the file', 'error', 2000);
      });
  };

  const openDocuments = async () => {
    if (docRow.doc_source == 1) {
      await downloadDocumentFile(docRow.doc_id, docRow.doc_name);
    } else {
      await downloadMessageFile(docRow.doc_id);
    }
  };

  return (
    <ResponsiveBox>
      <Col ratio={0.6} screen='md lg sm xs' />
      <Col ratio={2} />
      <Row />

      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div id='hm-btn-section-1'>
          <p>Document</p>
        </div>
        <div id='hm-btn-section-1'>
          <Button width={130} height={36} text='Download' type='success' stylingMode='outlined' onClick={openDocuments}/>
        </div>
      </Item>

      <Item>
        <Location screen='md lg sm' row={0} col={1} />
        <Location screen='xs' row={0} col={1} />
        <div>
          <DataGrid
            id='client-comms-documents-grid'
            height={700}
            dataSource={clientDocList}
            keyExpr={['doc_source', 'doc_id']}
            defaultFocusedRowIndex={0}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            wordWrapEnabled={true}
            showRowLines={true}
            showColumnLines={true}
            showColumnHeaders={true}
            onFocusedRowChanged={(e) => {
              console.log(e);
              setDocRow(e.row.data);
              //   setSelInquiryId(e.row.key);
            }}
          >
            <FilterRow visible={false} />
            <Column dataField='doc_id' visible={false} caption='doc_id' />
            <Column dataField='doc_source' visible={false} caption='Source' width={80} />
            <Column dataField='source_type' visible={true} caption='Source' width={100} /> 
            <Column dataField='doc_name' alignment='left' caption='Document' visible={true} width={200} />
            <Column dataField='createdon' caption='Date' dataType='datetime' visible={true} width={130} />
          </DataGrid>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

export default PortalDocuments;

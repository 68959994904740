import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import CasesNotesToPop from '../operations/CasesNotesToPop';

const ClientUserApprovalPop = (props) => {
  const { showPopup, hidePopup, selClientid, selAccountUserId, user, selAccountUser } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactClientid, setContactClientid] = useState([]);
  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteCaseSituation, setNoteCaseSituation] = useState(0);
  const [contactClient, setContactClient] = useState(false);

  const openApprovalNotesToPopup = async () => {
    await setContactClientid(selClientid);
    await setNoteCaseSituation(10);
    await setContactClient(false);
    await setShowCasesNotesTo(true);
  };

  const openAdditionalNotesToPopup = async () => {
    await setContactClientid(selClientid);
    await setNoteCaseSituation(20);
    await setContactClient(true);
    await setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    setShowCasesNotesTo(false);
    hidePopup(true);
  };

  return (
    <Popup
      title={'User Account Approval'}
      width={460}
      height={220}
      showCloseButton={true}
      visible={showPopup}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        caseSituationAction={true}
        caseSituation={noteCaseSituation}
        contactClientid={contactClientid}
        entryid={-1}
        caseEntryid={-1}
        contactPortalid={-1}
        contactClient={contactClient}
      />
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='client-approval-pop-area'>
            <div id='client-approval-pop-one'>
              <table>
                <tbody>
                  <tr>
                    <td width='170px'>Request approval for:</td>
                    <td width='200px'>
                      {selAccountUser &&
                        selAccountUser.cl_lastname +
                          ' ' +
                          selAccountUser.cl_firstname +
                          ', ' +
                          selAccountUser.cl_salutation}
                    </td>
                  </tr>
                  <tr>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> </td>
                  </tr>
                  <tr>
                    <td width='170px'>as additional account user</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={200}
                height={50}
                text='Request Approval'
                type='normal'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={openApprovalNotesToPopup}
              />
              <Button
                id='access-pop-savebutton-right'
                width={200}
                height={50}
                text='Contact Client'
                type='normal'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={openAdditionalNotesToPopup}
              />
              {/* 
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />*/}
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientUserApprovalPop;

import React, { useEffect, useState } from 'react';
import { Toast } from 'devextreme-react/toast';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import Draggable from 'devextreme-react/draggable';
import Resizable from 'devextreme-react/resizable';
import './UserPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';
import HTTP from '../../api/HTTP';
import Call from '../call/Call';

const AdminPanel = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [usersDataList, setUsersDatalist] = useState([]);
  const [selUser, setSelUser] = useState(-1);
  const [roomId, setRoomId] = useState('');
  const [callVisible, setCallVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const dataArray = await HTTP.get(`/user/userofportal/${user.portalid}`);
        setUsersDatalist(dataArray.data);
      } catch (err) {}
    };

    if (user.portalid) {
      fetchUsers();
    }
  }, [user.portalid]);

  const UserCellClick = (e) => {
    setSelUser(e.row.data.id);
  };

  const getUserUserChatid = async () => {
    const response = await HTTP.get(`/management/user-user-chatid/${user.userId}/${selUser}`);
    if (response.data[0]) {
      return response.data[0].chatid;
    } else {
      return -1;
    }
    // return response.data[0].chatid;
  };

  const startCall = async () => {
    console.log(selUser);

    if (selUser !== -1) {
      user.call = {
        selUser: selUser,
      };
      const roomId = uuidv4();
      setRoomId(roomId);
      const response = await HTTP.get(`/base/set-crm-user-call/220/-1/${user.call.selUser}/${user.userId}/${roomId}`);
      // console.log(10, response);
      setCallVisible(true);
    } else {
      setToastVisible(true);
    }
  };

  const endCall = async () => {
    // Stop video stream
    const video = document.querySelector('video');
    const mediaStream = video.srcObject;
    const tracks = mediaStream.getTracks();
    tracks[0].stop();
    tracks.forEach((track) => track.stop());

    // Hide call
    setCallVisible(false);
  };

  const onHiding = async () => {
    setToastVisible(false);
  };

  const openChatPage = async () => {
    const chatid = await getUserUserChatid();

    if (chatid !== -1) {
      user.chat = {
        chatid: chatid,
        selUser: selUser,
      };
      history.push({
        pathname: '/chat',
      });
    }
  };

  return (
    <div>
      <DataGrid
        id='menu-user-grid'
        showBorders={true}
        dataSource={usersDataList}
        keyExpr='id'
        focusedRowEnabled={true}
        showColumnHeaders={false}
        columnAutoWidth={true}
        onCellClick={(e) => {
          e.row && UserCellClick(e);
        }}
      >
        <Column dataField='id' visible={false}></Column>
        <Column dataField='username' caption='User' width='100%'></Column>
      </DataGrid>

      <div>
        <Button
          id='menuadminButton'
          width='92%'
          height='36'
          text='Call'
          type='normal'
          stylingMode='outlined'
          onClick={startCall}
        ></Button>
      </div>
      <div>
        <Button
          id='menuadminButton-2'
          width='92%'
          height='36'
          text='Chat'
          type='normal'
          stylingMode='outlined'
          onClick={openChatPage}
        ></Button>
      </div>
      {callVisible && (
        <Draggable id='callDraggable'>
          <Resizable id='callOverlay'>
            <Call roomId={roomId} />
            <Button id='endCall' text='End call' onClick={endCall}>
              <FontAwesomeIcon icon={faPhoneSlash} />
            </Button>
          </Resizable>
        </Draggable>
      )}

      {toastVisible && (
        <Toast
          visible={toastVisible}
          message='Select a user to call!'
          type='error'
          onHiding={onHiding}
          displayTime={2000}
        />
      )}
    </div>
  );
};

export default AdminPanel;

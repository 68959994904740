export const downloadFileFromBlob = (file, fileName) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onloadend = async () => {
    if (fileReader.result) {
      const link = document.createElement("a");
      link.href = fileReader.result.toString();
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
};

export const bufferToBlob = buffer => {
  const uint8Array = new Uint8Array(buffer);
  return new Blob([uint8Array]);
};

export const downloadFileFromBuffer = (buffer, fileName) => {
  downloadFileFromBlob(bufferToBlob(buffer), fileName);
};
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './PartnerServicesEdit.css';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { SelectBox } from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ServiceProviderEditName = (props) => {
  const { selProviderId, user, serviceName } = props;

  const history = useHistory();
  const dataFormRef = useRef(null);
  const [providerData, setProviderData] = useState({});
  const [companyContact, setCompanyContact] = useState({});
  const [lpCategories, setLpCategories] = useState([]);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const dataArray = await HTTP.get(`/provider/serviceprov/${selProviderId}`);
        setProviderData(dataArray.data[0]);
        let anpersonid = -1;
        if (dataArray.data[0].personid) {
          anpersonid = dataArray.data[0].personid;
        }
        const ContactdataArray = await HTTP.get(`/contacts/company/${dataArray.data[0].personid}`);
        setCompanyContact(ContactdataArray.data[0]);
      } catch (err) {}
    };

    if (selProviderId) {
      fetchServiceProvider();
    }
  }, [selProviderId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const dataArray = await HTTP.get('/service/servicecat');
        setLpCategories(dataArray.data);
      } catch (err) {}
    };

    fetchCategories();
  }, []);

  const stLpCategoriesData = new ArrayStore({
    key: 'category_id',
    data: lpCategories,
  });

  const providerFormData = {
    providerid: (providerData && providerData.providerid) || -1,
    personid: (providerData && providerData.personid) || -1,
    categoryid: (providerData && providerData.categoryid) || -1,
    providername: (providerData && providerData.providername) || '',

    contactid: companyContact.contactid,
    lastname: companyContact.lastname,
    street: companyContact.street,
    city_code: companyContact.city_code,
    city: companyContact.city,
    phone_one: companyContact.phone_one,
    email: companyContact.email,
    homepage: companyContact.homepage,
    addressfield: companyContact.addressfield,
    nationality: companyContact.nationality,
    res_cntry: companyContact.res_cntry,
    contact_cat: companyContact.contact_cat,
  };

  function navigateToHome() {
    history.push({
      pathname: '/partner',
    });
  }

  return (
    <div id='partner-services-provider-box'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>{'Service: ' + serviceName}</div>
      </div>
      <ResponsiveBox>
        <Row />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            ref={dataFormRef}
            formData={providerFormData}
            labelLocation={'left'}
            colCountByScreen={colCountByScreen}
            readOnly={true}
          >
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='providername' colSpan={7}>
                <Label text='Provider' />
              </SimpleItem>
              <SimpleItem
                dataField='categoryid'
                editorType='dxSelectBox'
                colSpan={5}
                name='category_listbox'
                editorOptions={{
                  dataSource: stLpCategoriesData,
                  valueExpr: 'category_id',
                  displayExpr: 'category_name',
                  searchEnabled: true,
                }}
              >
                <Label text='Category' />
              </SimpleItem>
            </Item>

            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='addressfield' editorType='dxTextArea' colSpan={8} editorOptions={{ height: 120 }}>
                <Label text='Address' visible={false} />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='email' colSpan={7}>
                <Label text='E-Mail' />
              </SimpleItem>
              <SimpleItem dataField='phone_one' colSpan={5}>
                <Label text='Phone' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='homepage' colSpan={7}>
                <Label text='Home Page' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceProviderEditName;

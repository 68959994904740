import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'devextreme-react/button';

import InternalMessagingHelper from './internalMessaging/InternalMessagingHelper';
import ThreadMessagesPortals from './internalMessaging/ThreadMessagesPortals';

function MessagePortalsPage(props) {
  const { messageid, openedBy, portalType, selClientId, refreshActivity, own_portalid, tgt_portalid } = props;
  const [clientInfo, setClientInfo] = useState(null);

  const handleBackButton = () => {
    if (clientInfo && clientInfo.customerId) {
      props.history.push({
        pathname: `/clients/${clientInfo.customerId}`,
        state: { openTabIndex: 7 },
      });
    } else {
      props.history.push({ pathname: '/messages' });
    }
  };

  useEffect(() => {
    InternalMessagingHelper.getThreadDetails(messageid).then((response) => {
      if (response?.persons) {
        const clientInfo = response.persons.find((p) => p.customerType === 'CLIENT');
        if (clientInfo) {
          setClientInfo(clientInfo);
        }
      }
    });
  }, [messageid]);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div>
          <ThreadMessagesPortals
            threadId={messageid}
            openedBy={openedBy}
            portalType={portalType}
            selClientId={selClientId}
            refreshActivity={refreshActivity}
            own_portalid={own_portalid} 
            tgt_portalid={tgt_portalid}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessagePortalsPage;

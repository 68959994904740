import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import HTTP from '../../api/HTTP';

const ClientAccountUsersPop = (props) => {
  const { showPopup, hidePopup, user, selClientid } = props;
  const [multiUserConfigData, setMultiUserConfigData] = useState({});

  useEffect(() => {
    const fetchMultiUserConfig = async () => {
      const dataArray = await HTTP.get(`/client/clientmultiuserconfig/${selClientid}`);
      setMultiUserConfigData(dataArray.data[0]);
    };

    if (showPopup && selClientid) {
      fetchMultiUserConfig();
    }
  }, [showPopup, selClientid]);

  const multiUserData = {
    multiple_users:
      multiUserConfigData && multiUserConfigData.multiple_users ? multiUserConfigData.multiple_users : false,
    count_users: multiUserConfigData && multiUserConfigData.count_users ? multiUserConfigData.count_users : 0,
  };

  const saveClientUserCount = async () => {
    try {
      HTTP.post(
        '/client/setclientmultiuserconfig',
        JSON.stringify({
          clientid: selClientid,
          multiple_users: multiUserData.multiple_users,
          count_users: multiUserData.count_users,
        })
      );
      hidePopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Account Users'}
      width={420}
      height={220}
      showCloseButton={true}
      visible={showPopup}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id='client-access-form' labelLocation='left' formData={multiUserData} alignItemLabels={true}>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='multiple_users'
                colSpan={4}
                editorType='dxCheckBox'
                editorOptions={{ stylingMode: 'outlined' }}
              >
                <Label text='Multiple Users' location='right' alignment='left' />
              </SimpleItem>

              <SimpleItem
                dataField='count_users'
                editorType='dxNumberBox'
                colSpan={8}
                editorOptions={{ showSpinButtons: true, min: 0, max: 10 }}
              >
                <Label text='Permitted User Count' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={saveClientUserCount}
              />
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientAccountUsersPop;

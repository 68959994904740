import React, { useState, useEffect } from 'react';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../contexts/auth';
import './detailgrids.css';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import ProductServiceDetailGrid from './ProductServiceDetailGrid';
import HTTP from '../api/HTTP';

const ProductCostRiskServiceDetailGrid = (props) => {
  const { user } = useAuth();
  const risklevel = props.data.data;
  const [costRateDetailList, setCostRateDetailList] = useState([]);

  useEffect(() => {
    const fetchCostRateEntries = async () => {
      try {
        const dataArray = await HTTP.get(
          `/product/viewproductcostriskserviceentries/${risklevel.productid}/${risklevel.partnerid}/${risklevel.riskid}`
        );
        setCostRateDetailList(dataArray.data);
      } catch (err) {}
    };
    fetchCostRateEntries();
  }, [risklevel.productid, risklevel.partnerid, risklevel.riskid]);

  const stCostDetailsData = new ArrayStore({
    key: ['serviceid'],
    data: costRateDetailList,
  });

  const dcCostDetailsData = new DataSource({
    store: stCostDetailsData,
  });

  return (
    <div>
      <DataGrid
        id={'product-risk-detailgrid'}
        height={350}
        width={900}
        dataSource={dcCostDetailsData}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        autoNavigateToFocusedRow={true}
        // onFocusedRowChanged={e => {
        //   setRiskGridData(e.row.data);
        //   setSelRiskid(e.row.data.riskid);
        // }}
      >
        <Column dataField='serviceid' visible={false} />
        <Column dataField='costentryid' visible={false} />
        <Column dataField='riskid' visible={false} />
        <Column dataField='servicename' caption='Service' allowEditing={false} />
        <Column dataField='risklevel' caption='Risk Level' width='150px' visible={false} allowEditing={false} />
        <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
        <Column dataField='not_applicable' caption='Not Applicable' width='100px' />
        <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='80' />
        <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='100' />
        <Column dataField='recperiod' caption='Recurring' width='80'></Column>
        <MasterDetail enabled={true} component={ProductServiceDetailGrid} />
      </DataGrid>
    </div>
  );
};
export default ProductCostRiskServiceDetailGrid;

import React, { useState, useEffect } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import HTTP from '../../api/HTTP';

const ServiceProvider = (props) => {
  const { selServiceId, user } = props;
  const [companyContact, setCompanyContact] = useState({});

  useEffect(() => {
    const fetchCompanyContact = async () => {
      try {
        const dataArray = await HTTP.get(`/provider/providercontact/${selServiceId}`);
        setCompanyContact(dataArray.data[0]);
      } catch (err) {}
    };
    if (selServiceId) {
      fetchCompanyContact();
    }
  }, [selServiceId]);

  return (
    <ResponsiveBox>
      <Col />
      <Row />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div id='service-serviceprovider-data'>
          <p>{companyContact.addressfield}</p>
          <p>{companyContact.email}</p>
          <p>{companyContact.phone_one}</p>
          <p>{companyContact.homepage}</p>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceProvider;

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'devextreme-react/button';
import '../Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, Editing, Button as DGButton } from 'devextreme-react/data-grid';

import CasesNotesPop from '../../operations/CasesNotesPop';
import CasesNotesToPop from '../../operations/CasesNotesToPop';
import HTTP from '../../../api/HTTP';

const ClientNotesTab = (props) => {
  const { tabVisibleIndex, selClientId, user, refreshCRMActivity } = props;
  const history = useHistory();
  const [notesDataList, setNotesDatalist] = useState([]);
  const [notesGridRecord, setNotesGridRecord] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadNotesData, setReloadNotesData] = useState(false);

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);

  const [caseSituationAction, setCaseSituationAction] = useState(false);
  const [caseNoteSubject, setCaseNoteSubject] = useState('');
  const [caseNoteContent, setCaseNoteContent] = useState('');

  const [compDay, setCompDay] = useState(new Date().toISOString().split('T')[0]);

  const [tempCaseId, setTempCaseId] = useState(-1);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/clientcases/${selClientId}`);
        // console.log(1234,dataArray);
        await setNotesDatalist(dataArray.data);
        if (reloadNotesData) {
          await setReloadNotesData(false);
        }
      } catch (err) {}
    };
    if (selClientId || reloadNotesData) {
      fetchCases();
    }
  }, [selClientId, tabVisibleIndex, reloadNotesData, refreshCRMActivity]);

  const getTempCaseNoteId = async () => {
    try {
      const dataArray = await HTTP.get(`/cases/tempcaseid`);
      setTempCaseId(dataArray.data[0].temp_caseid);
    } catch (err) {}
  };

  const navigatetoCaseNotePage = () => {
    if (notesGridRecord.inquiryid !== -1) {
      user.inquiry = {
        selInquiryId: notesGridRecord.inquiryid,
        selClientid: notesGridRecord.clientid,
      };
      history.push({
        pathname: '/inquiry',
      });
    } else {
      user.casenote = {
        selEntryid: notesGridRecord.entryid,
      };
      history.push({
        pathname: '/casenote',
      });
    }

    // setOpenedType(2);
    //  setShowPopup(true);
  };

  const navigatetoAddCaseNote = async () => {
    await setContactClientid(notesGridRecord.clientid);
    await setOpenedType(1);
    await setShowPopup(true);
  };

  const hidePopup = async () => {
    await setShowPopup(false);
    await setReloadNotesData(true);
  };

  const openAddNotesToPopup = async () => {
    setContactClientid(notesGridRecord.clientid);
    setCaseSituationAction(true);
    setNoteCaseSituation(notesGridRecord.case_situation);
    setShowCasesNotesTo(true);
  };

  const contactSelectedClient = async () => {
    getTempCaseNoteId();
    setContactClientid(notesGridRecord.clientid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(notesGridRecord.merchant_portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = async () => {
    setContactClient(false);
    setContactClientid(notesGridRecord.clientid);
    setContactPortalid(notesGridRecord.merchant_portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = async () => {
    getTempCaseNoteId();
    setContactClient(false);
    setContactClientid(notesGridRecord.clientid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const forwardToSelectedClient = async () => {
    getTempCaseNoteId();
    setContactClientid(notesGridRecord.clientid);
    setCaseSituationAction(false);
    setNoteCaseSituation(30);
    setCaseNoteSubject(notesGridRecord.subject);
    setCaseNoteContent(notesGridRecord.case_content);
    setContactPortalid(notesGridRecord.merchant_portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const forwardToProvider = async () => {
    getTempCaseNoteId();
    setContactClient(false);
    setContactClientid(notesGridRecord.clientid);
    setCaseNoteSubject(notesGridRecord.subject);
    setCaseNoteContent(notesGridRecord.case_content);
    setContactPortalid(-10);
    setNoteCaseSituation(40);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = useCallback(async (anInquiryid) => {
    await setShowCasesNotesTo(false);

    if (contactClient && anInquiryid !== -1) {
      if (anInquiryid > -1) {
        user.inquiry = {
          selInquiryId: anInquiryid,
          selClientid: notesGridRecord.clientid,
        };
        history.push({
          pathname: '/inquiry',
        });
      }
    } else {
      await setContactPortalid(-1);
      await setContactClient(false);
      await setContactClientid(-1);
      await setContactPortalid(-1);
      await setNoteCaseSituation(-1);
      await setCaseSituationAction(false);

      await setReloadNotesData(true);
    }
  });

  const inquiryBtnClick = (e) => {
    user.inquiry = {
      selInquiryId: e.row.data.inquiryid,
      selClientid: e.row.data.clientid,
    };
    history.push({
      pathname: '/inquiry',
    });
  };

  const InquiryBtnVisible = (e) => {
    if (e.row.data.inquiryid !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const DocBtnVisible = (e) => {
    if (e.row.data.inquiryid === -1) {
      return true;
    } else {
      return false;
    }
  };

  const caseNoteOpen = (e) => {
    user.casenote = {
      selEntryid: e.row.data.entryid,
    };
    history.push({
      pathname: '/casenote',
    });
  };

  const gridCellData = (gridData) => {
    return gridData.data['lastchat_from'];
  };

  const renderChatDateCell = (cellData) => {
    if (cellData.value !== null) {
      const gDay = cellData.value.toISOString().split('T')[0];
      if (compDay === gDay) {
        return <div style={{ color: 'blue' }}>{gridCellData(cellData) + String.fromCharCode(10) + cellData.text}</div>;
      } else {
        return (
          <div style={{ color: 'default' }}>{gridCellData(cellData) + String.fromCharCode(10) + cellData.text}</div>
        );
      }
    }
  };

  const renderNoteDateCell = (cellData) => {
    const gDay = cellData.value.toISOString().split('T')[0];
    // console.log(111, compDay, gDay);
    if (compDay === gDay) {
      return <div style={{ color: 'blue' }}>{cellData.text}</div>;
    } else {
      return <div style={{ color: 'default' }}>{cellData.text}</div>;
    }
  };

  return (
    <React.Fragment>
      <CasesNotesPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        user={user}
        entryid={notesGridRecord.entryid}
        openedToEdit={openedType}
        caseNumber={notesGridRecord && notesGridRecord.case_entryid}
        contactClientid={contactClientid}
      />
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={notesGridRecord.entryid}
        caseEntryid={notesGridRecord.case_entryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        caseSituationAction={caseSituationAction}
        caseNoteSubject={caseNoteSubject}
        caseNoteContent={caseNoteContent}
        tempCaseId={tempCaseId}
      />
      <div id='Client-notes-box'></div>
      <ResponsiveBox>
        <Col ratio={0.6} screen='md lg sm' />
        <Col ratio={2.3} />
        <Row ratio={1} />
        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Client</p>
            <Button
              width={120}
              height={36}
              text='Contact'
              type='default'
              stylingMode='outlined'
              onClick={contactSelectedClient}
            />
          </div>
          <div id='hm-btn-section-11'>
            <p>Note</p>
            <Button
              width={120}
              height={36}
              text='Add'
              type='default'
              stylingMode='outlined'
              onClick={navigatetoAddCaseNote}
            />
          </div>
          {/* 
          <div id='hm-btn-section-11'>
            <Button width={120} height={36} text='Open' type='success' onClick={navigatetoCaseNotePage} />
          </div>
          */}
        </Item>

        <Item>
          <Location screen='md lg sm, xs' row={0} col={1} />
          <div>
            <DataGrid
              id='client-comms-notes-grid'
              height={700}
              dataSource={notesDataList}
              keyExpr={'entryid'}
              defaultFocusedRowIndex={0}
              columnAutoWidth={false}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnHeaders={true}
              showColumnLines={true}
              onFocusedRowChanged={(e) => {
                setNotesGridRecord(e.row.data);
                // console.log(7450, e.row.data);
              }}
              onRowDblClick={() => {
                navigatetoCaseNotePage();
              }}
            >
              <Editing mode='row' useIcons={true} />
              <FilterRow visible={false} />
              <Column dataField='entryid' visible={false} />
              <Column dataField='case_entryid' visible={false} />
              <Column
                dataField='datenote'
                caption='Note Date'
                dataType='datetime'
                width={80}
                visible={true}
                cellRender={renderNoteDateCell}
              />
              <Column dataField='case_content' visible={false} />
              <Column dataField='subject' caption='subject' visible={false} />
              <Column
                dataField='content'
                caption='Notes'
                encodeHtml={false}
                dataType='string'
                visible={true}
                width={240}
              />
              <Column type='buttons' caption='Details' width={80}>
                <DGButton
                  hint='open inquiry'
                  icon='copy'
                  visible={InquiryBtnVisible}
                  disabled={false}
                  onClick={inquiryBtnClick}
                />
                <DGButton
                  hint='open Docs/Chat'
                  icon='activefolder'
                  visible={DocBtnVisible}
                  disabled={false}
                  onClick={caseNoteOpen}
                />
              </Column>
              <Column dataField='from_sender' caption='From' width={80} visible={true} />
              <Column
                dataField='to_receivers'
                caption='To'
                width={80}
                encodeHtml={false}
                dataType='string'
                visible={true}
              />
              <Column
                dataField='datechat'
                caption='Last Chat Msg'
                dataType='datetime'
                width={80}
                visible={true}
                cellRender={renderChatDateCell}
              />{' '}
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientNotesTab;

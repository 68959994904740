import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';
import './PartnerServicesEdit.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { Form, SimpleItem, Label } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';

import PartnerServiceDetailGrid from '../../detailgrids/PartnerServiceDetailGrid';

import HTTP from '../../api/HTTP';

const ServicesEditPartnerSellRates = (props) => {
  const { selServiceId, selPartnerId } = props;
  const { user } = useAuth();
  const history = useHistory();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(`/partner/singlepartnerservicecostrateheader/${selServiceId}/${selPartnerId}`);
        setCostRateHeaderList(dataArray.data[0]);

        let anCostEntryId = -1;

        if (dataArray.data[0].costentryid) {
          anCostEntryId = dataArray.data[0].costentryid;
        }

        const costRiskDataArray = await HTTP.get(
          `/partner/viewpartnercostriskentries/${selPartnerId}/${anCostEntryId}`
        );

        setCostRiskEntriesList(costRiskDataArray.data);
      } catch (err) {}
    };
    if (selServiceId && selServiceId > -1 && selPartnerId) {
      fetchCostRatesHeader();
    }
  }, [selServiceId, selPartnerId]);

  const costRateHeader = {
    partnerid: (costRateHeaderList && costRateHeaderList.partnerid) || -1,
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active_date: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.goBack();
  }

  return (
    <div id='partner-services-box-prices'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Cost Rates</div>
      </div>
      <ResponsiveBox>
        <Col ratio={3} />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id='service-cost-entries-form-edit-base'
            formData={costRateHeader}
            labelLocation={'left'}
            colCountByScreen={colCountByScreen}
            scrollingEnabled={true}
          >
            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='currency' colSpan={2}>
                <Label text='Currency' editorOptions={{ readOnly: true }} />
              </SimpleItem>
              <SimpleItem
                dataField='valid_on'
                dataType='date'
                editorType='dxDateBox'
                colSpan={3}
                editorOptions={{ readOnly: true }}
              >
                <Label text='Valid on' />
              </SimpleItem>
              <SimpleItem
                dataField='valid_until'
                dataType='date'
                editorType='dxDateBox'
                colSpan={3}
                editorOptions={{ readOnly: true }}
              >
                <Label text='Valid until' />
              </SimpleItem>
            </Item>
            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='active'
                dataType='boolean'
                editorType='dxCheckBox'
                colSpan={2}
                editorOptions={{ readOnly: true }}
              >
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem
                dataField='active_date'
                dataType='date'
                editorType='dxDateBox'
                colSpan={3}
                editorOptions={{ readOnly: true }}
              >
                <Label text='Active on' />
              </SimpleItem>
            </Item>

            <Item>
              <DataGrid
                id={'service-edit-cost-rates-risk-grid'}
                height={400}
                dataSource={dcCostRiskEntriesData}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                focusedRowEnabled={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                autoNavigateToFocusedRow={true}
              >
                <Column dataField='partnerid' visible={false} />
                <Column dataField='serviceid' visible={false} />
                <Column dataField='costentryid' visible={false} />
                <Column dataField='riskid' visible={false} />
                <Column dataField='risklevel' caption='Risk Level' width='250px' allowEditing={false} />
                <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='100' />
                <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='100' />
                <Column dataField='recperiod' caption='Recurring' width='300'></Column>
                <MasterDetail enabled={true} component={PartnerServiceDetailGrid} />
              </DataGrid>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesEditPartnerSellRates;

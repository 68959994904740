import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';
import './Product.css';
import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ProductSetup = (props) => {
  const { selPartnerId, selProductId, selRoutingId, user, modeInsert } = props;
  const history = useHistory();
  const dataFormRef = useRef(null);
  const [lpRecPeriod, setLpRecPeriod] = useState([]);
  const [lpIdentityType, setLpIdentityType] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [productData, setProductData] = useState({});
  const [productServicesData, setProductServicesData] = useState([]);
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [productServiceDataMod, setProductServiceDataMod] = useState();
  const [lpProductType, setLpProductType] = useState([]);
  const [RoutingServicesDataLp, setRoutingServicesDataLp] = useState([]);

  useEffect(() => {
    const fetchRecPeriod = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/2');
        setLpRecPeriod(dataArray.data);
      } catch (err) {}
    };

    const fetchIdentityType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/3');
        setLpIdentityType(dataArray.data);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/4');
        setLpRiskLevel(dataArray.data);
      } catch (err) {}
    };

    const fetchProductType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/30');
        setLpProductType(dataArray.data);
      } catch (err) {}
    };

    const fetchRoutingServicesLp = async () => {
      try {
        const dataArray = await HTTP.get(`/routing/routingsystemserviceslp/${selRoutingId}`);

        setRoutingServicesDataLp(dataArray.data);
      } catch (err) {}
    };

    fetchRecPeriod();
    fetchIdentityType();
    fetchRiskLevel();
    fetchProductType();
    fetchRoutingServicesLp();
  }, []);

  useEffect(() => {
    const fetchSingleProduct = async () => {
      try {
        const dataArray = await HTTP.get(`/product/singleproduct/${selProductId}`);
        setProductData(dataArray.data[0]);
      } catch (err) {}
    };
    if (selProductId) {
      fetchSingleProduct();
    }
  }, [selProductId]);

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await HTTP.get(`/product/systemservices/${selProductId}`);
        setProductServicesData(dataArray.data);
      } catch (err) {}
    };
    if (selProductId) {
      fetchProductServices();
    }
  }, [selProductId, productServiceDataMod]);

  useEffect(() => {
    const fetchRoutingServices = async () => {
      try {
        const dataArray = await HTTP.get(`/routing/routingsystemservices/${selRoutingId}`);
        setRoutingServicesData(dataArray.data);
      } catch (err) {}
    };

    if (selRoutingId) {
      fetchRoutingServices();
    }
  }, [selRoutingId]);

  const stProductServicesData = new ArrayStore({
    key: 'serviceid',
    data: productServicesData,
  });

  const stRoutingServicesData = new ArrayStore({
    key: 'serviceid',
    data: routingServicesData,
  });

  const stRoutingServicesDataLp = new ArrayStore({
    key: 'serviceid',
    data: RoutingServicesDataLp,
  });

  const stlpIdentityType = new ArrayStore({
    key: 'lp_id',
    data: lpIdentityType,
  });

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const stProductType = new ArrayStore({
    key: 'lp_id',
    data: lpProductType,
  });

  const product_FormData = {
    productid: (productData && productData.productid) || -1,
    partnerid: (productData && productData.partnerid) || user.managedpartner,
    productname: (productData && productData.productname) || '',
    startdate: productData && productData.startdate,
    enddate: productData && productData.enddate,
    setupfee: productData && productData.setupfee,
    recurring_fee: productData && productData.recurring_fee,
    recurring_period: (productData && productData.recurring_period) || -1,
    blacklist_id: (productData && productData.blacklist_id) || -1,
    whitelist_id: (productData && productData.whitelist_id) || -1,
    identitytype: (productData && productData.identitytype) || -1,
    risklevel: (productData && productData.risklevel) || -1,
    autoorder: (productData && productData.autoorder) || false,
    userid: (productData && productData.userid) || user.userId,
    currency: (productData && productData.currency) || '',
    productactive: (productData && productData.productactive) || false,
    producttypeid: (productData && productData.producttypeid) || 1,
  };

  const service_FormData = {
    serviceid: (selectedService && selectedService.serviceid) || -1,
    main_bank_account: (selectedService && selectedService.main_bank_account) || false,
  };

  const ModifyProduct = async () => {
    let OpType = 1;
    if (modeInsert) {
      OpType = 1;
    } else {
      OpType = 2;
    }
    try {
      await HTTP.post(
        '/product/modproduct',
        JSON.stringify({
          operation: OpType,
          productid: product_FormData.productid,
          partnerid: product_FormData.partnerid,
          productname: product_FormData.productname,
          startdate: product_FormData.startdate,
          enddate: product_FormData.enddate,
          setupfee: product_FormData.setupfee,
          recurring_fee: product_FormData.recurring_fee,
          recurring_period: product_FormData.recurring_period,
          blacklist_id: product_FormData.blacklist_id,
          whitelist_id: product_FormData.whitelist_id,
          identitytype: product_FormData.identitytype,
          risklevel: product_FormData.risklevel,
          autoorder: product_FormData.autoorder,
          userid: product_FormData.userid,
          currency: product_FormData.currency,
          productactive: product_FormData.productactive,
          producttypeid: product_FormData.producttypeid,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyProduct();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const modProductService = async (modify) => {
    try {
      await HTTP.post(
        '/product/modproductservices',
        JSON.stringify({
          productid: product_FormData.productid,
          system_service_id: service_FormData.serviceid,
          main_bank_account: service_FormData.main_bank_account,
          operation: modify,
        })
      );
      setProductServiceDataMod(!productServiceDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id='partner-customer-product-edit'>
      <ResponsiveBox>
        <Row ratio={2} />
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='client-data-name'>
            <div className='rb-header item'>
              <div className='dx-fieldset-header' id='Product-Edit-Caption'>
                Product
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <Form
            id={'product-setup-form'}
            ref={dataFormRef}
            formData={product_FormData}
            labelLocation={'left'}
            colCountByScreen={colCountByScreen}
            validationGroup='product_create_data'
          >
            <Item itemType='group'>
              <SimpleItem dataField='productname' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Product Name' />
                <Label text='Name' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={12}>
              <SimpleItem
                dataField='producttypeid'
                editorType='dxSelectBox'
                colSpan={8}
                name='service_listbox'
                editorOptions={{
                  dataSource: stProductType,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: true,
                }}
              >
                <Label text='Product Type' />
              </SimpleItem>
              <SimpleItem dataField='currency' colSpan={4} editorOptions={baseEditorOptions}>
                <Label text='Currency' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={12}>
              <SimpleItem dataField='productactive' colSpan={4} editorType='dxCheckBox'>
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem dataField='startdate' editorType='dxDateBox' colSpan={6} editorOptions={{ type: 'date' }}>
                <Label text='Start Date' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <EmptyItem />
          </Form>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'product-form-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={36}
                text='Save'
                type='default'
                stylingMode='outlined'
                validationGroup='product_create_data'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductSetup;

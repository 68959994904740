import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './PartnerServicesEdit.css';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServicesEditTermsofUse = (props) => {
  const history = useHistory();
  const { systemServiceId, user } = props;

  const [serviceData, setServiceData] = useState({});

  useEffect(() => {
    const fetchSystemService = async () => {
      try {
        const dataArray = await HTTP.get(`/service/systemservice/${systemServiceId}`);
        setServiceData(dataArray.data[0]);
      } catch (err) {}
    };

    if (systemServiceId) {
      fetchSystemService();
    }
  }, [systemServiceId]);

  function navigateToHome() {
    history.push({
      pathname: '/partner',
    });
  }

  return (
    <React.Fragment>
      <div id='partner-customer-documents-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Terms of Use</div>
        </div>
        <DataGrid
          id='service-edit-doc-grid'
          height={400}
          // dataSource={serviceFeatureDataList}
          keyExpr={'servicefeature_id'}
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}
          columnAutoWidth={true}
          defaultFocusedRowIndex={0}
          focusedRowEnabled={true}
          //  onFocusedRowChanged={e => {
          //    setFocusedDataRow(e.row.data);
          //  }}
        >
          <Column dataField='servicefeature_id' visible={false}></Column>
          <Column dataField='category_id' visible={false}></Column>
          <Column dataField='buysellasset' visible={false}></Column>
          <Column dataField='sendreceiveasset' visible={false}></Column>
          <Column dataField='feature' caption='Terms of Use Document' width='200px'></Column>
          <Column dataField='validfrom' caption='Valid' width='100px' />
          <Column dataField='validuntil' caption='Until' width='100px' />
          <Column dataField='buysellasset_txt' caption='Content'></Column>

          <Column dataField='receive' caption='Required' dataType='boolean' width='100px'></Column>
        </DataGrid>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServicesEditTermsofUse;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ChangePasswordForm, PasswordKeyForm } from './components';

export default function () {
  return (
    <Switch>
      <Route exact path='/login'>
        <SingleCard title='Finbuilder'>
          <LoginForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode'>
        <SingleCard title='Change Password'>
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/password-key'>
        <SingleCard title='Password Key'>
          <PasswordKeyForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}

import React, { useEffect, useRef, useState } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

import { useHttpClient } from '../../hooks/http-hook';
import HTTP from '../../api/HTTP';

const UserEditPop = (props) => {
  const { showPopup, hidePopup, user, selUserId, modeInsert } = props;
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [singleUser, setSingleUser] = useState([]);
  const [lpPortals, setLpPortals] = useState([]);
  const [selProviderid, setSelProviderid] = useState(-1);

  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        const dataArray = await HTTP.get(`/user/sel-portaluser/${selUserId}`);
        setSingleUser(dataArray.data[0]);
        console.console.log(dataArray.data[0]);
      } catch (err) {}
    };
    if (modeInsert && showPopup) {
      setSingleUser({});
    } else if (selUserId && showPopup) {
      fetchSingleUser();
    }
  }, [selUserId, showPopup, modeInsert]);

  const userData = {
    operation: modeInsert ? 1 : 2,
    userid: (singleUser && singleUser.id) || -1,
    last_name: (singleUser && singleUser.last_name) || '',
    first_name: (singleUser && singleUser.first_name) || '',
    email: (singleUser && singleUser.email) || '',
    phone: (singleUser && singleUser.phone) || '',
    office: (singleUser && singleUser.office) || '',
    department: (singleUser && singleUser.department) || '',
    portalid: (singleUser && singleUser.portalid) || -1,
    resetpasswordtoken: (singleUser && singleUser.resetPasswordToken) || '',
  };

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await HTTP.get('/user/userportals');
        setLpPortals(dataArray.data);
      } catch (err) {}
    };

    if (showPopup) {
      fetchPortals();
    }
  }, [showPopup]);

  const stPortals = new ArrayStore({
    key: 'partnerid',
    data: lpPortals,
  });

  function navigateToSettings() {
    hidePopup();
    //   history.push({
    //    pathname: '/syssetup',
    //    // closeaction: userSaved,
    //  });
  }

  const saveUser = async () => {
    console.log(userData);
    try {
      await HTTP.post(
        '/user/mod-portaluser',
        JSON.stringify({
          operation: userData.operation,
          userid: userData.userid,
          last_name: userData.last_name,
          first_name: userData.first_name,
          email: userData.email,
          phone: userData.phone,
          office: userData.office,
          department: userData.department,
          portalid: userData.portalid,
          resetpasswordtoken: userData.resetpasswordtoken,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await saveUser();
      navigateToSettings();
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'User'}
      width={900}
      height={600}
      ref={popupRef}
      visible={showPopup}
      showCloseButton={true}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={9} />
        <Row ratio={1} />
        <Item>
          <Form
            id={'puser-form'}
            ref={dataFormRef}
            formData={userData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='UserEditData'
          >
            <Item itemType='group' colCount={10}>
              <SimpleItem
                dataField='portalid'
                colSpan={4}
                isRequired={true}
                editorType='dxSelectBox'
                name='portal_listbox'
                editorOptions={{
                  dataSource: stPortals,
                  valueExpr: 'portalid',
                  displayExpr: 'portaltext',
                  searchEnabled: true,
                }}
              >
                <RequiredRule message='Please select a Portal' />
                <Label text='Portal' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={5}>
              <SimpleItem dataField='last_name' colSpan={2} isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Name' />
                <Label text='Last Name' />
              </SimpleItem>
              <SimpleItem dataField='first_name' colSpan={2} isRequired={true}>
                <RequiredRule message='Please enter a First Name' />
                <Label text='First Name' />
              </SimpleItem>
            </Item>
            <EmptyItem />

            <Item itemType='group' colCount={5}>
              <SimpleItem dataField='email' colSpan={2} isRequired={true}>
                <RequiredRule message='Please enter a Login' />
                <Label text='E-Mail' />
              </SimpleItem>
              <SimpleItem dataField='phone' colSpan={2} isRequired={true}>
                <RequiredRule message='Please enter a Login' />
                <Label text='Smartphone' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={5}>
              <SimpleItem dataField='resetpasswordtoken' colSpan={2}>
                <Label text='Password Reset Key' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='UserEditData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToSettings}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'underlined' };
const passwordEditorOptions = { mode: 'password' };

export default UserEditPop;

import React, { useCallback, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './GlobalDefinitions.css';
import ArrayStore from 'devextreme/data/array_store';
import MessagePop from '../../ui_elements/MessagePop';
import PartnerEditReqDocPopup from './partnerEditReqDocPopup';
import PartnerEditReqDocEditPopup from './partnerEditReqDocEditPopup';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { CheckBox } from 'devextreme-react/check-box';
import { Toast } from 'devextreme-react/toast';
import HTTP from '../../api/HTTP';

function PartnerEditReqDoc(props) {
  const { user } = props;

  const [requiredDocData, setRequiredDocData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popInsert, setPopInsert] = useState(0);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadDocData, setReloadDocData] = useState(false);
  const [documentsArray, setDocumentsArray] = useState([]);
  const [operationId, setOperationId] = useState(10);
  const [toastConfigVisible, setToastConfigVisible] = useState(false);

  useEffect(() => {
    const fetchReqDocs = async () => {
      try {
        const dataArray = await HTTP.post(
          '/document/getAllSignupDocuments',
          JSON.stringify({
            operationId: operationId,
            selPartnerId: user.portalid,
            partnerName: user.portalname,
          })
        );
        setDocumentsArray(dataArray.data);
      } catch (err) {}
    };
    fetchReqDocs();
  }, [user.token, reloadDocData, operationId]);

  const removeTouDoc = async () => {
    try {
      await HTTP.post(
        '/document/removeDocument',
        JSON.stringify({
          id: requiredDocData.id,
        })
      );

      // Reset
      requiredDocData.usage_advice = '';
    } catch (err) {
      console.log(err);
    }
  };

  const stReqDocData = new ArrayStore({
    key: 'id',
    data: documentsArray,
  });

  const addRequiredCustomerDoc = () => {
    setReloadDocData((reloadDocData) => !reloadDocData);
    setPopInsert(2);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadDocData((reloadDocData) => !reloadDocData);
  };

  const hideMessagePop = useCallback((doRemove) => {
    if (doRemove) {
      removeTouDoc();
      setReloadDocData((reloadDocData) => !reloadDocData);
    }
    setShowMessagePop(false);
    setReloadDocData((reloadDocData) => !reloadDocData);
  });

  const removeReqDoc = () => {
    // console.log(requiredDocData)
    if (requiredDocData.usage_advice) {
      setReloadDocData((reloadDocData) => !reloadDocData);
      setShowMessagePop(true);
    } else {
      setToastConfigVisible(true);
    }
  };

  const editRequiredCustomerDoc = () => {
    // console.log(requiredDocData)
    if (requiredDocData.usage_advice) {
      setReloadDocData((reloadDocData) => !reloadDocData);
      setShowEditPopup(true);
    } else {
      setToastConfigVisible(true);
    }
  };

  const hideEditPopup = () => {
    setShowEditPopup(false);
    setReloadDocData((reloadDocData) => !reloadDocData);
  };

  function cellRenderSignupPerson(data) {
    return (
      <div>
        <CheckBox
          defaultValue={data.value}
          readOnly={true}
          // onValueChanged={(e) => handleChange(e, data, 10)}
        />
      </div>
    );
  }

  function cellRenderSignupCompany(data) {
    return (
      <div>
        <CheckBox
          defaultValue={data.value}
          readOnly={true}
          // onValueChanged={(e) => handleChange(e, data, 20)}
        />
      </div>
    );
  }

  const changeStatusProgress = () => {
    // UPDATE Progress
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Template?'
      />
      <PartnerEditReqDocPopup
        showPopup={showPopup}
        hidePopup={hidePopup}
        popInsert={popInsert}
        selPartnerId={user.portalid}
        selPersonId={user.id}
        partnername={user.portalname}
        user={user}
        operationId={operationId}
      />
      <PartnerEditReqDocEditPopup
        showEditPopup={showEditPopup}
        hideEditPopup={hideEditPopup}
        user={user}
        requiredDocData={requiredDocData}
      />
      <div id='settings-tou-box'>
        <div className='dx-fieldset-header'>Document Templates</div>

        <ResponsiveBox>
          <Col ratio={2} screen='md lg sm xs' />
          <Col ratio={10} />
          <Row />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-0-1'>
              <Button
                width={100}
                height={36}
                text='Add'
                type='default'
                stylingMode='outlined'
                onClick={addRequiredCustomerDoc}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button
                width={100}
                height={36}
                text='Edit'
                type='success'
                stylingMode='outlined'
                onClick={editRequiredCustomerDoc}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <Button width={100} height={36} text='Rem' type='danger' stylingMode='outlined' onClick={removeReqDoc} />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <DataGrid
                id='setting-req-doc-Grid'
                height={400}
                dataSource={stReqDocData}
                defaultFocusedRowIndex={0}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                onFocusedRowChanged={(e) => {
                  setRequiredDocData(e.row.data);
                }}
              >
                <Column dataField='id' visible={false} />
                <Column dataField='usage_advice' caption='Title' width={200} />

                <Column dataField='comment' caption='Description' allowSorting={false} width={200} />
                <Column dataField='file_name' caption='File Name' width={200} />
                <Column
                  alignment='center'
                  caption='Signup Prsn'
                  dataField='signup_person'
                  width={100}
                  cellRender={cellRenderSignupPerson}
                />
                <Column
                  alignment='center'
                  caption='Signup Cmpy'
                  dataField='signup_company'
                  width={100}
                  cellRender={cellRenderSignupCompany}
                />
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
      <Toast
        visible={toastConfigVisible}
        message='Choose a document first.'
        type='error'
        displayTime={4000}
        onHiding={() => {
          setToastConfigVisible(false);
        }}
      />
    </React.Fragment>
  );
}

export default PartnerEditReqDoc;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import './Product.css';

import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ProductServices = (props) => {
  const { selPartnerId, selProductId, selRoutingId, user, modeInsert } = props;
  const history = useHistory();
  const dataFormRef = useRef(null);
  const [lpRecPeriod, setLpRecPeriod] = useState([]);
  const [lpIdentityType, setLpIdentityType] = useState([]);
  const [lpRiskLevel, setLpRiskLevel] = useState([]);
  const [productData, setProductData] = useState({});
  const [productServicesData, setProductServicesData] = useState([]);
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [productServiceDataMod, setProductServiceDataMod] = useState();
  const [lpProductType, setLpProductType] = useState([]);
  const [RoutingServicesDataLp, setRoutingServicesDataLp] = useState([]);

  useEffect(() => {
    const fetchRecPeriod = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/2');
        setLpRecPeriod(dataArray.data);
      } catch (err) {}
    };

    const fetchIdentityType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/3');
        setLpIdentityType(dataArray.data);
      } catch (err) {}
    };

    const fetchRiskLevel = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/4');
        setLpRiskLevel(dataArray.data);
      } catch (err) {}
    };

    const fetchProductType = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/30');
        setLpProductType(dataArray.data);
      } catch (err) {}
    };

    const fetchRoutingServicesLp = async () => {
      try {
        const dataArray = await HTTP.get(`/routing/routingsystemserviceslp/${selRoutingId}`);

        setRoutingServicesDataLp(dataArray.data);
      } catch (err) {}
    };

    fetchRecPeriod();
    fetchIdentityType();
    fetchRiskLevel();
    fetchProductType();
    fetchRoutingServicesLp();
  }, []);

  useEffect(() => {
    const fetchSingleProduct = async () => {
      try {
        const dataArray = await HTTP.get(`/product/singleproduct/${selProductId}`);
        setProductData(dataArray.data[0]);
      } catch (err) {}
    };
    if (selProductId) {
      fetchSingleProduct();
    }
  }, [selProductId]);

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await HTTP.get(`/product/systemservices/${selProductId}`);
        setProductServicesData(dataArray.data);
      } catch (err) {}
    };
    if (selProductId) {
      fetchProductServices();
    }
  }, [selProductId, productServiceDataMod]);

  useEffect(() => {
    const fetchRoutingServices = async () => {
      try {
        const dataArray = await HTTP.get(`/routing/routingsystemservices/${selRoutingId}`);
        setRoutingServicesData(dataArray.data);
      } catch (err) {}
    };

    if (selRoutingId) {
      fetchRoutingServices();
    }
  }, [selRoutingId]);

  const stProductServicesData = new ArrayStore({
    key: 'serviceid',
    data: productServicesData,
  });

  const stRoutingServicesData = new ArrayStore({
    key: 'serviceid',
    data: routingServicesData,
  });

  const stRoutingServicesDataLp = new ArrayStore({
    key: 'serviceid',
    data: RoutingServicesDataLp,
  });

  const stlpIdentityType = new ArrayStore({
    key: 'lp_id',
    data: lpIdentityType,
  });

  const stRiskLevel = new ArrayStore({
    key: 'lp_id',
    data: lpRiskLevel,
  });

  const stProductType = new ArrayStore({
    key: 'lp_id',
    data: lpProductType,
  });

  const product_FormData = {
    productid: (productData && productData.productid) || -1,
    partnerid: (productData && productData.partnerid) || user.managedpartner,
    productname: (productData && productData.productname) || '',
    startdate: productData && productData.startdate,
    enddate: productData && productData.enddate,
    setupfee: productData && productData.setupfee,
    recurring_fee: productData && productData.recurring_fee,
    recurring_period: (productData && productData.recurring_period) || -1,
    blacklist_id: (productData && productData.blacklist_id) || -1,
    whitelist_id: (productData && productData.whitelist_id) || -1,
    identitytype: (productData && productData.identitytype) || -1,
    risklevel: (productData && productData.risklevel) || -1,
    autoorder: (productData && productData.autoorder) || false,
    userid: (productData && productData.userid) || user.userId,
    currency: (productData && productData.currency) || '',
    productactive: (productData && productData.productactive) || false,
    producttypeid: (productData && productData.producttypeid) || 1,
  };

  const service_FormData = {
    serviceid: (selectedService && selectedService.serviceid) || -1,
    main_bank_account: (selectedService && selectedService.main_bank_account) || false,
  };

  const ModifyProduct = async () => {
    let OpType = 1;
    if (modeInsert) {
      OpType = 1;
    } else {
      OpType = 2;
    }
    try {
      await HTTP.post(
        '/product/modproduct',
        JSON.stringify({
          operation: OpType,
          productid: product_FormData.productid,
          partnerid: product_FormData.partnerid,
          productname: product_FormData.productname,
          startdate: product_FormData.startdate,
          enddate: product_FormData.enddate,
          setupfee: product_FormData.setupfee,
          recurring_fee: product_FormData.recurring_fee,
          recurring_period: product_FormData.recurring_period,
          blacklist_id: product_FormData.blacklist_id,
          whitelist_id: product_FormData.whitelist_id,
          identitytype: product_FormData.identitytype,
          risklevel: product_FormData.risklevel,
          autoorder: product_FormData.autoorder,
          userid: product_FormData.userid,
          currency: product_FormData.currency,
          productactive: product_FormData.productactive,
          producttypeid: product_FormData.producttypeid,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  const validateForm = async (dataFormRef) => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await ModifyProduct();
      navigateToHome();
    } else {
      alert('Please complete all required fields');
    }
  };

  const modProductService = async (modify) => {
    try {
      await HTTP.post(
        '/product/modproductservices',
        JSON.stringify({
          productid: product_FormData.productid,
          system_service_id: service_FormData.serviceid,
          main_bank_account: service_FormData.main_bank_account,
          operation: modify,
        })
      );
      setProductServiceDataMod(!productServiceDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id='partner-product-edit'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Included Services</div>
      </div>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={4} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} colspan={3} />
              <div>
                <Form id={'form'} formData={service_FormData} labelLocation={'left'}>
                  <Item itemType='group' colCount={4}>
                    <SimpleItem
                      dataField='serviceid'
                      editorType='dxSelectBox'
                      colSpan={2}
                      name='service_listbox'
                      editorOptions={{
                        dataSource: stRoutingServicesDataLp,
                        valueExpr: 'serviceid',
                        displayExpr: 'servicename',
                        searchEnabled: true,
                      }}
                    >
                      <Label location='left' text='Service' />
                    </SimpleItem>
                    <SimpleItem dataField='main_bank_account' editorType='dxCheckBox'>
                      <Label text='Main Bank Account' />
                    </SimpleItem>
                  </Item>
                </Form>

                <Button
                  width={80}
                  height={'36px'}
                  text='Add'
                  type='default'
                  stylingMode='outlined'
                  onClick={() => {
                    modProductService(1);
                  }}
                />

                <Button
                  id='product-service-edit-button'
                  width={80}
                  height={'36px'}
                  text='Mod'
                  type='success'
                  stylingMode='outlined'
                  onClick={() => {
                    modProductService(2);
                  }}
                />

                <Button
                  id='product-service-edit-button'
                  width={80}
                  height={'36px'}
                  text='Rem'
                  type='danger'
                  stylingMode='outlined'
                  onClick={() => {
                    modProductService(3);
                  }}
                />
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <DataGrid
                id='product-services-grid'
                height={240}
                dataSource={stProductServicesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={(e) => {
                  setSelectedService(e.row.data);
                }}
              >
                <Column dataField='serviceid' visible={false} />
                <Column dataField='servicename' caption='Contained Services' />
                <Column dataField='category_name' caption='Category' />
                <Column dataField='main_bank_account' dataType='boolean' caption='Main Bank Account' width='140' />
              </DataGrid>
              <div>
                <p>Attention a Standard Product requires at least one (Main) Bank Account</p>
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductServices;

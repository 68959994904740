import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Client.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import ClientBase from './ClientBase';
import ClientComms from './communication/ClientComms';
import ClientProduct from './ClientProduct';
import ClientAccess from './ClientAccess';
import ClientUsers from './ClientUsers';
import ClientRiskMatrix from './ClientRiskMatrix';
import ClientKYC from './ClientKYC';
import ClientsTransactions from './ClientTransactions';
import PortalComms from './comprovider/PortalComms';
import ForexTrades from './ForexTrades';
import CRMActivity from './CRMActivity';
import ClientContinueSignup from './ClientContinueSignup';
import HTTP from '../../api/HTTP';

const ClientPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const [cRMActivity, setCRMActivity] = useState({
    activitystate: '- ok -',
    activityaction: '- none -',
  });
  const [refreshCRMActivity, setRefreshCRMActivity] = useState(false);
  const [clientData, setClientData] = useState({});

  const clientBaseData = {
    selClientId: user.client ? user.client.selClientid : 0,
    clientData: user.client ? user.client.selClientData : {},
    usesForex: user.client ? user.client.selClientUsesForex : false,
  };

  useEffect(() => {
    if (!user.client) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${clientBaseData.selClientId}`);
        setClientData(dataArray.data[0]);
      } catch (err) {}
    };

    const fetchCrmActivity = async () => {
      try {
        const dataArray = await HTTP.get(`/base/crm-activity/${clientBaseData.selClientId}/${user.portalid}`);
        if (dataArray.data[0]) {
          setCRMActivity(dataArray.data[0]);
        } else {
          setCRMActivity({
            activitystate: '- ok -',
            activityaction: '- none -',
          });
        }
      } catch (err) {}
    };

    if (clientBaseData.selClientId) {
      fetchCrmActivity();
      fetchClientData();
    }
  }, [clientBaseData.selClientId, refreshCRMActivity]);

  const navigateToHome = () => {
    history.goBack();
  };

  const refreshActivity = () => {
    // console.log(222, 'Refresh Activity');
    setRefreshCRMActivity(!refreshCRMActivity);
  };

  return (
    <React.Fragment>
      {cRMActivity.activitystate !== '- ok -' && (
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg xs sm' row={0} col={0} />
            <div className={'content-block'}>
              <div className={'dx-card'} id='crm-activity-line'>
                <CRMActivity cRMActivity={cRMActivity} />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      )}
      {clientData.productid === -10 && (
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg xs sm' row={0} col={0} />
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <div id='signup-continue-box'>
                  <ClientContinueSignup
                    user={user}
                    clientid={clientBaseData.selClientId}
                    refreshCRMActivity={refreshCRMActivity}
                  />
                </div>
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      )}
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} colspan={2} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg xs sm' row={0} col={0} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box'>
                    <ClientBase user={user} selSignupClientid={clientBaseData.selClientId} />
                  </div>
                </div>
              </div>
            </Item>
            <Item>
              <Location screen='md lg xs sm' row={1} col={0} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box'>
                    <ClientAccess
                      user={user}
                      selSignupClientid={clientBaseData.selClientId}
                      refreshActivity={refreshActivity}
                    />
                  </div>
                </div>
              </div>
            </Item>
            <Item>
              <Location screen='md lg xs sm' row={0} col={1} rowspan={2} />
              <div className={'content-block'}>
                <div className={'dx-card'}>
                  <div id='client-base-box-right'>
                    <ClientComms
                      user={user}
                      selClientId={clientBaseData.selClientId}
                      clientChatid={clientBaseData.clientData.chatid}
                      refreshCRMActivity={refreshCRMActivity}
                      refreshActivity={refreshActivity}
                    />
                  </div>
                </div>
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-access-box'>
                <ClientRiskMatrix user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={1} rowspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-base-box-right'>
                <PortalComms user={user} selClientId={clientBaseData.selClientId} refreshActivity={refreshActivity} />
              </div>
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg xs sm' row={3} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-kyc-box'>
                <ClientKYC user={user} selSignupClientid={clientBaseData.selClientId} />
              </div>
            </div>
          </div>
        </Item>

        {user.client_multi_account_users === true && (
          <Item>
            <Location screen='md lg xs sm' row={4} col={0} />
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <div id='client-base-box-right'>
                  <ClientUsers user={user} selSignupClientid={clientBaseData.selClientId} />
                </div>
              </div>
            </div>
          </Item>
        )}

        {clientBaseData && clientBaseData.usesForex && user.seeForex && (
          <Item>
            <Location screen='md lg xs sm' row={5} col={0} colspan={2} />
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <div id='client-ForexTrades-box'>
                  <ForexTrades user={user} selClientid={clientBaseData.selClientId} />
                </div>
              </div>
            </div>
          </Item>
        )}

        <Item>
          <Location screen='md lg xs sm' row={6} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='client-Transaction-box'>
                <ClientsTransactions
                  selectedKey={clientBaseData.selClientId}
                  user={user}
                  selClientid={clientBaseData.selClientId}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={7} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientPage;

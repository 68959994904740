import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import {
  getUser,
  signIn as sendSignInRequest,
  signIn2auth as sendSignInRequest2auth,
  activateGoogle2auth as sendActivateGoogle2auth,
  logOut,
} from '../api/auth';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.user);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password, portalType) => {
    const result = await sendSignInRequest(email, password, portalType);
    if (result.isOk && result.user !== undefined) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signIn2auth = useCallback(async (pin, portalType, hash) => {
    const result = await sendSignInRequest2auth(pin, portalType, hash);
    if (result.isOk && result.user !== undefined) {
      setUser(result.user);
    }

    return result;
  }, []);

  const activateGoogle2auth = useCallback(async (pin, portalType, hash) => {
    const result = await sendActivateGoogle2auth(pin, portalType, hash);
    if (result.isOk && result.user !== undefined) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser();
    logOut();
  }, []);

  const [auth2factor, setAuth2factor] = useState({ url: '', hash: '' });

  const setAuth2factorState = useCallback((url, hash) => {
    setAuth2factor({ url, hash });
  }, []);

  const setCurrentUser = useCallback(currentUser => {
    setUser(currentUser);
  }, []);

  const setUserServiceProviderContact = useCallback(serviceProviderContact => {
    setUser(prev => ({
      ...prev,
      serviceProviderContact,
    }));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setCurrentUser,
        setUserServiceProviderContact,
        signIn,
        signIn2auth,
        activateGoogle2auth,
        signOut,
        loading,
        auth2factor,
        setAuth2factorState,
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };

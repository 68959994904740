import HTTP from '../../../api/HTTP';

class InternalMessagingHelper {
  getThreads(clientId, queryStringData) {
    return HTTP.get(`/internal_messaging/threads?clientId=${clientId}`, {
      params: queryStringData,
    }).then((response) => response.data);
  }

  getThreadDetails(theadId) {
    return HTTP.get(`/internal_messaging/threads/${theadId}`).then((response) => response.data);
  }

  createThread(threadData) {
    return HTTP.post('/internal_messaging/threads', threadData).then((response) => {
      return response.data;
    });
  }

  addThreadMessage(threadData) {
    return HTTP.post('/internal_messaging/messages', threadData).then((response) => response.data);
  }

  getThreadMessages(threadId, mportalid, queryStringData) {
    return HTTP.get(`/internal_messaging/messages/${threadId}/${mportalid}`, {
      params: queryStringData,
    }).then((response) => response.data);
  }

  getFileContent = (documentId, mportalid) => {
    return HTTP.get(`/internal_messaging/files/${documentId}/${mportalid}`).then((response) => response.data);
  };
}

export default new InternalMessagingHelper();

import React, { useEffect, useState, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";

import "./Product.css";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import { useAuth } from "../../contexts/auth";

import ProductSetup from "./ProductSetup";
import ProductServices from "./ProductServices";
import ProductPricing from "./ProductPricing";

const ProductPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();

  const partnerBaseData = {
    partnerid: user.product && user.product.partnerid,
    productid: user.product && user.product.productid,
    routingid: user.product && user.product.routingid,
  };

  useEffect(() => {
    if (!user.product) {
      history.push({
        pathname: "/",
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen="md lg xs sm" row={0} col={0} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="product-box-left">
                <ProductSetup
                  selPartnerId={partnerBaseData.partnerid}
                  selProductId={partnerBaseData.productid}
                  selRoutingId={partnerBaseData.routingid}
                  user={user}
                  modeInsert={false}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={0} col={1} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="product-box-right">
              <ProductServices
                  selPartnerId={partnerBaseData.partnerid}
                  selProductId={partnerBaseData.productid}
                  selRoutingId={partnerBaseData.routingid}
                  user={user}
                  modeInsert={false}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={1} col={0} colspan={2} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="product-box-sell-rates">
                <ProductPricing
                  selPartnerId={partnerBaseData.partnerid}
                  selProductId={partnerBaseData.productid}
                  selRoutingId={partnerBaseData.routingid}
                  user={user}
                />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg sm xs" row={2} col={1} />
          <div id={"client-close-button"}>
            <div style={{ flexDirection: "row" }}>
              <Button
                width={120}
                height={50}
                text="Close"
                type="normal"
                stylingMode="contained"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ProductPage;

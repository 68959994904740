import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import './home.scss';
import '../../global.scss';

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import TabPanel from 'devextreme-react/tab-panel';
import { MultiView, SelectBox } from 'devextreme-react';
import Tabs from 'devextreme-react/tabs';

import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

const HomePage = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);

  const get_Date = (an_Date) => {
    const ansiDate = new Date(an_Date.getTime() - an_Date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    return ansiDate;
  };

  const past_Date = (anDate, anDays) => {
    let dt = new Date(anDate);
    dt.setDate(dt.getDate() + anDays);
    return dt;
  };

  const anPastDate = past_Date(new Date(), -30);

  const [selStartDate, setSelStartDate] = useState(get_Date(new Date(anPastDate)));
  const [selEndDate, setSelEndDate] = useState(get_Date(new Date()));

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}></div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import './Forex.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';

import ForexLeveragePop from './ForexLeveragePop';
import HTTP from '../../api/HTTP';

const ForexSetup = (props) => {
  const history = useHistory();
  const { extAccountId, user } = props;

  const dataFormRef = useRef(null);
  const [forexBaseData, setForexBaseData] = useState({});
  const [forexBaseLeverage, setForexBaseLeverage] = useState([]);
  const [showForexLeveragePopup, setShowForexLeveragePopup] = useState(false);
  const [popActivity, setPopActivity] = useState('');
  const [selLeverage, setSelLeverage] = useState(5);

  const fetchForexBase = async () => {
    try {
      const dataArray = await HTTP.get(`/forex/forexbase/${extAccountId}`);
      setForexBaseData(dataArray.data[0]);
    } catch (err) {}
  };

  const fetchForexBaseLeverage = async () => {
    try {
      const dataArray = await HTTP.get(`/forex/forexbaseleverage/${extAccountId}`);
      console.log(1, extAccountId, dataArray.data);
      setForexBaseLeverage(dataArray.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (extAccountId) {
      fetchForexBase();
      fetchForexBaseLeverage();
    }
  }, [ extAccountId]);

  const forex_base_FormData = {
    baseaccountid: (forexBaseData && forexBaseData.baseaccountid) || -1,
    order_bips: (forexBaseData && forexBaseData.order_bips) || 1,
    order_bips_unit_4: (forexBaseData && forexBaseData.order_bips_unit_4) || 0.0001,
    order_bips_unit_2: (forexBaseData && forexBaseData.order_bips_unit_2) || 0.01,
    sell_bips: (forexBaseData && forexBaseData.sell_bips) || 1,
    sell_bips_unit_4: (forexBaseData && forexBaseData.sell_bips_unit_4) || 0.0001,
    sell_bips_unit_2: (forexBaseData && forexBaseData.sell_bips_unit_2) || 0.1,
    margin_call_use: (forexBaseData && forexBaseData.margin_call_use) || true,
    margin_call_value: (forexBaseData && forexBaseData.margin_call_value) || 125,
    stop_out_value: (forexBaseData && forexBaseData.stop_out_value) || 100,
  };

  function navigateToHome() {
    history.goBack();
  }

  const modifyForexBase = async () => {
    try {
      setForexBaseData(forex_base_FormData);
      await HTTP.post(
        '/forex/modforexbase',
        JSON.stringify({
          baseaccountid: forex_base_FormData.baseaccountid,
          order_bips: forex_base_FormData.order_bips,
          order_bips_unit_4: forex_base_FormData.order_bips_unit_4,
          order_bips_unit_2: forex_base_FormData.order_bips_unit_2,
          sell_bips: forex_base_FormData.sell_bips,
          sell_bips_unit_4: forex_base_FormData.sell_bips_unit_4,
          sell_bips_unit_2: forex_base_FormData.sell_bips_unit_2,
          margin_call_use: forex_base_FormData.margin_call_use,
          margin_call_value: forex_base_FormData.margin_call_value,
          stop_out_value: forex_base_FormData.stop_out_value,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const openForexLeveragePop = (anActivity) => {
    setPopActivity(anActivity);
    setShowForexLeveragePopup(true);
  };

  const hideForexLeveragePop = () => {
    setShowForexLeveragePopup(false);
    fetchForexBaseLeverage();
  };

  return (
    <React.Fragment>
      <ForexLeveragePop
        showPopup={showForexLeveragePopup}
        hidePopup={hideForexLeveragePop}
        extAccountId={extAccountId}
        forexLeverageValue={selLeverage}
        popActivity={popActivity}
        user={user}
      />
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Forex</div>

        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.2} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <Form
                ref={dataFormRef}
                formData={forex_base_FormData}
                labelLocation={'left'}
                readOnly={true}
                // validationGroup='Service_Edit_Data'
              >
                <Item itemType='group' colCount={12}>
                  <SimpleItem dataField='order_bips' colSpan={4}>
                    <Label text='Order Bips' />
                  </SimpleItem>
                  <SimpleItem dataField='order_bips_unit_4' colSpan={4}>
                    <Label text='Bip Unit 4 dec' />
                  </SimpleItem>
                  <SimpleItem dataField='order_bips_unit_2' colSpan={4}>
                    <Label text='Bip Unit 2 dec' />
                  </SimpleItem>
                </Item>

                <EmptyItem />

                <Item itemType='group' colCount={12}>
                  <SimpleItem dataField='sell_bips' colSpan={4}>
                    <Label text='Sell Bips' />
                  </SimpleItem>
                  <SimpleItem dataField='sell_bips_unit_4' colSpan={4}>
                    <Label text='Bip Unit 4 dec' />
                  </SimpleItem>
                  <SimpleItem dataField='sell_bips_unit_2' colSpan={4}>
                    <Label text='Bip Unit 2 dec' />
                  </SimpleItem>
                </Item>

                <EmptyItem />

                <Item itemType='group' colCount={12}>
                  <SimpleItem dataField='margin_call_use' colSpan={4}>
                    <Label text='Use Margin Call' />
                  </SimpleItem>
                  <SimpleItem dataField='margin_call_value' colSpan={4}>
                    <Label text='Margin Call' />
                  </SimpleItem>
                  <SimpleItem dataField='stop_out_value' colSpan={4}>
                    <Label text='Stop Out' />
                  </SimpleItem>
                </Item>
              </Form>
              <div id='extention-forex-cap'>Leverage</div>
              <ResponsiveBox>
                <Row />
                <Col ratio={2} />
                <Col ratio={8} />
                <Item>
                  <Location screen='md lg sm xs' row={0} col={0} />
                  <div id='hm-btn-section-0-1'>
                    <Button
                      width={80}
                      height={36}
                      text='Add'
                      type='default'
                      disabled={true}
                      stylingMode='outlined'
                      onClick={(e) => {
                        openForexLeveragePop('add');
                      }}
                    />
                  </div>
                  <div id='hm-btn-section-0-11'>
                    <Button
                      width={80}
                      height={36}
                      text='Rem'
                      type='danger'
                      disabled={true}
                      stylingMode='outlined'
                      onClick={(e) => {
                        openForexLeveragePop('rem');
                      }}
                    />
                  </div>
                </Item>
                <Item>
                  <Location screen='md lg sm xs' row={0} col={1} />
                  <DataGrid
                    width={100}
                    height={150}
                    dataSource={forexBaseLeverage}
                    keyExpr='leverage'
                    showBorders={true}
                    showColumnHeaders={false}
                    onSelectionChanged={(e) => {
                      e.selectedRowsData[0] ? setSelLeverage(e.selectedRowsData[0].leverage) : setSelLeverage(5);
                    }}
                  >
                    <Selection mode='single' />
                    <Column dataField='leverage' caption='leverage' width={59} />
                  </DataGrid>
                </Item>
              </ResponsiveBox>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'services-edit-button'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  width={100}
                  height={36}
                  text='Save'
                  type='default'
                  disabled={true}
                  stylingMode='outlined'
                  // validationGroup='Service_Edit_Data'
                  // useSubmitBehavior={true}
                  onClick={modifyForexBase}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ForexSetup;

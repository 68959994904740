import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'devextreme-react/button';

import InternalMessagingHelper from './internalMessaging/InternalMessagingHelper';
import ThreadMessagesUsers from './internalMessaging/ThreadMessagesUsers';

function MessageUsersPage(props) {
  const { chatid, user, selUser } = props;
  const [clientInfo, setClientInfo] = useState(null);

  useEffect(() => {
    InternalMessagingHelper.getThreadDetails(chatid).then((response) => {
      if (response?.persons) {
        const clientInfo = response.persons.find((p) => p.customerType === 'CLIENT');
        if (clientInfo) {
          setClientInfo(clientInfo);
        }
      }
    });
  }, [chatid]);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div>
          <ThreadMessagesUsers threadId={chatid} user={user} selUser={selUser} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageUsersPage;

import React, { useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import MultiView from 'devextreme-react/multi-view';
import Tabs from 'devextreme-react/tabs';

import CasesNotesTab from './ClientNotesTab';
import ClientsInquiry from './ClientInquiries';
import MessageClientsPage from '../../messages/MessageClientsPage';
import ClientDocuments from './ClientDocuments';
import ClientCalls from './ClientCalls';

const ClientsComms = (props) => {
  const { user, selClientId, clientChatid, refreshCRMActivity, refreshActivity } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div id='client-comms-box'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Client</div>

        <Tabs
          selectedIndex={tabIndex}
          width='100%'
          visible={true}
          onItemClick={(e) => {
            setTabIndex(e.itemIndex);
          }}
        >
          <Item text='Notes' />
          <Item text='Chat' />
          <Item text='Call' />
          <Item text='Doc. Requests' />
          <Item text='Documents' />
        </Tabs>
        <MultiView height={760} animationEnabled={false} selectedIndex={tabIndex}>
          <Item title='Notes'>
            <CasesNotesTab
              tabVisibleIndex={tabIndex}
              selClientId={selClientId}
              user={user}
              refreshCRMActivity={refreshCRMActivity}
              // getReload={getReloadCondition}
            />
          </Item>
          <Item title='Chat'>
            <MessageClientsPage
              messageid={parseInt(clientChatid)}
              openedBy={parseInt(user.portalid)}
              portalType={1}
              selClientId={selClientId}
              refreshActivity={refreshActivity}
              own_portalid={user.portalid}
              tgt_portalid={-5}
            />
          </Item>
          <Item title='Calls'>
            <ClientCalls user={user} selClientId={selClientId} selPortalId={parseInt(user.portalid)} />
          </Item>
          <Item title='Doc. Requests'>
            <ClientsInquiry
              selectedKey={selClientId}
              selClientid={selClientId}
              selAccountType={'Person'}
              selPartnerid={user.partnerid}
              user={user}
            />
          </Item>
          <Item title='Documents'>
            <ClientDocuments user={user} selClientId={selClientId} selPortalId={parseInt(user.portalid)} />
          </Item>
        </MultiView>
      </div>
    </div>
  );
};

export default ClientsComms;

class DocumentRequestFormData {
    title
    serviceId
    requestType
    description
    templates
    constructor() {
        this.title = "";
        this.serviceId = null;
        this.requestType = null;
        this.description = "";
        this.templates = [];
    }
}

export {DocumentRequestFormData}
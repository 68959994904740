import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { Form, Label, SimpleItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import HTTP from '../../api/HTTP';

const PartnerEditReqDocEditPopup = (props) => {
  const { showEditPopup, hideEditPopup, user, requiredDocData } = props;
  const popupRef = useRef(requiredDocData);

  const docTemplateData = {
    doc_title: requiredDocData?.usage_advice || '',
    docComment: requiredDocData?.comment || '',
    docId: requiredDocData?.id,
    signup_person: requiredDocData?.signup_person || false,
    signup_company: requiredDocData?.signup_company || false,
  };

  // console.log(docTemplateData);

  const updateRequiredDoc = async () => {
    // console.log(docTemplateData);

    try {
      await HTTP.post(
        '/document/updateDocument',
        JSON.stringify({
          docTemplateData,
        })
      );
    } catch (err) {
      console.log(err);
    }
    notify('Document was successfully updated', 'success', 2000);
    hideEditPopup(true);
  };

  return (
    <Popup
      title='Document Template Definitions'
      ref={popupRef}
      visible={showEditPopup}
      height={'auto'}
      width={'650px'}
      closeOnOutsideClick={true}
      showCloseButton={true}
      resizeEnabled={true}
      onHiding={() => {
        hideEditPopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={5} />
        <Row ratio={2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id='doc-add-dialog' ref={popupRef} formData={docTemplateData} labelLocation={'top'}>
            <SimpleItem dataField='doc_title' colSpan={2}>
              <Label text='Title' />
            </SimpleItem>
            <SimpleItem dataField='docComment' editorType='dxTextArea' editorOptions={{ height: 100 }}>
              <Label text='Description' />
            </SimpleItem>

            <p>Use for Signup of</p>

            <Item itemType='group' colCount={10} caption='Use on Signup for'>
              <SimpleItem
                editorType='dxCheckBox'
                colSpan={2}
                dataField='signup_person'
                editorOptions={{
                  text: 'Persons',
                }}
              >
                <Label visible={false} />
              </SimpleItem>
              <SimpleItem
                editorType='dxCheckBox'
                colSpan={2}
                dataField='signup_company'
                editorOptions={{
                  text: 'Companies',
                }}
              >
                <Label visible={false} />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                text='Save'
                type='default'
                height={40}
                width={120}
                onClick={updateRequiredDoc}
              />
              <Button
                id='access-pop-savebutton-right'
                text='Close'
                type='normal'
                height={40}
                width={120}
                onClick={() => hideEditPopup(true)}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default PartnerEditReqDocEditPopup;

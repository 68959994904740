import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import './partnerservices.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 700;
  return anValue;
};

const PartnerServicesTab = (props) => {
  const { passToParent, user, selRoutingId } = props;
  const history = useHistory();
  const [servicesList, setServicesList] = useState([]);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [sysServiceID, setSysServiceID] = useState(-1);
  const [selServiceData, setSelServiceData] = useState({});

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const dataArray = await HTTP.get(`/service/partnersystemservices/${user.partnerid}`);
        setServicesList(dataArray.data);
      } catch (err) {}
    };
    if (user.partnerid) {
      fetchServices();
    }
  }, [user.partnerid]);

  const stServicesData = new ArrayStore({
    key: 'serviceid',
    data: servicesList,
  });

  const dcServicesData = new DataSource({
    store: stServicesData,
  });

  const openServicesEdit = () => {
    user.service = {
      partnerId: user.partnerid,
      partnerName: user.portalname,
      routingId: selRoutingId,
      serviceId: sysServiceID,
      serviceName: selServiceData.servicename,
      providerId: selServiceData.providerid,
      categoryId: selServiceData.categoryid,
      account_Liquidity_Id: selServiceData.account_liquidity_id,
      account_Service_Provider_Id: selServiceData.account_service_provider_id,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/partnerservice',
    });
  };

  const createPartnerSellRates = async () => {
    try {
      const dataArray = await HTTP.get(`/partner/createpartnersellrates/${user.partnerid}/${selRoutingId}`);
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.3} />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openServicesEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='service-serviceoverview-grid'
              height={getScreenGridHeight}
              dataSource={dcServicesData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSysServiceID(e.row.key);
                  passToParent(e.row.key);
                  setSelServiceData(e.row.data);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='serviceid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service' width={280} />
              <Column dataField='category' caption='Category' width={200} />
              <Column dataField='providername' caption='Provider' width={200} />
              <Column dataField='lq_account' caption='Liq. Account' width={200} visible={false} />
              <Column dataField='sv_account' caption='Srv. Account' width={200} visible={false} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerServicesTab;

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Inquiry.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import InquiryRequest from './inquiry-sections/InquiryRequest';
import InquiryDocuments from './inquiry-sections/InquiryDocuments';
import HTTP from '../../api/HTTP';

const InquiryPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();
  const [inquiryData, setInquiryData] = useState({});
  const [inquiryState, setInquiryState] = useState(0);
  const [reloadInquiry, setReloadInquiry] = useState(false);

  const inquiryBaseData = {
    selInquiryId: user.inquiry ? user.inquiry.selInquiryId : 0,
    selClientid: user.inquiry ? user.inquiry.selClientid : '',
  };

  useEffect(() => {
    if (!user.inquiry) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchInquiryData = async () => {
      try {
        const dataArray = await HTTP.get(`/inquiries/getInquiry/${inquiryBaseData.selInquiryId}`);
        await setInquiryData(dataArray.data[0]);
        await setInquiryState(dataArray.data[0].inquirystate);
        setReloadInquiry(false);

        await HTTP.get(`/alerting/markalertread/${inquiryBaseData.selInquiryId}/-1/${user.portalid}`);
      } catch (err) {
        console.log(err);
      }
    };
    if (inquiryBaseData.selInquiryId || reloadInquiry) {
      fetchInquiryData();
    }
  }, [inquiryBaseData.selInquiryId, reloadInquiry]);

  const reloadInquiryData = useCallback(async (doReload) => {
    setReloadInquiry(doReload);
  });

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <InquiryRequest user={user} inquiryData={inquiryData} reloadInquiryData={reloadInquiryData} />
            </div>
          </div>
        </Item>
        {/* 
         <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='inq-chat-box'>
                <p>
                  <b>Chat</b>
                </p>

                <div>
                  {inquiryData.chatthreadid && <MessageThreadPage messageid={inquiryData.chatthreadid} openedBy={1} />}
                </div>
              </div>
            </div>
          </div>
        </Item>      
       */}
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <InquiryDocuments user={user} selInquiryId={inquiryBaseData.selInquiryId} selPartnerid={user.portalid} />
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={1} />
          <div id={'inq-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default InquiryPage;

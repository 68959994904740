import { useState, useCallback, useRef, useEffect } from 'react';
import HTTP from '../api/HTTP';

export const useInquiry = () => {
  const createInquiry = useCallback(async (user, clientid, subject, comment) => {
    console.log(2009, user, clientid, subject, comment);
    try {
      const response = await HTTP.post(
        '/inquiries/createClientRequests',
        JSON.stringify({
          userId: user.id,
          clientId: clientid,
          serviceId: -1,
          portalId: user.portalid,
          title: subject,
          description: comment,
        })
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    createInquiry: createInquiry,
  };
};

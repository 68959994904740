import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../contexts/auth";
import "./ProductPage.css";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from "devextreme-react/form";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import { Tabs, MultiView, SelectBox } from "devextreme-react";
import { Label } from "devextreme-react/data-grid";

import ProductsTab from "./ProductsTab";
import ProductSetup from "./ProductSetup";
import ProductPricing from "./ProductPricing";
import ProductEditTermsofUse from "./ProductEditTermsofUse";

const PartnerEditProductsPage = (props) => {
  const { user } = useAuth();
  const [selProductid, setSelProductid] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const getProductid = useCallback((fProductid) => {
    setSelProductid(fProductid);
  });

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <div className="rb-header item">
            <div className="dx-fieldset-header" id="cap_item">
              Products of {user.portalname}
            </div>
          </div>
          <ResponsiveBox>
            <Row />
            <Col />

            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div className="rb-content item">
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen="md lg sm xs" row={0} col={0} />
                    <ProductsTab passToParent={getProductid} user={user} partnerid={user.partnerid} />

                    <div>
                      <ResponsiveBox>
                        <Col ratio={0.33} screen="md lg sm" />
                        <Col ratio={3} />
                        <Row />
                        <Item>
                          <Location screen="md lg sm xs" row={0} col={0} />
                        </Item>
                        <Item>
                          <Location screen="md lg sm xs" row={0} col={1} />
                          <div className="rb-content item">
                            <Tabs
                              id="client-product-tab"
                              selectedIndex={tabIndex}
                              width="99%"
                              visible={true}
                              onItemClick={(e) => {
                                setTabIndex(e.itemIndex);
                              }}
                            >
                              <Item text="Product" />
                              <Item text="Pricing" />
                            </Tabs>
                            <ResponsiveBox>
                              <Row />
                              <Col />
                              <Item>
                                <Location screen="md lg sm xs" row={0} col={0} />
                                <div>
                                  <MultiView height={400} animationEnabled={false} selectedIndex={tabIndex}>
                                    <Item title="Product Setup">
                                      <ProductSetup
                                        selPartnerId={user.partnerid}
                                        selProductId={selProductid}
                                        user={user}
                                      />
                                    </Item>
                                    <Item title="Product Pricing">
                                      <ProductPricing
                                        selPartnerId={user.partnerid}
                                        selProductId={selProductid}
                                        user={user}
                                      />
                                    </Item>
                                  </MultiView>
                                </div>
                              </Item>
                            </ResponsiveBox>
                          </div>{" "}
                        </Item>
                      </ResponsiveBox>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerEditProductsPage;

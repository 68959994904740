import React, { useState } from 'react';

import { withRouter, Link } from 'react-router-dom';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import './login-form.scss';

import ScrollView from 'devextreme-react/scroll-view';

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
};
const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
};

function LoginForm(props) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const portalType = 2;

  const { signIn, setAuth2factorState } = useAuth();

  const hasOwnProperty = (obj, prop) => {
    return obj.hasOwnProperty(prop);
  };

  const onSubmit = e => {
    e.preventDefault();
    const { email, password } = formData;

    setLoading(true);

    signIn(email, password, portalType)
      .then(result => {
        if (hasOwnProperty(result, 'auth2')) {
          setAuth2factorState('', result.auth2.hash);
          props.history.push('/qr-login');
        }

        if (result.isOk) {
          props.history.push({ pathname: '/', state: {} });
        } else {
          setFormData(prev => ({ ...prev, password: '' }));
          setLoading(false);
          notify('Authentication failed. Email or password is incorrect.', 'error', 2000);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log('Err', e);
      });
  };

  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
      <div className={'dx-card content wide-form'}>
        <div className={'header'}>
          <div className={'title'}>Merchant Portal</div>
          <p />
          <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <Item dataField={'email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
                <RequiredRule message='Email is required' />

                <Label visible={false} />
              </Item>
              <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                <RequiredRule message='Password is required' />
                <Label visible={false} />
              </Item>
              <ButtonItem>
                <ButtonOptions width={'100%'} height={'36px'} type={'default'} useSubmitBehavior={true}>
                  <span className='dx-button-text'>
                    {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Sign In'}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <Item>
                <div className={'link'}>
                  <Link to={'/password-key'}>Set password?</Link>
                </div>
              </Item>
            </Form>
          </form>
        </div>
      </div>
    </ScrollView>
  );
}

export default withRouter(LoginForm);

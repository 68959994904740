import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './services.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

import ProductsEditPop from './ProductsEditPop';
import InfoPop from '../../ui_elements/InfoPop';
import MessagePop from '../../ui_elements/MessagePop';
import HTTP from '../../api/HTTP';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 700;
  return anValue;
};

const ProductsTab = (props) => {
  const { passToParent, user, selCompanyID, partnerid } = props;
  const history = useHistory();
  const [productsList, setProductsList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedProductid, setSelectedProductid] = useState();
  const [selectedRoutingid, setSelectedRoutingid] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dataArray = await HTTP.get(`/product/products/${partnerid}`);
        setProductsList(dataArray.data);
      } catch (err) {}
    };
    fetchProducts();
  }, [reloadData]);

  const stProductData = new ArrayStore({
    key: 'productid',
    data: productsList,
  });

  const dcProductData = new DataSource({
    store: stProductData,
  });

  const openProductAdd = () => {
    setSelectedProductid(-1);
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowContactPopup(true);
  };

  const openProductEdit = () => {
    user.product = {
      partnerid: partnerid || -1,
      productid: selectedProductid || -1,
      routingid: selectedRoutingid || -1,
      modeInsert: false,
    };
    history.push({
      pathname: '/productedit',
    });
  };

  const delProduct = async () => {
    try {
      await HTTP.post(
        '/product/delproduct',
        JSON.stringify({
          productid: selectedProductid,
        })
      ).then(() => setReloadData(!reloadData));
    } catch (err) {
      console.log(err);
    }
  };

  const hideContactPopup = useCallback((doContactReload) => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadData(!reloadData);
    }
  });

  const checkIsProductActiv = () => {
    if (!selectedStatus) {
      setShowMessagePop(true);
    } else {
      setpopModeInsert(true);
      setPopupTabsVisible(true);
      setShowRemovePopup(true);
    }
  };

  const hideRemovePopup = () => {
    setShowRemovePopup(false);
  };

  const hideMessagePop = (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      delProduct();
    }
  };

  return (
    <React.Fragment>
      <ProductsEditPop showPopup={showContactPopup} hidePopup={hideContactPopup} selPartnerId={partnerid} user={user} />
      <InfoPop
        showPopup={showRemovePopup}
        hidePopup={hideRemovePopup}
        popTitle='Information'
        popMessage='It is not possible to delete Active Products'
      />
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Product?'
      />

      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Add' type='default' stylingMode='outlined' onClick={openProductAdd} />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Open'
              type='success'
              stylingMode='outlined'
              onClick={openProductEdit}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Rem'
              type='danger'
              stylingMode='outlined'
              onClick={checkIsProductActiv}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='partner-products-edit-Grid'
              height={getScreenGridHeight}
              dataSource={dcProductData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  passToParent(e.row.key);
                  setSelectedProductid(e.row.key);
                  setSelectedRoutingid(e.row.data.routingid);
                  setSelectedStatus(e.row.data.productactive);
                  setSelectedProduct(e.row.data.productname);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='productid' visible={false}></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='routingid' visible={false}></Column>
              <Column dataField='productname' caption='Product' width={300}></Column>
              <Column dataField='producttype' caption='Product Type' width={150}></Column>
              <Column dataField='productactive' caption='Active' width={90}></Column>
              <Column dataField='startdate' dataType='date' caption='Start Date' width={130} />
              <Column dataField='enddate' dataType='date' caption='End Date' width={130} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ProductsTab;

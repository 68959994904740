import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 600;
  return anValue;
};

const TransactionsOneTab = (props) => {
  const { passToParent, user, selProviderId } = props;
  const history = useHistory();
  const [provTransactionsData, setProvTransactionsData] = useState([]);
  const [selectedTransactionid, setSelectedTransactionid] = useState(-1);
  const [selServiceid, setselServiceid] = useState(-1);

  useEffect(() => {
    const fetchTransactions = async () => {
      console.log(user);

      try {
        const dataArray = await HTTP.get(`/rep/partnertransactions/${user.partnerid}`);
        setProvTransactionsData(dataArray.data);
      } catch (err) {}
    };

    fetchTransactions();
  }, []);

  const stprovTransactionsData = new ArrayStore({
    key: 'transactionid',
    data: provTransactionsData,
  });

  const dcProvTransactionsData = new DataSource({
    store: stprovTransactionsData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col ratio={0.35} screen='md lg sm xs' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button
              width={140}
              height={50}
              text='Find'
              type='normal'
              stylingMode='outlined'
              //  onClick={showApprovePop}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={140}
              height={50}
              text='Contact'
              type='normal'
              stylingMode='outlined'
              //  onClick={showApprovalSettingsPop}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <DataGrid
              id='rep-transaction-Grid'
              height={getScreenGridHeight}
              dataSource={dcProvTransactionsData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selectedTransactionid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelectedTransactionid(e.row.key);
                  setselServiceid(e.row.data.serviceid);
                  passToParent(e.row.key);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='transactionid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='clientid' visible={false}></Column>
              <Column dataField='transferdate' caption='Transfer Date' dataType='datetime' width={140}></Column>
              <Column dataField='servicename' caption='Service' visible={true} width={250} />
              <Column dataField='operation' caption='Operation' visible={true} width={90} />{' '}
              <Column dataField='assetname' caption='Asset' visible={true} width={80} />
              <Column dataField='assetamount' caption='Amount' visible={true} width={140} />
              <Column dataField='assetcalcunit' caption='Unit' visible={true} width={70} />
              <Column dataField='currency' caption='Currency' visible={true} width={60} />
              <Column dataField='currencyamount' caption='Amount' visible={true} width={90} />
              <Column dataField='partner_fee' caption='Ptn. Fee' visible={true} width={70} />
              <Column dataField='client_fee' caption='Cl. Fee' visible={true} width={70} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default TransactionsOneTab;

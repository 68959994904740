import { useState, useCallback, useRef, useEffect } from 'react';
import HTTP from '../api/HTTP';

export const useClientData = () => {
  const callClientData = useCallback(async (user, clientid) => {
    try {
      const dataArray = await HTTP.get(`/client/clientsignupdata/${clientid}`);
      return dataArray.data[0];
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clientUsesForex = useCallback(async (user, clientid) => {
    try {
      const dataArray = await HTTP.get(`/forex/clientuesesforex/${clientid}`);
      if (dataArray.data[0] && dataArray.data[0].category_ident === 'frx') {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    callClientData: callClientData,
    clientUsesForex: clientUsesForex,
  };
};

import React, { useState, useEffect } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ClientAccountUsersPop from '../clientsapproved/ClientAccountUsersPop';
import ClientUserApprovalPop from '../clientsapproved/ClientUserApprovalPop';
import HTTP from '../../api/HTTP';

const ClientUsers = (props) => {
  const { selSignupClientid, user } = props;

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [clientConnectedData, setClientConnectedData] = useState([]);
  const [showClientAccountUsersPop, setShowClientAccountUsersPop] = useState(false);
  const [multiUserConfigData, setMultiUserConfigData] = useState({});
  const [reloadMultipleUsersConfig, setReloadMultipleUsersConfig] = useState(false);
  const [showClientUserApprovalPop, setShowClientUserApprovalPop] = useState(false);
  const [countRequireApproval, setCountRequireApproval] = useState(0);
  const [selAccountUserId, setSelAccountUserId] = useState(-1);
  const [selAccountUser, setSelAccountUser] = useState({});

  useEffect(() => {
    const fetchMultiUserConfig = async () => {
      const dataArray = await HTTP.get(`/client/clientmultiuserconfig/${selSignupClientid}`);
      setMultiUserConfigData(dataArray.data[0]);
    };

    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selSignupClientid}`);
        setSignupClientDatalist(dataArray.data[0]);

        const dataArrayThree = await HTTP.get(`/client/connectedusers/${selSignupClientid}`);
        setClientConnectedData(dataArrayThree.data);

        const conCount = dataArrayThree.data.filter((obj) => {
          return obj.approved === false;
        });
        setCountRequireApproval(conCount.length);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchMultiUserConfig();
      fetchSignupClientData();
    }
  }, [selSignupClientid]);

  useEffect(() => {
    const fetchMultiUserConfig = async () => {
      const dataArray = await HTTP.get(`/client/clientmultiuserconfig/${selSignupClientid}`);
      setMultiUserConfigData(dataArray.data[0]);
    };
    fetchMultiUserConfig();
  }, [reloadMultipleUsersConfig]);

  const stClientConnectedData = new ArrayStore({
    key: 'inviteid',
    data: clientConnectedData,
  });

  const dcClientConnectedData = new DataSource({
    store: stClientConnectedData,
  });

  const showClientAccessDialog = () => {
    setShowClientAccountUsersPop(true);
  };

  const showClientUserApprovalDialog = async () => {
    setShowClientUserApprovalPop(true);
  };

  const hideClientHideAccountUsersPop = async () => {
    await setShowClientAccountUsersPop(false);
    await setReloadMultipleUsersConfig(!reloadMultipleUsersConfig);
  };

  const hideClientUserApprovalPop = async () => {
    await setShowClientUserApprovalPop(false);
  };

  return (
    <React.Fragment>
      <ClientAccountUsersPop
        showPopup={showClientAccountUsersPop}
        hidePopup={hideClientHideAccountUsersPop}
        user={user}
        selClientid={selSignupClientid}
      />
      <ClientUserApprovalPop
        showPopup={showClientUserApprovalPop}
        hidePopup={hideClientUserApprovalPop}
        user={user}
        // clientApprovalStateData={clientApprovalStateData}
        selClientid={selSignupClientid}
        selAccountUserId={selAccountUserId}
        selAccountUser={selAccountUser}
      />
      <div id='client-users-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Account Users</div>
        </div>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={2.3} />
          <Col ratio={9} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-0-1'>
              <Button
                width={120}
                height={36}
                text='Access'
                type='default'
                stylingMode='outlined'
                onClick={showClientAccessDialog}
              />
            </div>
            <div id='hm-btn-section-0-11'>
              <p>Request User</p>
            </div>
            <div id='hm-btn-section-0-1'>
              <Button
                width={120}
                height={36}
                text='Approval'
                type='default'
                stylingMode='outlined'
                disabled={countRequireApproval > 0 ? false : true}
                onClick={showClientUserApprovalDialog}
              />
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div id='client-access-info'>
              <table>
                <tbody>
                  <tr>
                    <td width='120px'>Multiple Users:</td>
                    <td width='150px'>{multiUserConfigData && multiUserConfigData.multiple_users ? 'Yes' : 'No'} </td>
                    <td width='100px'>Count:</td>
                    <td width='300px'>
                      {multiUserConfigData && multiUserConfigData.count_users
                        ? multiUserConfigData.count_users
                        : 'None'}{' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td width='120px'>Connected Users:</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataGrid
              id='client-connected-user-grid'
              height={'300px'}
              dataSource={dcClientConnectedData}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              showColumnHeaders={true}
              // focusedRowKey={selectedClientid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelAccountUserId(e.row.key.u_clientid);
                  setSelAccountUser(e.row.data);
                  //    passToParent(e.row.key.clientid);
                }
              }}
            >
              <Column dataField='inviteid' visible={false}></Column>
              <Column dataField='u_clientid' visible={false}></Column>
              <Column dataField='assetaccountid' visible={false}></Column>
              <Column dataField='cl_firstname' caption='First Name' width={100} />
              <Column dataField='cl_lastname' caption='Name' width={120} />
              <Column dataField='accountname' caption='Account' width={260} />
              <Column dataField='approved' caption='Access Approved' width={120} />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientUsers;

import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Button from 'devextreme-react/button';

import ClientAccessSignupPop from "../clientsapproved/ClientAccessSignupPop";

const ClientContinueSignup = (props) => {
  const { clientid, user, refreshCRMActivity } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showClientSignupPop, setShowClientSignupPop] = useState(false);

  const showClientSignupDialog = () => {
    setShowClientSignupPop(true);
  };

  const hideClientSignupDialog = () => {
    setShowClientSignupPop(false);
  };

  return (
    <React.Fragment>
        <ClientAccessSignupPop
        showPopup={showClientSignupPop}
        hidePopup={hideClientSignupDialog}
        user={user}
        selClientid={clientid}
      />
      <div id='crm-activity-box'>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />
          <Col ratio={1.3} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div className='sd-header item'>
              <div className='dx-fieldset-header' id='cont-signup-label'>Check requested Document and define Risk as part of Client Signup</div>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div >
              <Button
                // width={120}
                height={36}
                text='Decide about Client Signup'
                type='default'
                stylingMode='outlined'
                onClick={showClientSignupDialog}
              />
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientContinueSignup;

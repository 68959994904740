import React, { useState, useEffect, useCallback } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ClientAccessPop from './ClientAccessPop';
import ClientApprovalPop from './ClientApprovalPop';
import ClientAccessSignupPop from './ClientAccessSignupPop';
import HTTP from '../../api/HTTP';

const ClientsApprovedAccess = (props) => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [clientApprovalStateData, setClientApprovalStateData] = useState([]);
  const [showClientAccessPop, setShowClientAccessPop] = useState(false);
  const [showClientApprovalPop, setShowClientApprovalPop] = useState(false);
  const [showClientSignupPop, setShowClientSignupPop] = useState(false);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selSignupClientid}`);
        setSignupClientDatalist(dataArray.data[0]);

        const dataArrayTwo = await HTTP.get(`/client/getclientapprovalstate/${selSignupClientid}`);
        setClientApprovalStateData(dataArrayTwo.data);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [selSignupClientid]);

  const stclientApprovalStateData = new ArrayStore({
    key: 'assetaccountid',
    data: clientApprovalStateData,
  });

  const dcClientApprovalStateData = new DataSource({
    store: stclientApprovalStateData,
  });

  const showClientAccessDialog = () => {
    setShowClientAccessPop(true);
  };

  const hideClientAccessPop = () => {
    setShowClientAccessPop(false);
  };

  const showClientApprovalDialog = () => {
    setShowClientApprovalPop(true);
  };

  const hideClientApprovalPop = async () => {
    await setShowClientApprovalPop(false);
    await reloadNewsGridCondition(true);
  };

  const showClientSignupDialog = () => {
    setShowClientSignupPop(true);
  };

  const hideClientSignupDialog = () => {
    setShowClientSignupPop(false);
  };

  return (
    <React.Fragment>
      <ClientAccessPop
        showPopup={showClientAccessPop}
        hidePopup={hideClientAccessPop}
        user={user}
        selClientid={selSignupClientid}
      />
      <ClientApprovalPop
        showPopup={showClientApprovalPop}
        hidePopup={hideClientApprovalPop}
        user={user}
        clientApprovalStateData={clientApprovalStateData}
        selSignupClientid={selSignupClientid}
      />
      <ClientAccessSignupPop
        showPopup={showClientSignupPop}
        hidePopup={hideClientSignupDialog}
        user={user}
        selClientid={selSignupClientid}
      />

      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1.8} />
        <Col ratio={9} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Account</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Signup'
              type='normal'
              stylingMode='outlined'
              disabled={signupClientDatalist && signupClientDatalist.productid < 0 ? false : true}
              onClick={showClientSignupDialog}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Approval'
              type='default'
              stylingMode='outlined'
              disabled={signupClientDatalist && signupClientDatalist.productid < 0 ? true : false}
              onClick={showClientApprovalDialog}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Access'
              type='default'
              stylingMode='outlined'
              onClick={showClientAccessDialog}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='client-access-info'>
            <table>
              <tbody>
                <tr>
                  <td width='100px'>Client Access:</td>
                  <td width='100px'>Active</td>
                  <td width='60px'>Since:</td>
                  <td width='100px'>01.01.2022</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p>Accounts:</p>
          </div>
          <DataGrid
            id='client-access-grid'
            height={'200px'}
            dataSource={dcClientApprovalStateData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            autoNavigateToFocusedRow={true}
            // focusedRowKey={selectedClientid}
            onFocusedRowChanged={(e) => {
              if (e.row) {
                //    setSelectedClientid(e.row.key.clientid);
                //    passToParent(e.row.key.clientid);
              }
            }}
          >
            <Column dataField='assetaccountid' visible={false}></Column>
            <Column dataField='providerid' visible={false}></Column>
            <Column dataField='productname' caption='Product' width={150} />
            <Column dataField='servicename' caption='Service' width={150} />
            <Column dataField='providername' caption='Provider' width={120} />
            <Column dataField='provider_approved' caption='Approved' width={80} />
            <Column dataField='access_state_txt' caption='Access State' width={120} />
            <Column dataField='access_state_time' caption='on' dataType='datetime' width={120} />
          </DataGrid>
          <div id='client-access-onboarding'>
            <table>
              <tbody>
                <tr>
                  <td width='130px'>Onboarding Fee:</td>
                  <td width='100px'>Paid</td>
                  <td width='60px'>01.01.2022</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedAccess;

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './OmCaseNote.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import CaseNote from './sections/CaseNote';
import CaseNoteDocuments from './sections/CaseNoteDocuments';
import CaseNoteMessageThread from '../messages/MessageClientsPage';
import ClientBase from './ClientBase';
import ClientProduct from './ClientProduct';
import HTTP from '../../api/HTTP';

const OmCaseNotePage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();
  const [caseNoteData, setCaseNoteData] = useState({});
  const [reloadInquiry, setReloadInquiry] = useState(false);
  const [showDocSection, setShowDocSection] = useState(false);
  const [showChatSection, setShowChatSection] = useState(false);
  const [docRequestId, setDocRequestId] = useState(-1);
  const [noteChatId, setNoteChatId] = useState(-1);

  const caseNoteBaseData = {
    selEntryId: user.casenote ? user.casenote.selEntryid : 0,
  };

  useEffect(() => {
    if (!user.casenote) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchInquiryData = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/single/${caseNoteBaseData.selEntryId}`);

        await setCaseNoteData(dataArray.data[0]);
        await setShowDocSection(dataArray.data[0].has_documents);
        await setShowChatSection(dataArray.data[0].has_chat);
        await setDocRequestId(dataArray.data[0].documentrequestid);
        await setNoteChatId(dataArray.data[0].chatthreadid);

        await HTTP.get(`/alerting/markalertread/-1/${caseNoteBaseData.selEntryId}/${user.portalid}`);

        setReloadInquiry(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (caseNoteBaseData.selEntryId || reloadInquiry) {
      fetchInquiryData();
    }
  }, [caseNoteBaseData.selEntryId, reloadInquiry]);

  const reloadInquiryData = useCallback(async (doReload) => {
    await setReloadInquiry(doReload);
  });

  function navigateToHome() {
    history.goBack();
  }

  const showDocuments = () => {
    setShowDocSection(!showDocSection);
  };

  const showChat = () => {
    setShowChatSection(!showChatSection);
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />

        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='omcase-base-box'>
                <ClientBase user={user} selSignupClientid={caseNoteData.clientid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='omcase-base-box'>
                <ClientProduct user={user} selSignupClientid={caseNoteData.clientid} />
              </div>
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <CaseNote
                user={user}
                caseNoteData={caseNoteData}
                reloadInquiryData={reloadInquiryData}
                showDocuments={showDocuments}
                showChat={showChat}
                showDocSection={showDocSection}
                showChatSection={showChatSection}
              />
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={1} />
          {showChatSection && noteChatId !== -1 ? (
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <div id='inq-chat-box'>
                  <p>
                    <b>Chat</b>
                  </p>
                  <div>
                    <CaseNoteMessageThread messageid={noteChatId} openedBy={2} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={1} colspan={1} />
          {showDocSection ? (
            <div className={'content-block'}>
              <div className={'dx-card'}>
                <CaseNoteDocuments user={user} selInquiryId={docRequestId} selPartnerid={user.portalid} />
              </div>
            </div>
          ) : (
            ''
          )}
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={4} col={1} />
          <div id={'inq-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={130}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default OmCaseNotePage;

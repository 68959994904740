import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './costrateservices.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import ServicesEditPop from './ServicesEditPop';
import HTTP from '../../api/HTTP';

const CostRatesManageTab = props => {
  const { passToParent, user, selCompanyID } = props;
  const history = useHistory();
  const [costRateHeaderList, setCostRateHeaderList] = useState([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popupTabsVisible, setPopupTabsVisible] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadHeader, setReloadHeader] = useState(false);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(
          '/service/costrateheader'
        );
        setCostRateHeaderList(dataArray.data);
      } catch (err) {}
    };
    fetchCostRatesHeader();
  }, [ reloadHeader]);

  const stCostRateHeaderData = new ArrayStore({
    key: 'costentryid',
    data: costRateHeaderList,
  });

  const dcCostRateHeaderData = new DataSource({
    store: stCostRateHeaderData,
  });

  const openContactEdit = () => {
    setpopModeInsert(false);
    setPopupTabsVisible(false);
    setShowContactPopup(true);
  };

  const openContactAdd = () => {
    setpopModeInsert(true);
    setPopupTabsVisible(true);
    setShowContactPopup(true);
  };

  const hideContactPopup = useCallback(doContactReload => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadHeader(!reloadHeader);
    }
  });

  return (
    <React.Fragment>
      <ServicesEditPop showPopup={showContactPopup} hidePopup={hideContactPopup} user={user} />
      <ResponsiveBox>
        <Col ratio={0.3} screen='md lg sm' />
        <Col ratio={3} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <Button width={120} height={50} text='Add' type='normal' stylingMode='outlined' onClick={openContactAdd} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={1} col={0} />
          <div>
            <DataGrid
              id='service-cost-rates-header-grid-base'
              dataSource={dcCostRateHeaderData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={e => {
                if (e.row) {
                  passToParent(e.row.data.costentryid, e.row.data.serviceid);
                }
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='serviceid' visible={false} />
              <Column dataField='providerid' visible={false} />
              <Column dataField='costentryid' visible={false} />
              <Column dataField='servicename' caption='Service' width={300} />
              <Column dataField='providername' caption='Provider' width={170} />
              <Column dataField='generated' caption='Generated' dataType='datetime' width={150} visible={false} />
              <Column dataField='entrynumber' caption='Cost No' width={100} visible={false} />
              <Column dataField='valid_on' caption='Valid From' dataType='date' width={100} />
              <Column dataField='valid_until' caption='Valid Until' dataType='date' width={100} />
              <Column dataField='userid' visible={false} />
              <Column dataField='currency' caption='Currency' width={70} />
              <Column dataField='active' caption='Active' width={80} />
              <Column dataField='active_date' caption='Active on' dataType='date' width={100} />
              <Column dataField='combined_name' caption='User' width={150} visible={false} />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CostRatesManageTab;

import React, { useEffect, useRef, useState } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react';

const InquiryAddDocsPop = props => {
  const { showPopup, hidePopup, user, documentsArray } = props;
  const [selDocument, setSelDocument] = useState({});
  const [saveDocument, setSaveDocument] = useState(false);
  const [selDocIndex, setSelDocIndex] = useState(-1);

  const stReqDocData = new ArrayStore({
    key: 'id',
    data: documentsArray,
  });

  const addDocumentTemplate = async () => {
    setSaveDocument(true);
    if (selDocIndex !== -1 && selDocument) {
      hidePopup(true, selDocument);
    } else {
      hidePopup(false, selDocument);
    }
  };

  return (
    <Popup
      title={'Add Documents'}
      width={400}
      height={180}
      visible={showPopup}
      showCloseButton={true}
      onShowing={() => {
        setSelDocument({});
        setSaveDocument(false);
        setSelDocIndex(-1);
      }}
      onHiding={() => {
        if (selDocIndex === -1 || !saveDocument) {
          hidePopup(false, {});
        }
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <table>
            <tbody>
              <tr>
                <td>From Document Templates</td>
              </tr>
            </tbody>
          </table>
          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={3} />
            <Col ratio={2} />

            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />

              <div>
                <SelectBox
                  dataSource={stReqDocData}
                  displayExpr={'usage_advice'}
                  valueExpr='id'
                  value={selDocIndex}
                  width={'85%'}
                  onValueChanged={e => {
                    setSelDocIndex(e.value);
                  }}
                  onSelectionChanged={e => {
                    setSelDocument(e.selectedItem);
                  }}
                />
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <div>
                <Button
                  width={100}
                  height={40}
                  text='Add'
                  type='default'
                  stylingMode='contained'
                  onClick={() => {
                    addDocumentTemplate();
                  }}
                />
              </div>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default InquiryAddDocsPop;

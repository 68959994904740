import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ProductPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import DataGrid, { Column } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import HTTP from '../../api/HTTP';

const ProductSetup = (props) => {
  const { selProductId, user } = props;
  const history = useHistory();
  const dataFormRef = useRef(null);
  const [productData, setProductData] = useState({});
  const [productServicesData, setProductServicesData] = useState([]);

  useEffect(() => {
    const fetchSingleProduct = async () => {
      try {
        const dataArray = await HTTP.get(`/product/singleproduct/${selProductId}`);
        setProductData(dataArray.data[0]);
      } catch (err) {}
    };

    if (selProductId) {
      fetchSingleProduct();
    }
  }, [selProductId]);

  useEffect(() => {
    const fetchProductServices = async () => {
      try {
        const dataArray = await HTTP.get(`/product/systemservices/${selProductId}`);
        setProductServicesData(dataArray.data);
      } catch (err) {}
    };
    if (selProductId) {
      fetchProductServices();
    }
  }, [selProductId]);

  const stProductServicesData = new ArrayStore({
    key: 'serviceid',
    data: productServicesData,
  });

  const product_FormData = {
    productid: (productData && productData.productid) || -1,
    partnerid: (productData && productData.partnerid) || user.managedpartner,
    productname: (productData && productData.productname) || '',
    startdate: productData && productData.startdate,
    enddate: productData && productData.enddate,
    userid: (productData && productData.userid) || user.userId,
    currency: (productData && productData.currency) || '',
    productactive: (productData && productData.productactive) || false,
  };

  function navigateToHome() {
    history.goBack();
  }

  return (
    <div id='client-product-form'>
      <ResponsiveBox>
        <Row ratio={2} />
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            ref={dataFormRef}
            formData={product_FormData}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            validationGroup='product_create_data'
          >
            <Item itemType='group' colCount={6}>
              <SimpleItem dataField='productname' isRequired={true} editorOptions={baseEditorOptions}>
                <RequiredRule message='Please enter a Product Name' />
                <Label text='Name' />
              </SimpleItem>
              <SimpleItem dataField='currency' editorOptions={baseEditorOptions}>
                <Label text='Currency' />
              </SimpleItem>
              <SimpleItem dataField='productactive' editorType='dxCheckBox'>
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem dataField='startdate' editorType='dxDateBox' editorOptions={{ type: 'date' }}>
                <Label text='Start Date' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <ResponsiveBox>
            <Row ratio={1} />
            <Row ratio={4} />
            <Item>
              <Location screen='md lg sm xs' row={1} col={0} />
              <DataGrid
                id='client-product-services-edit-grid'
                dataSource={stProductServicesData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
              >
                <Column dataField='serviceid' visible={false} />
                <Column dataField='servicename' caption='Services' />
                <Column dataField='category_name' caption='Category' />
                <Column dataField='main_bank_account' dataType='boolean' caption='Main Bank Account' width='140' />
              </DataGrid>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ProductSetup;

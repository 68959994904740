import { useState, useCallback, useRef, useEffect } from 'react';
import HTTP from '../api/HTTP';

export const useCaseNote = () => {
  const sendCaseNoteSilent = useCallback(async (user, clientid, caseSituation, notecomment) => {
    //   console.log(1009, user, clientid, caseSituation, notecomment);
    try {
      await HTTP.post(
        '/cases/sendcasenotesilent',
        JSON.stringify({
          userid: user.id,
          clientid: clientid,
          caseSituation: caseSituation,
          portalid: user.portalid,
          notecomment: notecomment,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    sendCaseNoteSilent: sendCaseNoteSilent,
  };
};

import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

const TransactionsClientDetails = props => {
  const { tabVisibleIndex, caseDetails } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showPopup, setShowPopup] = useState(false);

  const navigatetoCasesOverview = () => {
    setShowPopup(true);
  };

  const hidePopup = useCallback(doContactReload => {
    setShowPopup(false);
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <p>Client Details</p>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default TransactionsClientDetails;

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServiceProvClientsSignup = (props) => {
  const { user, selProviderId } = props;
  const history = useHistory();
  const [partnerClientDataList, setPartnerClientDatalist] = useState([]);
  const [selectedClientid, setSelectedClientid] = useState(-1);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showApprovalSettingsPopup, setShowApprovalSettingsPopup] = useState(false);
  const [selServiceid, setselServiceid] = useState(-1);
  const [doReload, setDoReload] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsprovidersignup/${selProviderId}`);
        setPartnerClientDatalist(dataArray.data);
      } catch (err) {}
    };

    if (selProviderId || doReload) {
      fetchCustomers();
    }
  }, [selProviderId, doReload]);

  const stPartnerCustomerData = new ArrayStore({
    key: 'clientid',
    data: partnerClientDataList,
  });

  const dcPartnerCustomerData = new DataSource({
    store: stPartnerCustomerData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <DataGrid
              id='service-prov-clients-signup-tab-grid'
              dataSource={dcPartnerCustomerData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selectedClientid}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  setSelectedClientid(e.row.key);
                  setselServiceid(e.row.data.serviceid);
                }
              }}
            >
              <Column dataField='clientid' visible={false}></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='servicename' caption='Service' visible={true} width={250} />
              <Column dataField='cl_lastname' caption='Name' visible={true} width={150} />
              <Column dataField='cl_firstname' caption='First Name' visible={true} width={100} />
              <Column dataField='cl_countryofresidence' caption='Living in' visible={true} width={150} />
              <Column dataField='risklevel' caption='Risk Level' visible={true} width={120} />
              <Column
                dataField='provider_approved'
                caption='Approved'
                dataType='boolean'
                visible={true}
                width={100}
              ></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServiceProvClientsSignup;

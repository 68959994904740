import React, { useState, useEffect, useCallback } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import DataGrid, { Column, Selection, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ClientProduct = (props) => {
  const { selSignupClientid, user } = props;
  const [signupClientDatalist, setSignupClientDatalist] = useState({});
  const [clientProductData, setClientProductData] = useState([]);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selSignupClientid}`);
        setSignupClientDatalist(dataArray.data[0]);

        const dataArrayTwo = await HTTP.get(`/client/getclientproducts/-1/${selSignupClientid}`);
        setClientProductData(dataArrayTwo.data);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [selSignupClientid]);

  const stClientProductData = new ArrayStore({
    key: 'assetaccountid',
    data: clientProductData,
  });

  const dcClientProductData = new DataSource({
    store: stClientProductData,
  });

  return (
    <React.Fragment>
      <div id='client-reg-prod-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Registered Products</div>
        </div>
        <ResponsiveBox>
          <Col />
          <Row />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <DataGrid
              id='client-access-grid'
              height={'300px'}
              dataSource={dcClientProductData}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              // focusedRowKey={selectedClientid}
            >
              <Selection mode='single' />
              <Column dataField='assetaccountid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='productname' caption='Product' width={150} />
              <Column dataField='servicename' caption='Service' width={150} />
              <Column dataField='providername' caption='Provider' width={120} />
              <Column dataField='provider_approved' caption='Approved' width={80} />
              <Column dataField='access_state_txt' caption='Access State' width={120} />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientProduct;

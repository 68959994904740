import React, { useState, useEffect, useCallback } from 'react';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import PartnerServiceDetailGrid from '../../detailgrids/PartnerServiceDetailGrid';
import HTTP from '../../api/HTTP';

const ServicePartnerSellRates = (props) => {
  const { selServiceId, selPartnerId, user } = props;

  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(`/partner/singlepartnerservicecostrateheader/${selServiceId}/${selPartnerId}`);
        setCostRateHeaderList(dataArray.data[0]);

        let anCostEntryId = -1;

        if (dataArray.data[0].costentryid) {
          anCostEntryId = dataArray.data[0].costentryid;
        }

        const costRiskDataArray = await HTTP.get(
          `/partner/viewpartnercostriskentries/${selPartnerId}/${anCostEntryId}`
        );

        setCostRiskEntriesList(costRiskDataArray.data);
      } catch (err) {}
    };

    if (selServiceId && selServiceId > -1) {
      fetchCostRatesHeader();
    }
  }, [selServiceId]);

  const costRateHeader = {
    partnerid: (costRateHeaderList && costRateHeaderList.partnerid) || -1,
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form
            id='service-costrates'
            formData={costRateHeader}
            labelLocation={'left'}
            colCountByScreen={colCountByScreen}
            scrollingEnabled={true}
            readOnly={true}
          >
            <Item itemType='group' colCount={5}>
              <SimpleItem dataField='currency'>
                <Label text='Currency' />
              </SimpleItem>
              <SimpleItem dataField='valid_on' dataType='date' editorType='dxDateBox'>
                <Label text='Valid on' />
              </SimpleItem>
              <SimpleItem dataField='valid_until' dataType='date' editorType='dxDateBox'>
                <Label text='Valid on' />
              </SimpleItem>
              <SimpleItem dataField='active' dataType='boolean' editorType='dxCheckBox'>
                <Label text='Active' />
              </SimpleItem>
              <SimpleItem
                dataField='active_date'
                dataType='date'
                editorType='dxDateBox'
                editorOptions={{ readOnly: true }}
              >
                <Label text='Active on' />
              </SimpleItem>
            </Item>
            <Item>
              <DataGrid
                // id={'service-edit-cost-rates-risk-grid'}
                dataSource={dcCostRiskEntriesData}
                // defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                allowColumnReordering={false}
                allowColumnResizing={false}
                focusedRowEnabled={true}
                showBorders={false}
                showRowLines={true}
                showColumnLines={false}
                autoNavigateToFocusedRow={true}
                // onFocusedRowChanged={e => {
                //   setRiskGridData(e.row.data);
                //   setSelRiskid(e.row.data.riskid);
                // }}
              >
                <Column dataField='partnerid' visible={false} />
                <Column dataField='serviceid' visible={false} />
                <Column dataField='costentryid' visible={false} />
                <Column dataField='riskid' visible={false} />
                <Column dataField='risklevel' caption='Risk Level' width='250px' allowEditing={false} />
                <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='100' />
                <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='100' />
                <Column dataField='recperiod' caption='Recurring' width='250'></Column>
                <MasterDetail enabled={true} component={PartnerServiceDetailGrid} />
              </DataGrid>
            </Item>
          </Form>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServicePartnerSellRates;

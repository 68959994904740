import HTTP from '../../api/HTTP';

class DocExchangeHelper {
  getDocumentsMetaInfo = docId => {
    return HTTP.get(`/doc_exch/documents/${docId}`);
  };

  changeDocument = docId => {
    return HTTP.put(`/doc_exch/documents/${docId}`);
  };

  deleteDocument = docId => {
    return HTTP.delete(`/doc_exch/documents/${docId}`);
  };

  genDocumentUrl = docId => {
    return HTTP.defaults.baseURL + `/doc_exch/documents/content/${docId}`;
  };

  downloadDocument = (docId, asName) => {
    HTTP.get(this.genDocumentUrl(docId), {
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', asName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  addDocumentToFolder = formData => {
    return HTTP.post(`/doc_exch/documents`, formData);
  };

  getRootFolder = async () => {
    return HTTP.get(`/doc_exch/folder`);
  };

  createNewFolder = (parentId, label) => {
    return HTTP.post(`/doc_exch/folder`, {
      parentId,
      label,
    });
  };

  renameFolder = (id, label) => {
    return HTTP.put(`/doc_exch/folder`, {
      id,
      label,
    });
  };
  // TODO: for the future
  renameDocument = (id, label) => {
    return Promise.resolve(label);
    // return HTTP.put(`/doc_exch/folder`, {
    //     id,
    //     label
    // });
  };

  getFolderById = id => {
    return HTTP.get(`/doc_exch/folder/${id}`);
  };

  deleteFolder = id => {
    return HTTP.delete(`/doc_exch/folder/${id}`);
  };

  getFolderContent = async id => {
    let pathData = '';
    if (id !== null) {
      pathData = `/${id}`;
    }
    return HTTP.get(`/doc_exch/folder/content${pathData}`).then(response => response.data);
  };

  getRequestTypes = () => {
    return HTTP.get('/doc_exch/request_types').then(response => response.data);
  };

  getDocumentTypes = () => {
    return HTTP.get('/doc_exch/document/types').then(response => response.data);
  };

  getRequiredCustomerDocumentsForService = serviceId => {
    return HTTP.get(`/doc_exch/service/${serviceId}`).then(response => response.data);
  };

  uploadDocument = (serviceId, data) => {
    return HTTP.post(`/doc_exch/service/${serviceId}`, data);
  };

  uploadDocumentGlobal = data => {
    return HTTP.post(`/document/add`, data);
  };

  uploadCustomDocumentThread = data => {
    return HTTP.post(`/inquiries/uploadDocumentThread`, data);
  };

  getServiceDocuments = (serviceId, params) => {
    return HTTP.get(`/doc_exch/service/${serviceId}`, { params }).then(res => res.data);
  };

  getDocumentDetails = (serviceId, documentId) => {
    return HTTP.get(`/doc_exch/service/${serviceId}/${documentId}`).then(res => res.data);
  };

  getDocumentDownloadLink = (serviceId, documentId) => {
    return HTTP.get(`/doc_exch/service/${serviceId}/${documentId}/content`, {
      responseType: 'blob',
    });
  };

  createNewDocumentsRequestForClient = (selSignupClientId, userId, portalId, data) => {
    return HTTP.post(`/inquiries/createClientRequests/`, {
      title: data.title,
      userId: userId,
      portalId: portalId,
      clientId: selSignupClientId,
      typeId: 1,
      description: data.description,
      serviceId: -1,
      documentIds: data.templates,
    }).then(response => response.data);
  };

  getClientRequests = (serviceId, clientId, queryStringData) => {
    return HTTP.get(`/doc_exch/requests/${serviceId}/${clientId}`, {
      params: queryStringData,
    }).then(response => response.data);
  };

  getDocumentRequestInformation = requestId => {
    return HTTP.get(`/doc_exch/requests/${requestId}`).then(response => response.data);
  };

  changeDocumentRequestStatus = (requestId, statusId) => {
    return HTTP.put(`/doc_exch/requests/status`, { requestId, statusId }).then(response => response.data);
  };

  getClientDocumentsForRequest = requestId => {
    return HTTP.get(`/doc_exch/requests/${requestId}/documents`, {}).then(response => response.data);
  };

  resendDocumentRequest = requests => {
    return HTTP.post(`/doc_exch/requests/resend-notification`, {
      requestIds: requests,
    });
  };

  getDocumentRequestStatuses = () => {
    return HTTP.get('/doc_exch/requests/status/all').then(request => request.data);
  };
}

export default new DocExchangeHelper();

import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Position } from 'devextreme-react/data-grid';

const CRMActivity = (props) => {
  const { cRMActivity } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  return (
    <React.Fragment>
      <div id='crm-activity-box'>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div className='sd-header item'>
              <table>
                <tbody>
                  <tr>
                    <td width='500px' className='dx-fieldset-header'>
                      {cRMActivity.activitystate}
                    </td>
                    <td width='160px' className='dx-fieldset-header'>
                      Required Action:
                    </td>
                    <td width='600px' className='dx-fieldset-header'>
                      {cRMActivity.activityaction}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default CRMActivity;

import authService from '../services/AuthService';
import tokenService from '../services/TokenService';

export async function signIn(email, password, portalType) {
  try {
    const tokenResponse = await authService.login(email, password, portalType);
    //console.log('-1-', tokenResponse);

    // if (tokenResponse.data.accessToken && tokenResponse.data.refreshToken) { // TODO: add refreshToken
    if (tokenResponse.data.accessToken) {
      // tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
      tokenService.setAccessToken(tokenResponse.data.accessToken);
      const currentUser = await authService.getAuthUser();

      // console.log('-2345-', currentUser);
      
      const frxresult = currentUser.sections.filter(obj => {
        return obj.sectionshortid === 'FX';
      });
      
      if (frxresult.length === 1) {
        currentUser.seeForex = true;
      } else {
        currentUser.seeForex = false;
      };

      const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60);

      currentUser.userId = currentUser.id;
      currentUser.lastname = currentUser.lastName;
      currentUser.firstname = currentUser.firstName;
      // currentUser.email = currentUser.email;
      // currentUser.mainadminid = 1; // responseDataSrv.mainadminid,
      // currentUser.isadmin = true; // responseDataSrv.isadmin,
      currentUser.fullname = currentUser.lastName + ' ' + currentUser.firstName;
      currentUser.selcompany = -1;
      currentUser.managedpartner = -1; // responseDataSrv.managedpartner,
      currentUser.routingid = -1; // responseDataSrv.routingid,
      currentUser.managedpartner_name = ''; // responseDataSrv.managedpartner_name,
      currentUser.token = tokenResponse.data.accessToken; // responseDataSrv.token,
      currentUser.expiration = tokenExpirationDate.toISOString();

      // console.log('-2-', currentUser);

      localStorage.setItem('userData', JSON.stringify(currentUser));

      return {
        isOk: true,
        user: currentUser,
      };
    }

    if (tokenResponse.data.hash) {
      return {
        isOk: true,
        auth2: tokenResponse.data,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function signIn2auth(pin, portalType, hash) {
  try {
    const tokenResponse = await authService.sendGoogleAuthPin(pin, portalType, hash);
    // if (tokenResponse.data.accessToken && tokenResponse.data.refreshToken) { // TODO: add refresh token
    if (tokenResponse.data.accessToken) {
      // tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
      tokenService.setAccessToken(tokenResponse.data.accessToken);
      const currentUser = await authService.getAuthUser();
      return {
        isOk: true,
        user: currentUser,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function activateGoogle2auth(pin, portalType, hash) {
  try {
    const tokenResponse = await authService.activateGoogle2auth(pin, hash);
    // if (tokenResponse.data.accessToken && tokenResponse.data.refreshToken) { //TODO: add refresh token
    if (tokenResponse.data.accessToken) {
      // tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
      tokenService.setAccessToken(tokenResponse.data.accessToken);
      const currentUser = await authService.getAuthUser();
      return {
        isOk: true,
        user: currentUser,
      };
    }
  } catch (error) {
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export function logOut() {
  tokenService.removeAll();
}

export async function getUser() {
  try {
    // Send request
    const storedData = JSON.parse(localStorage.getItem('userData'));
    // let storedData;
    console.log('--1--');
    console.log(storedData);
    console.log('--1--End--1--');
    console.log(new Date());
    console.log(new Date(storedData.expiration));
    if (
      !(storedData && storedData.token) ||
      !(new Date(storedData.expiration) > new Date()) ||
      storedData.userId === -5
    ) {
      console.log('False');
      return {
        isOk: false,
      };
    } else {
      console.log('True');
      return {
        isOk: true, // Automatisches Einloggen erfolgt wenn isOK true ist
        data: storedData,
        user: storedData,
      };
    }
  } catch (error) {
    return {
      isOk: false,
    };
  }
}

{
  /*
export async function getUser() {
  try {
    const currentUser = await authService.getAuthUser();
    return {
      isOk: true,
      user: currentUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}
 */
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(recoveryCode, newPassword, confirmPassword) {
  try {
    return authService.setNewPassword(recoveryCode, newPassword, confirmPassword);
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log('Reset password: ' + email);
    const resetResponse = await authService.resetPasswordRequest(email);
    return {
      isOk: true,
      success: resetResponse.data.success,
    };
  } catch (e) {
    return {
      isOk: false,
      message: 'Failed to reset password',
      error: e.response.data.fieldErrors.email,
    };
  }
}


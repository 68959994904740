import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  ProductsPage,
  ReportingPage,
  CostRatesBasePage,
  ServiceFeeEdit,
  PartnerServiceFeesEdit,
  ProductServiceFeesEdit,
  TransactionsPage,
  ClientsApprovedPage,
  ComplianceMatrixPage,
  PortalUserPage,
  PartnerServicesPage,
  OperationsPage,
  InquiryPage,
  ServiceProviderPage,
  OmCaseNotePage,
  ClientPage,
  ProductPage,
  GlobalDefinitionsPage,
  PartnerServicesEditPage,
  ForexPage,
  ChatPage,
} from './pages';

const routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/products',
    component: ProductsPage,
  },
  {
    path: '/reporting',
    component: ReportingPage,
  },
  {
    path: '/productedit',
    component: ProductPage,
  },
  {
    path: '/costratesbase',
    component: CostRatesBasePage,
  },
  {
    path: '/servicefees_edit',
    component: ServiceFeeEdit,
  },
  {
    path: '/partnerservicefees_edit',
    component: PartnerServiceFeesEdit,
  },
  {
    path: '/productservicefees_edit',
    component: ProductServiceFeesEdit,
  },
  {
    path: '/transactions',
    component: TransactionsPage,
  },
  {
    path: '/clients',
    component: ClientsApprovedPage,
  },
  {
    path: '/portalusers',
    component: PortalUserPage,
  },
  {
    path: '/partnerservices',
    component: PartnerServicesPage,
  },
  { path: '/operations', component: OperationsPage },
  { path: '/inquiry', component: InquiryPage },
  { path: '/serviceprovider', component: ServiceProviderPage },
  { path: '/casenote', component: OmCaseNotePage },
  { path: '/clientdata', component: ClientPage },
  { path: '/globaldefinitions', component: GlobalDefinitionsPage },
  { path: '/partnerservice', component: PartnerServicesEditPage },
  { path: '/forex', component: ForexPage },
  {
    path: '/chat',
    component: ChatPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});

import React, { useState, useEffect } from 'react';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import Map from 'devextreme-react/map';
import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import HTTP from '../../api/HTTP';

const ClientsinSignupGeolocation = (props) => {
  const { tabVisibleIndex, selSignupClientid, user } = props;
  const [signupClientDatalist, setSignupClientDatalist] = useState([]);
  const [signupClientIPAddresses, setSignupClientIPAddresses] = useState([]);
  const markerUrl =
    'https://js.devexpress.com/Demos/RealtorApp/images/map-marker.png';

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(
            `/client/clientgeolocationdata/${selSignupClientid}`,
        );
        console.log(dataArray.data);

        const markersData = [];
        const ipAddresses = [];

        for (let i = 0; i < dataArray.data.length; i++) {
          let toolTipText =
            dataArray.data[i].ip_address +
            ' , ' +
            dataArray.data[i].city +
            ' , ' +
            dataArray.data[i].country +
            ' , ' +
            dataArray.data[i].country_code +
            ' , ' +
            dataArray.data[i].country;

          markersData.push({
            location: [dataArray.data[i].latitude, dataArray.data[i].longitude],
            tooltip: { text: toolTipText },
          });

          ipAddresses.push({
            ip: dataArray.data[i].ip_address,
            ip_whitelisted: dataArray.data[i].ip_whitelisted,
            ip_blacklisted: dataArray.data[i].ip_blacklisted,
            city: dataArray.data[i].city,
            country: dataArray.data[i].country,
            country_code: dataArray.data[i].country_code,
          });
        }

        setSignupClientDatalist(markersData);
        setSignupClientIPAddresses(ipAddresses);
      } catch (err) {}
    };

    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [selSignupClientid]);

  function showTooltips() {
    signupClientDatalist.map((item) => {
      const newItem = JSON.parse(JSON.stringify(item));
      newItem.tooltip.isShown = true;
      return newItem;
    });
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <div className="dx-fieldset">
            <div className="dx-fieldset-header">
              IP And Geolocation Data
            </div>
            {signupClientIPAddresses &&
              signupClientIPAddresses.map((item, rowindex) => (
                <div key={rowindex}>
                  <div className="note-details-personally-block" key={rowindex}>
                    IP: {item.ip} WL: {item.ip_whitelisted} BL:{' '}
                    {item.ip_blacklisted} City: {item.city} Country:{' '}
                    {item.country} Ccode: {item.country_code}
                  </div>
                </div>
              ))}
          </div>
          <div>
            <Map
              defaultZoom={11}
              height={440}
              width="100%"
              controls={true}
              markerIconSrc={markerUrl}
              markers={signupClientDatalist}
              provider="bing"
            ></Map>
            <div className="options">
              <div className="caption">Options</div>
              <div className="option">
                <CheckBox defaultValue={true} text="Use custom marker icons" />
              </div>
              <div className="option">
                <Button text="Show all tooltips" onClick={showTooltips} />
              </div>
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsinSignupGeolocation;

import React, { useState, useCallback } from "react";
import "./partnerservices.css";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import { Tabs, MultiView } from "devextreme-react";
import { useAuth } from "../../contexts/auth";

import PartnerServicesTab from "./PartnerServicesTab";
import ServiceFeatures from "./ServiceFeatures";
import ServiceProvider from "./ServiceProvider";
import ServicePartnerSellRates from "./ServicePartnerSellRates";

const PartnerServicesPage = (props) => {
  const { selPartnerId, selRoutingId, partnername } = props;
  const { user } = useAuth();
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const getServiceid = useCallback((fServiceId) => {
    setSelectedServiceId(fServiceId);
  });

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card"}>
        <div className="dx-fieldset-header" id="cap_item">
                      Available Services
                    </div>

          <ResponsiveBox>
            <Row />
            <Col />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div className="rb-content item">
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen="md lg sm xs" row={0} col={0} />
                    <PartnerServicesTab
                      passToParent={getServiceid}
                      user={user}
                      selPartnerId={user.partnerid}
                      selRoutingId={user.routingid}
                      partnername={partnername}
                    />
                    <div>
                      <ResponsiveBox>
                        <Row />
                        <Col ratio={0.3} />
                        <Col ratio={3} />
                        <Item>
                          <Location screen="md lg sm xs" row={0} col={0} />
                        </Item>
                        <Item>
                          <Location screen="md lg sm xs" row={0} col={1} />
                          <Tabs
                            selectedIndex={tabIndex}
                            width="99%"
                            // height='20px'
                            visible={true}
                            onItemClick={(e) => {
                              setTabIndex(e.itemIndex);
                            }}
                          >
                            <Item text="Service - Cost Rates" />
                            <Item text="Features" />
                            <Item text="Provider" />
                          </Tabs>
                          <MultiView height={430} animationEnabled={false} selectedIndex={tabIndex}>
                            <Item title="Partner Sell Rates">
                              <ServicePartnerSellRates
                                selServiceId={selectedServiceId}
                                selPartnerId={user.partnerid}
                                selRoutigId={user.routingid}
                                user={user}
                              />
                            </Item>{" "}
                            <Item title="Assinged Services">
                              <ServiceFeatures
                                selSystemServiceID={selectedServiceId}
                                selTabIndex={tabIndex}
                                user={user}
                              />
                            </Item>
                            <Item title="ServiceProvider">
                              <ServiceProvider selServiceId={selectedServiceId} user={user} />
                            </Item>
                          </MultiView>
                        </Item>
                      </ResponsiveBox>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen="md lg" row={2} col={0} colspan={2} />
              <Location screen="sm" row={2} col={0} colspan={1} />
              <Location screen="xs" row={2} col={0} />
              <div className="rb-footer item" id="cap_item"></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerServicesPage;

import React, { useEffect, useState, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";

import "./GlobalDefinitions.css";

import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";

import { useAuth } from "../../contexts/auth";

import PartnerEditOnboardingGrid from "./PartnerEditOnboardingGrid";
import PartnerEditRegionsTab from "./PartnerEditRegionsTab";
import PartnerEditReqDoc from "./PartnerEditReqDoc";
import PartnerEditTermsofUsePage from "./PartnerEditTermsofUsePage";
import ComplianceMatrixPage from "./ComplianceMatrixPage";

const GlobalDefinitionsPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [clientData, setClientData] = useState({});
  const [reloadClient, setReloadClient] = useState(false);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen="md lg xs sm" row={0} col={0} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="global-defs-box-line1-left">
                <PartnerEditOnboardingGrid user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={0} col={1} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="global-defs-box-line1-right">
                <PartnerEditRegionsTab user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={1} col={0} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="global-defs-box-line2-left">
                <PartnerEditReqDoc user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={1} col={1} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="global-defs-box-line2-right">
                <PartnerEditTermsofUsePage user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen="md lg xs sm" row={2} col={0} colspan={2} />
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div id="global-defs-box-line3">
                <ComplianceMatrixPage user={user} />
              </div>
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default GlobalDefinitionsPage;

import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import '../ComplianceMatrix.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, Container, Card } from 'devextreme-react/form';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import HTTP from '../../../api/HTTP';

export default function MatrixModel(props) {
  const { modelid, user } = props;
  const [matrixData, setMatrixData] = useState([]);
  const [showRiskMatrixPopup, setShowRiskMatrixPopup] = useState(false);
  const [focusedDataRow, setFocusedDataRow] = useState({});
  const [addNewMatrixEntry, setAddNewMatrixEntry] = useState(false);
  const [selRiskMatrixGroupId, setSelRiskMatrixGroupId] = useState(-1);
  const [reloadFeatures, setReloadFeatures] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [featureGroupData, setFeatureGroupData] = useState([]);
  const [showRiskGroupPopup, setShowRiskGroupPopup] = useState(false);
  const [reloadGroupFeatures, setReloadGroupFeatures] = useState(false);

  useEffect(() => {
    const fetchMatrix = async () => {
      const response = await HTTP.get(`/ai/feature-matrix/matrix/${modelid}`);
      setMatrixData(response.data);
    };
    if (modelid) {
      fetchMatrix();
    }
  }, [modelid, reloadFeatures]);

  useEffect(() => {
    const fetchFeatureValues = async () => {
      const response = await HTTP.get(`/ai/feature-matrix/feature-group-values/${modelid}/${selRiskMatrixGroupId}`);
      // console.log(response);
      setFeatureGroupData(response.data);
      setReloadGroupFeatures(false);
    };
    if ((modelid, selRiskMatrixGroupId)) {
      fetchFeatureValues();
    }
  }, [modelid, selRiskMatrixGroupId, reloadGroupFeatures]);

  const modRiskMatrixEntry = async (modify) => {
    const response = await HTTP.get(
      '/ai/feature-matrix/mod_matrix_entry',
      JSON.stringify({
        operation: modify,
        modelid: 1,
        entryid: focusedDataRow.entryid,
      })
    );
    console.log(response.data);
  };

  const addMatrixEntry = () => {
    setAddNewMatrixEntry(true);
    setShowRiskMatrixPopup(true);
  };

  const editMatrixEntry = () => {
    setAddNewMatrixEntry(false);
    setShowRiskMatrixPopup(true);
  };

  const hideRiskMatrixPopup = () => {
    setShowRiskMatrixPopup(false);
    setReloadFeatures(!reloadFeatures);
  };

  const hideMessagePop = async (doRemove) => {
    setShowMessagePop(false);
    if (doRemove) {
      await modRiskMatrixEntry(3);
      setReloadFeatures(!reloadFeatures);
    }
  };

  const removeMatrixEntry = () => {
    setShowMessagePop(true);
  };

  const defineRiskGroups = () => {
    setShowRiskGroupPopup(true);
  };

  const hideRiskGroupPopup = () => {
    setShowRiskGroupPopup(false);
    setReloadGroupFeatures(true);
    // }
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={7} />
        <Col ratio={5} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <h4 className='card-heading'>AI - Model Matrix</h4>
          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={1} />

            <Item>
              <Location screen='md lg xs sm' row={0} col={0} />
              <DataGrid
                id='MatrixEntryGrid'
                dataSource={matrixData}
                keyExpr={['entryid', 'group_entryid']}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                columnAutoWidth={true}
                focusStateEnabled={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                onFocusedRowChanged={(e) => {
                  if (e) {
                    setFocusedDataRow(e.row.data);
                    setSelRiskMatrixGroupId(e.row.key.entryid);
                  }
                }}
              >
                <Column dataField='entryid' visible={false} />
                <Column dataField='group_entryid' visible={false} />
                <Column dataField='clienttype' visible={false} />
                <Column dataField='risk_name' visible={true} caption='Risk Name' width='360px' />
                <Column dataField='clienttypevalue' visible={true} caption='Affects' width='150px' />
                <Column dataField='riskareavalue' visible={true} caption='Risk Area' width='150px' alignment='left' />
                <Column dataField='risk_groupid' visible={false} width='100px' />
              </DataGrid>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div id='ai-form-sub'>
            <h4 className='card-heading'>Feature Values</h4>
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />
              <Item>
                <Location screen='md lg xs sm' row={0} col={0} />
                <DataGrid
                  id='MatrixEntryGrid'
                  dataSource={featureGroupData}
                  keyExpr={'group_entryid'}
                  showRowLines={true}
                  showBorders={true}
                  showColumnLines={true}
                  columnAutoWidth={true}
                  focusStateEnabled={true}
                  defaultFocusedRowIndex={0}
                  focusedRowEnabled={true}
                  showColumnHeaders={true}
                >
                  <Column dataField='risk_groupid' visible={false} />
                  <Column dataField='group_entryid' caption='Entryid' width='60' visible={false} />
                  <Column dataField='group_entry' caption='Entry' />
                </DataGrid>
              </Item>
            </ResponsiveBox>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}

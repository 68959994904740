import React, { useState, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import AlertPanel from '../user-panel/alert-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useAuth } from '../../contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faPhone, faPhoneSlash } from '@fortawesome/pro-thin-svg-icons';
import HTTP from '../../api/HTTP';
import { useHistory } from 'react-router-dom';

import Draggable from 'devextreme-react/draggable';
import Call from '../../components/call/Call';

const Header = ({ menuToggleEnabled, title, toggleMenu }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [pendingCallsData, setPendingCallsData] = useState([]);
  const [pendingChatsData, setPendingChatsData] = useState([]);
  const [pendingCall, setPendingCall] = useState(false);
  const [pendingChat, setPendingChat] = useState(false);

  const [roomId, setRoomId] = useState('');
  const [callVisible, setCallVisible] = useState(false);

  const fetchPendingCalls = async () => {
    try {
      const dataArray = await HTTP.get(`/base/crm-user-pending-activity/220/${user.id}`);
      setPendingCallsData(dataArray.data);
      if (dataArray.data.length > 0) {
        setPendingCall(true);
      } else {
        setPendingCall(false);
      }
    } catch (err) {}
  };

  const fetchPendingChats = async () => {
    try {
      const dataArray = await HTTP.get(`/base/crm-user-pending-activity/210/${user.id}`);
      setPendingChatsData(dataArray.data);
      if (dataArray.data.length > 0) {
        setPendingChat(true);
      } else {
        setPendingChat(false);
      }
    } catch (err) {}
  };

  const markRead = async (anActivityNo) => {
    try {
      const dataArray = await HTTP.get(`/base/mark-crm-user-activity-read/${anActivityNo}`);
      console.log(dataArray.data);
      //  refreshActivity();
    } catch (err) {}
  };

  const getUserUserChatid = async (an_from_userid) => {
    const response = await HTTP.get(`/management/user-user-chatid/${user.userId}/${an_from_userid}`);
    if (response.data[0]) {
      return response.data[0].chatid;
    } else {
      return -1;
    }
  };

  useEffect(() => {
    if (user && user.id) {
      fetchPendingCalls();
      fetchPendingChats();

      const interval = setInterval(() => {
        fetchPendingCalls();
        fetchPendingChats();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [user, user.id]);

  useEffect(() => {
    if (user.clientcall) {
      console.log('3 call client');
      user.clientcall = false;
      openCall(user.call_room);
    }
  }, [user.clientcall]);

  const onCallClicked = () => {
    markRead(pendingCallsData[0].crm_activity_no);
    setPendingCall(false);
    openCall(pendingCallsData[0].call_room);
  };

  const onChatClicked = () => {
    markRead(pendingChatsData[0].crm_activity_no);
    setPendingChat(false);
    openChatPage(pendingChatsData[0].from_userid);
  };

  const openCall = (an_room) => {
    setRoomId(an_room);
    setCallVisible(true);
  };

  const endCall = async () => {
    // Stop video stream
    // const video = document.querySelector('video');
    // const mediaStream = video.srcObject;
    // const tracks = mediaStream.getTracks();
    // tracks[0].stop();
    // tracks.forEach((track) => track.stop());

    // Hide call
    setCallVisible(false);

    window.location.reload();
  };

  const openChatPage = async (an_from_userid) => {
    const chatid = await getUserUserChatid(an_from_userid);

    if (chatid !== -1) {
      user.chat = {
        chatid: chatid,
        selUser: an_from_userid,
      };
      history.push({
        pathname: '/chat',
      });
    }
  };

  return (
    <header className={'header-component'}>
      <React.Fragment>
        {callVisible && (
          <Draggable id='callOverlay'>
            <Call roomId={roomId} />
            <Button id='endCall' text='End call' onClick={endCall}>
              <FontAwesomeIcon icon={faPhoneSlash} />
            </Button>
          </Draggable>
        )}
        <Toolbar className={'header-toolbar'}>
          <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
            <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
          </Item>
          <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />

          <Item location='after'>
            {pendingChat && pendingChat === true && (
              <FontAwesomeIcon
                className={'icon-distance-2'}
                width={50}
                icon={faMessage}
                color='blue'
                size='xl'
                cursor='pointer'
                beat
                // border
                onClick={onChatClicked}
              />
            )}
          </Item>

          <Item className={'icon-distance'} location='after' visible={true}>
            {pendingCall && pendingCall === true && (
              <FontAwesomeIcon
                className={'icon-distance'}
                icon={faPhone}
                width={50}
                color='blue'
                size='xl'
                cursor='pointer'
                beat
                // border
                onClick={onCallClicked}
              />
            )}
          </Item>

          <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
            <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
              <UserPanel menuMode={'context'} />
            </Button>
          </Item>
          <Template name={'userPanelTemplate'}>
            <UserPanel menuMode={'list'} />
          </Template>
        </Toolbar>
      </React.Fragment>
    </header>
  );
};

export default Header;

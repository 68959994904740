import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ClientsApprovedKYC = (props) => {
  const { tabVisibleIndex, selSignupClientid, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [signupClientDatalist, setSignupClientDatalist] = useState([]);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientkycdata/${selSignupClientid}`);
        // console.log(dataArray.data);
        setSignupClientDatalist(dataArray.data);
      } catch (err) {}
    };

    if (selSignupClientid) {
      // fetchSignupClientData();
    }
  }, [selSignupClientid]);

  function getResult(signupClientDatalist) {
    if (typeof signupClientDatalist.reviewResult !== 'undefined') {
      return (
        <div className='note-details-personally-block-outputText'>{signupClientDatalist.reviewResult.reviewAnswer}</div>
      );
    } else {
      return <div className='note-details-personally-block-outputText'>Not available</div>;
    }
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div className='dx-fieldset'>
            <div className='dx-fieldset-header'>KYC Applicant Status</div>
            {signupClientDatalist && (
              <>
                <div className='note-details-personally-block' key='1'>
                  <div className='note-details-personally-block-infoText'>Creation Date</div>
                  <div className='note-details-personally-block-outputText'>{signupClientDatalist.createDate}</div>
                </div>
                <div className='note-details-personally-block' key='2'>
                  <div className='note-details-personally-block-infoText'>Status</div>
                  <div className='note-details-personally-block-outputText'>{signupClientDatalist.reviewStatus}</div>
                </div>
                <div className='note-details-personally-block' key='3'>
                  <div className='note-details-personally-block-infoText'>Result</div>
                  {getResult(signupClientDatalist)}
                </div>
                <div className='note-details-personally-block' key='4'>
                  <div className='note-details-personally-block-infoText'>
                    <a
                      href={
                        'https://cockpit.sumsub.com/checkus/#/applicant/' +
                        signupClientDatalist.applicantId +
                        '/basicInfo?clientId=finbuilder'
                      }
                      className='btn btn-info'
                      role='button'
                    >
                      Get full report
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedKYC;
